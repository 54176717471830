import React from 'react'
//import { Navbar, Header, Cards, Checklist, Register, Subscribe, Features, Footer, Process} from '../containers'
import Navbar from '../containers/Navbar/Navbar.js';
import Header from '../containers/Header/Header.js';
import Process from '../containers/Process/Process.js';
import Cards from '../containers/Cards/Cards.js';
import Checklist from '../containers/Checklist/Checklist.js';
import Register from '../containers/Register/Register.js';
import Subscribe from '../containers/Subscribe/Subscribe.js';
import Features from '../containers/Features/Features.js';
import Footer from '../containers/Footer/Footer.js';
import '../App.css'

export default function Index(props) {
  const switchKeycloakContext = props.switchKeycloakContext;
  return (
    <div className='app'>
      <div className="gradient-bg">
        <Navbar switchKeycloakContext={switchKeycloakContext} />
        <Header/>
      </div>
      <Process />
      <Cards />
      <Checklist />
      <Register />
      <Subscribe />
      <Features />
      <Footer />
    </div>
  )
}