import React, { useState, useRef } from 'react'
import './navbar.css'
import logo from '../../assets/logo.png'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth-hook';


const Navbar = (props) => {
	
	const [toggleMenu, setToggleMenu] = useState(false);
	const navigate = useNavigate();
	const navRef = useRef();
	const auth = useAuth();
	const switchKeycloakContext = props.switchKeycloakContext;
	
	const showNavbar = () => {
		navRef.current.classList.toggle("responsive-nav");
	}

	async function toDashboard() {
		navigate('dashboard');
	}
	async function logIn() {
		switchKeycloakContext(process.env.CORE_KC_REALM_NAME);
		sessionStorage['realmId'] = process.env.CORE_KC_REALM_NAME;
		navigate('dashboard');
	}

	async function logOut() {
		auth.logout();
	}
	return (
		<div className='navbar'>
			<div className="navbar-links">
				<div className="navbar-logo">
					<img src={logo} alt="Logo" />
				</div>
				<div className="navbar-links_container">
					<p><a href="#">Products</a></p>
					<p><a href="#">About</a></p>
					<p><a href="#">FAQ</a></p>
					<p><a href="#">Integrations</a></p>
				</div>
			</div>
			<div className="navbar-sign">
				<button onClick={auth?.isAuthenticated ? toDashboard: logIn}>{auth?.isAuthenticated ? "Dashboard" : "Log In"}</button>
			</div>
			<div className="navbar-menu">
				{toggleMenu ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
					: <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />}

				{toggleMenu && (
					<div className='navbar-menu_container scale-up-center'>
						<div className="navbar-menu_container-links">
							<p><a href="#">Products</a></p>
							<p><a href="#">About</a></p>
							<p><a href="#">FAQ</a></p>
							<p><a href="#">Integrations</a></p>
							<div className="navbar-menu_container-links-sign">
								<button onClick={auth?.meta?.keycloak?.authenticated ? toDashboard: logIn}>{auth?.meta?.keycloak?.authenticated ? "Dashboard" : "Log In"}</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Navbar