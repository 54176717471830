import React,{useState, useEffect} from 'react';
import './share-capital-form.css';
import useAxios from '../../../../../../../hooks/axios';
import { useAuth } from '../../../../../../../hooks/auth-hook';
import { NumericFormat } from 'react-number-format';
import ReactLoading from "react-loading";

const save_share_capital_url = `${process.env.API_BASE_URL}/share-capital`;
const get_share_capital_url = `${process.env.API_BASE_URL}/share-capital`;

export default function ShareCapitalForm(props) {

    const registrationCode = props.registrationCode;
    const setShowShareCapitalForm = props.setShowShareCapitalForm;
    const setShowShareCapitalTable = props.setShowShareCapitalTable;
    const [shareCapitalValue, setShareCapitalValue] = useState('');
    const [hasForeignerAsShareHolder, setHasForeignerAsShareHolder] = useState('');
    const [companyStructure, setCompanyStructure] = useState('');
    const [companyStructureDescription, setCompanyStructureDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [allFieldsValid, setAllFieldsValid] = useState(false);
    const [shareCapitalValueError, setShareCapitalValueError] =  useState('* Required');
    const [hasForeignerAsShareHolderError, setHasForeignerAsShareHolderError] = useState('* Required');
    const [companyStructureError, setCompanyStructureError] = useState('* Required');
    const [companyStructureDescriptionError, setCompanyStructureDescriptionError] = useState('');
    const [shareCapitalFormattedValue, setShareCapitalFormattedValue] = useState();
    const setShowAddShareCapital = props.setShowAddShareCapital;
    
    const { user, roles } = useAuth();
    const { axios, initialized, token } = useAxios.useAxios({});


    const onOptionChange = e => {
        setHasForeignerAsShareHolder(e.target.value);
        setHasForeignerAsShareHolderError('');
        validateAllFields();
    }

    function clearForm(){
        setShowShareCapitalForm(false);
        setShowShareCapitalTable(true);
        setShowAddShareCapital(true);
    }

    function hideSaveStatus() {
        setShowShareCapitalForm(false);
        setShowShareCapitalTable(true);
        const saveStatusSpan = document.getElementById('saveStatusSpan');
        saveStatusSpan.style.display = 'none';
    }

    async function post(e){
        e.preventDefault();
        const data = {
            shareCapitalValue: shareCapitalValue,
            hasForeignerAsShareHolder: hasForeignerAsShareHolder,
            companyStructure: companyStructure,
            companyStructureDescription: companyStructureDescription,
            registrationCode: registrationCode
        }

        setLoading(true);
        await axios.post(`${save_share_capital_url}`, data).then((response)=>{
            setLoading(false);
            const saveStatusSpan = document.getElementById('saveStatusSpan');
            saveStatusSpan.style.display = 'flex';
            setTimeout(hideSaveStatus, 500);
        })
        
    }

    function validateAllFields(){
        const shareCapitalValueValid = shareCapitalValue && shareCapitalValue>0;
        if(shareCapitalValueValid){
            setShareCapitalValueError('');
        }else{
            setShareCapitalValueError('* Required');
        }
        const shareholderIsForeignValid= hasForeignerAsShareHolderError!=='* Required';

        const companyStructureInput = document.getElementById('companyStructure');
        const companyStructureInputValue = companyStructureInput.value;
        const companyStructureInputValid = companyStructureInputValue && companyStructureInputValue!=='--' && companyStructureInputValue!=='';

        if(companyStructureInputValid){
            setCompanyStructureError('');
        }else{
            setCompanyStructureError('* Required');
        }

        const companyStructureDescriptionInput = document.getElementById('companyStructureDescription');
        const companyStructureDescriptionInputValue = companyStructureDescriptionInput.value;
        const companyStructureDescriptionInputValid = companyStructureDescriptionInputValue && companyStructureDescriptionInputValue!=='';
        
        if(companyStructureInputValue==='Other'){
            if(companyStructureDescriptionInputValid){
                setCompanyStructureDescriptionError('');
            }else{
                setCompanyStructureDescriptionError('* Required');
            }
        }else{
            setCompanyStructureDescriptionError('');
        }
        
        const allFieldsValid = (companyStructureInputValue==='Other' ? companyStructureDescriptionInputValid : true ) && companyStructureInputValid  && shareholderIsForeignValid;
        setAllFieldsValid(allFieldsValid);
        return allFieldsValid;
    }


    return (
        <div>

                <div className="formFieldInput">
                    <label className="formLabels">
                        Share Capital Value
                    </label>
                    <NumericFormat
                    value={shareCapitalFormattedValue}
                    id="shareCapitalValue"
                    className="formInputs"
                    thousandSeparator={true}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setShareCapitalValue(value);
                        setShareCapitalFormattedValue(formattedValue);
                        const shareCapitalValueValid = value && value>0;
                        const allFieldsValid = shareCapitalValueValid && validateAllFields();
                        if(shareCapitalValueValid){
                            setShareCapitalValueError('');
                        }else{
                            setShareCapitalValueError('* Required');
                        }
                    }}/>
                    {/*<input id="shareCapitalValue" type="number" className="formInputs" onChange={(e) => {setShareCapitalValue(e.target.value); validateAllFields();}} onClick={validateAllFields} name='shareCapitalValue'></input>*/}
                    {shareCapitalValueError &&<span id="shareCapitalValueError" className="formError">{shareCapitalValueError}</span>}
                </div>



                <div className="formFieldInput">
                    <div className="radioSet">
                        <label className="formLabels">
                            Has foreigner as shareholder? 
                        </label>&nbsp;&nbsp;
                        <label className="formLabels">
                            Yes &nbsp;
                            <input id="noForeignShareholder" className="shareCapFormRadio " type="radio" onChange={onOptionChange} value={true} checked={hasForeignerAsShareHolder === 'true'}></input>
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label className="formLabels">
                            No &nbsp;
                            <input id="hasNotForeignShareholder" className="shareCapFormRadio" type="radio" onChange={onOptionChange} value={false} checked={hasForeignerAsShareHolder === 'false'}></input>
                        </label> &nbsp;&nbsp;&nbsp;
                        {hasForeignerAsShareHolderError &&<span id="hasForeignerAsShareHolderError" className="formError">{hasForeignerAsShareHolderError}</span>}
                    </div>
                </div>


                <div className="formFieldInput">
                    <label className="formLabels">
                        Company Structure
                    </label>
                    <select id="companyStructure" className="formInputs" onChange={(e) => {setCompanyStructure(e.target.value); validateAllFields();}} >
                        <option key="0" value="--">-- Select Company structure --</option>
                        {[{value:'Functional',name:'Functional'},
                        {value:'Divisional',name:'Divisional'},
                        {value:'Matrix',name:'Matrix'},
                        {value:'Team',name:'Team'},
                        {value:'Network',name:'Network'},
                        {value:'Hierarchical',name:'Hierarchical'},
                        {value:'Flat', name:'Flat'},
                        {value:'Other', name:'Other'}]
                        .map((option, idx)=>(
                            (<option key={idx} value={option.value}>{option.name}</option>)
                        ))}
                    </select>
                    {companyStructureError &&<span id="companyStructureError" className="formError">{companyStructureError}</span>}
                </div>


                <div className="formFieldInput">
                    <label className="formLabels">
                        Company Structure Description {companyStructure==='Other' ? '(Required)':'(Optional)'}
                    </label>

                    <textarea id="companyStructureDescription"
                        className="formInputs companyDescTextArea"
                        onChange={(e) => { setCompanyStructureDescription(e.target.value);  validateAllFields(); }}
                        name='companyStructure'
                        rows="5" />
                        {companyStructureDescriptionError && <span id="companyStructureDescriptionError" className="formError">{companyStructureDescriptionError}</span>}
                </div>

                <div className="formFieldInput btns">
                <button className="cancelBtn" onClick={(e) => { return clearForm(e) }}>Cancel</button>
                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                <span id="saveStatusSpan" className="saveStatusSpan">Saved.</span>
                <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
            </div>
        </div>
    )
}