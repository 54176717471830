import React from 'react';
import './index.css'
import 'typeface-prompt';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Router } from 'react-router-dom';
import DashboardModule from './modules/1-dashboard/1-dashboard';
import CrobodCapital from './modules/2-crobod-capital/2-crobod-capital';
import CrobodBiz from './modules/3-crobod-biz/3-crobod-biz';
import CrobodPrime from './modules/4-crobod-prime/4-crobod-prime';
import InviteAndEarn from './modules/5-invite-and-earn/5-invite-and-earn';
import ChatWithUs from './modules/6-chat-with-us/6-chat-with-us';
import { useAuth } from '../../hooks/auth-hook';

export default function DashboardIndex() {

    const auth = useAuth();

    const link1 = useRef(null);
    const link2 = useRef(null);
    const link3 = useRef(null);
    const link4 = useRef(null);
    const link5 = useRef(null);
    const link6 = useRef(null);
    const links = [link1, link2, link3, link4, link5, link6];
    const [navLocationIdentifier, setNavLocationIdentifier] = useState('Dashboard');
    const [submoduleName, setSubmoduleName] = useState('');
    const navigate = useNavigate();

    function setModName(moduleName){
        setSubmoduleName(moduleName);
    }


    function menuNavigate(event) {
        event.preventDefault();
        links.forEach((link) => {
            link.current.className = 'menuItemInactive';
        });
        event.currentTarget.className = 'menuItemActive';
        setNavLocationIdentifier(event.currentTarget.innerHTML);
        setSubmoduleName('');
    }


    if (!auth?.initialized) {
        return <div>Loading...</div>;
    }
    if (auth?.initialized && !auth?.isAuthenticated) {
        auth.login()
    }

    function logOut(event){
        event.preventDefault();
        auth.logout();
    }


    function goHome(){
        navigate('/');
    }

    return (
        <div className='rootEl'>

            <div className='leftMenu'>
                <div onClick={goHome} className='crobodLogo'>
                </div>
                <br /><br />
                <div className='menuItems' >
                    <ul id='menu-items'>
                        <li><Link to='#' ref={link1} onClick={(e) => { menuNavigate(e) }} className='menuItemActive'>Dashboard</Link> </li>
                        <li><Link to='#' ref={link2} onClick={(e) => { menuNavigate(e) }} className='menuItemInactive'>Crobod Capital</Link> </li>
                        <li><Link to='#' ref={link3} onClick={(e) => { menuNavigate(e) }} className='menuItemInactive'>Crobod Biz</Link> </li>
                        <li><Link to='#' ref={link4} onClick={(e) => { menuNavigate(e) }} className='menuItemInactive'>Crobod Prime</Link> </li>
                        <li><Link to='#' ref={link5} onClick={(e) => { menuNavigate(e) }} className='menuItemInactive'>Invite and Earn</Link> </li>
                        <li><Link to='#' ref={link6} onClick={(e) => { menuNavigate(e) }} className='menuItemInactive'>Chat with us</Link> </li>
                    </ul>
                </div>
                <br /><br />
                <div className='logoutButton'>
                    <span onClick={ (e) => { logOut(e) } }>Logout</span>
                </div>
            </div>

            <div className='mainPanel'>
                <div className='userDetailsStrip'>
                    <div className='navLocationDiv'>{`${navLocationIdentifier}`}  {submoduleName!='' ? ` >> ${submoduleName}` : ''}</div>
                    <div className='userImgNotifSection'>
                        <div className='notifSvg'>
                            <img src='../../images/bell.svg'></img>
                        </div>
                        <div className='userNameField'>{(auth && auth.user && auth.user.firstName && auth.user.lastName) ? `Welcome, ${auth.user.firstName} ${auth.user.lastName}` : ''}</div>
                        <div className='seperatorDiv'>&nbsp;</div>
                        <div className='userImg'>
                            <img src='../../images/userImg.png'></img>
                        </div>
                    </div>
                </div>

                <br />

                <div className='appContainer'>
                    {navLocationIdentifier === 'Dashboard' && <DashboardModule setSubmoduleName={setModName}></DashboardModule>}
                    {navLocationIdentifier === 'Crobod Capital' && <CrobodCapital setSubmoduleName={setModName}></CrobodCapital>}
                    {navLocationIdentifier === 'Crobod Biz' && <CrobodBiz setSubmoduleName={setModName}></CrobodBiz>}
                    {navLocationIdentifier === 'Crobod Prime' && <CrobodPrime setSubmoduleName={setModName}></CrobodPrime>}
                    {navLocationIdentifier === 'Invite and Earn' && <InviteAndEarn setSubmoduleName={setModName}></InviteAndEarn>}
                    {navLocationIdentifier === 'Chat with us' && <ChatWithUs setSubmoduleName={setModName}></ChatWithUs>}
                </div>
            </div>
        </div>
    )

}