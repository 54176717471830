import React,{useState, useEffect} from 'react';
import  './working-capital.css'
import { useSelector, useDispatch } from 'react-redux';
import useAxios from '../../../../../hooks/axios';
import ReactLoading from "react-loading";
import { useAuth } from '../../../../../hooks/auth-hook';
import ResponsiveTable from '../../../../../common/responsive-table/responsive-table';
import { setUserId } from '../../../../../redux/actions/setUserId';
import validator from 'validator';
import FiancingForm from '../financing-form/financing-form';
import NoFinance from '../no-finance/no-finance';

const get_financing_url = `${process.env.API_BASE_URL}/financing`;

export default function WorkingCapital(props){
    
    const headers = ['Issue Date','Loan No.', 'Purpose', 'Amount Requested', 'Amount Issued', 'Status'];
    const purpose = 'Working Capital';
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [displayTable, setDisplayTable] = useState(true);
    const [displayForm, setDisplayForm] = useState(false);
    const { axios, initialized, token } = useAxios.useAxios({});
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const userId = useSelector((state) => state.userId);
    const { user, roles } = useAuth();
    const dispatch = useDispatch();
    const [hasFinance, setHasFinance] = useState(true);
    

    useEffect(() => {
        if (initialized) {
            
        }
        if (user.id) {
            dispatch(setUserId(user.id));
            load(page, limit, search, user.id);
        }
    }, [initialized, axios, user]);


    function load(page_, limit_, search_, userId_) {

        setLoading(true);
        fetchData(page_, limit_, search_, userId_).then((resp) => {

            setLoading(false);


            const financings = resp.financings;
            setHasFinance(financings.length>0);
            const total = resp.total;
            const pages = resp.pages;
            const page = resp.page;
            setPages(pages);
            setTotal(total);

            if (financings) {
                const data_ = [];
                financings.forEach((financing) => {
                    data_.push(
                        [
                            new Date(financing.createdAt).toDateString(),
                            financing.loanNo,
                            financing.purpose,
                            `${financing.currency} ${financing.amountRequested}`,
                            `${financing.currency} ${financing.amountIssued}`,
                            financing.status
                        ]);
                });
                setData(data_);
            }

        });

    }

    const sanitize = (input) => {
        return validator.escape(String(input));
    };

    function fetchData(page_, limit_, search_, userId_) {

        return new Promise((resolve, reject) => {
            try {

                if (axios) {
                    axios.get(`${get_financing_url}?page=${page_ || page}&limit=${limit_ || limit}&userId=${sanitize(userId_||userId||user.id)}&search=${sanitize(search_ || search)}&purpose=${sanitize(purpose)}`).then((resp) => {
                        resolve(resp.data);
                    });
                }

            } catch (Error) {
                console.error(Error);
            }
        });

    }
    function add() {
        setDisplayTable(false);
        setDisplayForm(true);
    }

    const handleSearch = (event) => {
        const page_ = 0;
        const limit_ = 5;
        const search_ = event.target.value;
        setLimit(limit_);
        setPage(page_);
        setSearch(search_);
        load(page_, limit_, search_);
    };

    return (
        <div>
            <div className='searchStrip'>
                <div className='newAddress'>
                    <span className="fa fa-plus-square-o" />
                    <span onClick={add}> &nbsp;Request Financing</span>
                </div>

                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}

                <div className='searchDiv'>
                    <span className='searchSpan'>
                            <label htmlFor="search">
                            <input id="search" type="text" value={search} onKeyUp={(e) => { setSearch(e.target.value); load(); }} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                        </label>
                    </span>
                </div>

            </div>
            {!hasFinance && !displayForm && <NoFinance message="No Finance" callToAction="Request Finance" action={add}></NoFinance>}
            
            <div className='appTable'>
                {hasFinance && displayTable && data.length>0 && <ResponsiveTable dataFetchFn={load} headers={headers} data={data} pagination={{ setPage: setPage, total: total, pages: pages, page: page }}></ResponsiveTable>}
                {displayForm && <FiancingForm formTitle="Request for Working Capital financing" loanPurpose={purpose} loadData={load} user={user} setDisplayForm={setDisplayForm} setDisplayTable={setDisplayTable}></FiancingForm>}
            </div>
        </div>
    )
}