import { createSlice } from '@reduxjs/toolkit'
const defaultval_ = {  
  businessName: '',
  userName: '',
  password: '',
  confirmPassword: '',
  aimUserId: '',
  realmId: '',
  realmName: '',
  webClientId: '',
  apiClientId: '',
  apiClientSecret: '',
  synchronized: false,
  emailAddress: '',
  tenantId: '',
  tenantName: '',
  currentRealm: '',
  keycloaks: {},
  currentKeycloak: {},
  loginBusinessName: '',
  loginEmailAddress: '',
  userType: '',
};
export const registrationDataSlice = createSlice({
  name: 'registrationData',
  initialState: {
    value: defaultval_,
  },
  reducers: {
    setBusinessName:(state,action) => {
      state.value.businessName = action.payload;
    },
    setUserName:(state,action) => {
      state.value.userName = action.payload;
    },
    setPassword:(state,action) => {
      state.value.password = action.payload;
    },
    setConfirmPassword:(state,action) => {
      state.value.confirmPassword = action.payload;
    },
    setAimUserId: (state,action) => {
      state.value.aimUserId = action.payload;
    },
    setRealmId:(state,action) => {
      state.value.realmId = action.payload;
    },
    setRealmName:(state,action) => {
      state.value.realmName = action.payload;
    },
    setWebClientId:(state,action) => {
      state.value.webClientId = action.payload;
    },
    setApiClientId:(state,action) => {
      state.value.apiClientId = action.payload;
    },
    setApiClientSecret:(state,action) => {
      state.value.apiClientSecret = action.payload;
    },
    setSynchronized:(state,action) => {
      state.value.synchronized = action.payload;
    },
    setEmailAddress:(state,action) => {
      state.value.emailAddress = action.payload;
    },
    setTenantId:(state,action) => {
      state.value.tenantId = action.payload;
    },
    setTenantName:(state, action) => {
      state.value.tenantName = action.payload;
    },
    setCurrentRealm:(state, action) => {
      state.value.currentRealm = action.payload;
    },
    setKeycloaks:(state, action) => {
      state.value.currentRealm[action.payload.realm] = action.payload.keycloak;
    },
    setCurrentKeycloak:(state, action) => {
      state.value.currentKeycloak = action.payload;
    },
    setLoginBusinessName:(state, action) => {
      state.value.loginBusinessName = action.payload;
    },
    setLoginEmailAddress:(state, action) => {
      state.value.loginEmailAddress = action.payload;
    },
    setUserType:(state, action) => {
      state.value.userType = action.payload;
    },
    
    clear: (state) => {
      state.value = defaultval_
    }
  },
})

// Action creators are generated for each case reducer function
export const { setBusinessName, setUserName, setPassword, setConfirmPassword, setAimUserId, setRealmId, setRealmName, setWebClientId, setApiClientId, setApiClientSecret, setSynchronized, setEmailAddress, setTenantId, setTenantName, setCurrentRealm, setKeycloaks,setCurrentKeycloak,  setLoginBusinessName, setLoginEmailAddress, setUserType, clear} = registrationDataSlice.actions

export default registrationDataSlice.reducer