import React from 'react'
import crobod from '../../assets/crobod.png'
import './process.css'
import Step from '../../components/Step/Step'

const Process = () => {
  return (
    <div className='process'>
        <div className="process-heading">
            <h2>The End-to-End Crobod Process</h2>
        </div>
        <div className="process-img">
            <img src={crobod} alt="" />
        </div>
        <div className="process-text">
            <Step 
                number="1"
                body="Sign up on Crobod"
            />
            <Step
                number="2"
                body="Get all documentations ready & calculate how much you need to request"
            />
            <Step 
                number="3"
                body="Request for funds & fill all required forms"
            />
            <Step 
                number="4"
                body="Submit and approval is in a max of 3 days"
            />
            <Step 
                number="5"
                body="Fund is made available in your newly created "
            />
            <Step
                number="6"
                body="Request to make payment to your supplier"
            />
            <Step 
                number="7"
                body="Leverage our freight network to ship into Nigeria"
            />
            <Step 
                number="8"
                body="Leverage our lastmile delivery network to pickup from the cargo and deliver to your house address"
            />
            <Step 
                number="9"
                body="Give us feedback for improvement purposes"
            />
            <Step 
                number="10"
                body="Request to make payment to your supplier"
            />
        </div>
    </div>
  )
}

export default Process