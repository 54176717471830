import React from 'react'
import check from '../../assets/check.png'
import './card.css'

const Card = ( {heading, main} ) => {
  return (
    <div className='card'>
      <div className="card-content">
        <img src={check} alt="Icon" />
        <h2>{heading}</h2>
        <p>{main}</p>
      </div>
    </div>
  )
}

export default Card