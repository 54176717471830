import { createStore } from 'redux';
import { combineReducers } from 'redux';
import userIdReducer from './reducers/userIdReducer';
import startTodayFormDataSlice from './reducers/startTodayFormDataSlice';
import registrationDataSlice from '../util/redux/registrationDataSlice';
import navigationStateDataSlice from './reducers/navigationStateReducer';
import directorFormStateReducer from './reducers/directorFormStateReducer';


const rootReducer = combineReducers({
    userIdReducer: userIdReducer,
    startTodayFormDataSlice: startTodayFormDataSlice,
    registrationData: registrationDataSlice,
    navigationStateDataSlice: navigationStateDataSlice,
    directorFormStateReducer: directorFormStateReducer
  });

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


export default store;
