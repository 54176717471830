import React from 'react'
import './features.css'
import Feature from '../../components/Feature/Feature'

const Features = () => {
  return (
    <div className='features'>
        <div className="features-container">
            <Feature
                heading="Cross Border Payments"
                main="We have trusted partners that can help you pay your 
                suppliers abroad so that as soon as you receive your loan, you can make the payment instantly from here"
            />
            <Feature 
                heading="Cross Border Shipment"
                main="We have partnered with a cross border shipment aggregator
                 that offers you all the best shipping deals and you make your choice and checkout from here"
            />
            <Feature 
                heading="Pickup from or to Cargo/Port"
                main="Do you have your imports in the cargo office already, or you want to take your
                 exports to a cargo company, you can use our trusted partner to get that done asap now"
            />
        </div>
    </div>
  )
}

export default Features