import React, { useState } from 'react';
import './no-finance.css';

export default function NoFinance(props) {
    const message = props.message;
    const callToAction = props.callToAction;
    const action = props.action;
    return (
        <div className="nofinanceDiv">
            <div className="centeredContent"><i className="fa fa-exclamation-triangle largeExlamation"></i></div>
            <div className="centeredContent noFinance">{message}</div>
            <div className="centeredContent"><button onClick={action} className="reqFinanceButton">{callToAction}</button></div>
        </div>
    );
}