import React, {useEffect} from 'react';
import './responsive-table.css';

export default function ResponsiveTable(props) {


    const headers_ = ['#', 'Name', 'Age', 'Gender', 'Salary'];
    const data_ = [
        ['1', 'Mwangi', '22', 'M', '$56,000'],
        ['2', 'Simon', '21', 'M', '$56,000'],
        ['3', 'Alice', '26', 'F', '$56,000']
    ];
    const headers = props.headers || headers_;
    const data = props.data || data_;
    const dataFetchFn = props.dataFetchFn;
    const pagination = props.pagination;
    const setPage = pagination.setPage;
    const total = pagination.total;
    const pages = pagination.pages;
    var colCount = 0;

   

    useEffect(()=>{
        dataFetchFn();
    },[]);

    function navigate(page){
        setPage(page);
        dataFetchFn(page);
    }

    const pageBtns = [];
    for(var i = 0; i<pagination.pages; i++){
        pageBtns.push({index: i});
    }

    return (
        <div className="appTable">
            <table>
                <thead>
                    <tr>
                        {(headers && headers.length > 0) && headers.map((header) => (
                            <th key={header}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>

                    {(data && data.length > 0) && data.map((data_, idx) => (
                        <tr key={idx + Math.random() + data_[0]}>
                            {data_.map((dta, idx_) => (
                                <td key={idx_ + Math.random()}>
                                    <span className="cell-header">{headers[idx_]}</span> {dta}
                                </td>
                            ))}
                        </tr>

                    ))}
                </tbody>
            </table>
            {pages>1 && 
            <div style={{width: '100%', fontWeight:200,padding:'10px', display: "flex", justifyContent: "space-between" }}>
                <span>Total Pages: {pages}</span>
                <span>Page:{" "}
                    {pageBtns.map((_, index)=>(
                        <button onClick={()=>{ navigate(index+1);}} className="pagingBtns" key={index} type="button" style={{  fontWeight: "bold"  }}>{index+1}</button>
                    ))}
                    
                </span>
            </div>}
        </div>
    );

}