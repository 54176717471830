// keycloakInstances.js
import Keycloak from 'keycloak-js';

export const keycloakConfig1 = {
  url: process.env.CORE_KC_BASE_URL || 'https://auth.crobod.africa',
  realm: sessionStorage['realmId'] || process.env.CORE_KC_REALM_NAME || 'crobod-dev',
  clientId: process.env.KC_WEB_CLIENT_ID || 'crobod-ui',
  pkceMethod: process.env.KC_WEB_CLIENT_PKCE_METHOD || 'S256',
  KeycloakResponseType: process.env.KC_RESPONSE_TYPE || 'code',
  onLoad: process.env.KC_ONLOAD || 'check-sso',//'login-required'//,
  checkLoginIframe: process.env.KC_CHECK_LOGIN_IFRAME || false
};

export const keycloakConfig2 = {
  url: 'https://keycloak-server2/auth',
  realm: sessionStorage['realmId']||'crobod-dev',
  clientId: 'another-client'
};
export const keycloakInstance1 = new Keycloak(keycloakConfig1);
export const keycloakInstance2 = new Keycloak(keycloakConfig2);
