import React from 'react';
import './business-dashboard-panel.css';
import BusinessDashboardIcons from '../business-dashboard-icons/business-dashboard-icons';

export default function BusinessDashboardPanel(props){
    const setShowDashboardPanel = props.setShowDashboardPanel;
    const registrationCode = props.registrationCode;
    const setShowAddressForm = props.setShowAddressForm;
    const setDisplayAddressTable = props.setDisplayAddressTable;
    const setNavigation = props.setNavigation;
    const businessAddressCount = props.businessAddressCount;
    const setShowDirectorForm = props.setShowDirectorForm;
    const setShowShareCapitalDash = props.setShowShareCapitalDash;
    const setShowShareHolderForm = props.setShowShareHolderForm;
    const setShowBusinessNames = props.setShowBusinessNames;
    const businessNamesCount = props.businessNamesCount;
    const setShowDirectorTable = props.setShowDirectorTable;
    const showDirectorDash=props.showDirectorDash;
    const setShowDirectorDash=props.setShowDirectorDash;
    const directorCount=props.directorCount;
    const setDirectorCount=props.setDirectorCount;
    const setShowShareCapitalTable = props.setShowShareCapitalTable;
    const shareCapitalCount = props.shareCapitalCount;
    const shareHolderCount = props.shareHolderCount;


    return (
        <div className="businessDashboardPanel">
            <BusinessDashboardIcons shareHolderCount={shareHolderCount} shareCapitalCount={shareCapitalCount} setShowShareCapitalDash={setShowShareCapitalDash} setShowShareCapitalTable={setShowShareCapitalTable} directorCount={directorCount} setDirectorCount={setDirectorCount} setShowDirectorDash={setShowDirectorDash} showDirectorDash={showDirectorDash} setShowDirectorTable={setShowDirectorTable} businessNamesCount={businessNamesCount} setShowBusinessNames={setShowBusinessNames} setShowShareHolderForm={setShowShareHolderForm} setShowDirectorForm={setShowDirectorForm} setDisplayAddressTable={setDisplayAddressTable}  setNavigation={setNavigation} businessAddressCount={businessAddressCount} setShowAddressForm={setShowAddressForm} setShowDashboardPanel={setShowDashboardPanel} registrationCode={registrationCode}></BusinessDashboardIcons>
        </div>
    );
}