import React from 'react';
import './crobod-dash-icon.css';

export default function CrobodDashIcon(props) {
    const label = props.label;
    const iconClassName = props.iconClassName;
    const callForAttention = props.callForAttention;
    const action = props.action;
     
    return (
        <div className="dashboardIcon" onClick={action}>
            {callForAttention && <div className="attentionIndicator"><i className="fa fa-exclamation-circle"></i></div>}
            <div className={iconClassName}></div>
            <div className="dashLabel">{label}</div>
        </div>
    )
}