import React from 'react'
import Card from '../../components/Card/Card'
import './cards.css'

const Cards = () => {
  return (
    <div className='cards section__padding'>
        <div className="cards-heading">
            <h2>Extra values Crobod will add to your business</h2>
        </div>
        <div className="cards-container">
            <Card 
                heading="Improve negotiations"
                main="The more you buy, the more you can request for discounts from your suppliers. Buy/sell more goods, serve more customers, and have all goods you need in stock at all times"
            />
            <Card 
                heading="Build your financial credit"
                main="We will ensure your prompt repayment is entered into a national credit database so that it helps build your credit-worthiness and further unlock more credit for your business,"
            />
            <Card 
                heading="Do all you need on our platform"
                main="For Prime subscribers, you can access finance, pay your suppliers, ship to your house address all on our same platform. All Prime customers get to have a free of charge delivery (0 - 5kg) from the port/cargo to house or from house to airport"
            />
            <Card 
                heading="Flexible repayment period"
                main="You can pay back in a maximum of 12 months. However, if there are reasons why you need more time, we are able to further discuss on a case-by-case basis and offer more time for you. This however comes at an extra cost and doesn’t affect your score."
            />
        </div>

    </div>
  )
}

export default Cards