import React, { useState, useEffect }  from 'react';
import './local-delivery-partners.module.css'
import useAxios from '../../../../../hooks/axios';
import ResponsiveTable from '../../../../../common/responsive-table/responsive-table';
import ReactLoading from "react-loading";
import validator from 'validator';
import DeliveryPartnerForm from './delivery-partner-form/delivery-partner-form';

const get_delivery_partner_url = `${process.env.API_BASE_URL}/delivery-partner`;

export default function LocalDeliveryPartners(props){

    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [displayTable, setDisplayTable] = useState(true);
    const [displayForm, setDisplayForm] = useState(false);
    const { axios, initialized, token } = useAxios.useAxios({});
    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState(['#', 'Name', 'Country', 'City','Distance Covered','Type', 'Status']);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page

    useEffect(() => {
        if (initialized) {
            load();
        }
    }, [initialized]);


    function load(page_, limit_, search_){

        setLoading(true);
        fetchDeliveryPartners(page_, limit_, search_).then((resp)=>{

            setLoading(false);

            const deliveryPartners = resp.deliveryPartners;
            const total = resp.total;
            const pages = resp.pages;
            const page = resp.page;
            setPages(pages);
            setTotal(total);

            const data_ = [];
            deliveryPartners.forEach((deliveryPartner) => {
                data_.push(
                    [
                        deliveryPartner.id,
                        deliveryPartner.name,
                        deliveryPartner.country,
                        deliveryPartner.city,
                        deliveryPartner.distanceCovered,
                        deliveryPartner.type,
                        deliveryPartner.status
                    ]);
            });
            setData(data_);

        });
        
    }

    const sanitize = (input) => {
        return validator.escape(String(input));
    };

    function fetchDeliveryPartners(page_, limit_, search_) {

        return new Promise((resolve, reject) => {
            try {

                if (axios) {
                    axios.get(`${get_delivery_partner_url}?page=${page_||page}&limit=${limit_||limit}&search=${sanitize(search_||search)}`).then((resp) => {
                        resolve(resp.data);
                    });
                }

            } catch (Error) {
                console.error(Error);
            }
        });

    }
    function add() {
        setDisplayTable(false);
        setDisplayForm(true);
    }

    const handleSearch = (event) => {
        const page_ = 0;
        const limit_ = 5;
        const search_ = event.target.value;
        setLimit(limit_);
        setPage(page_);
        setSearch(search_);
        load(page_, limit_, search_);
    };
    
    return (
        <div>
        <div className='searchStrip'>
            <div className='newAddress'>
                <span className="fa fa-plus-square-o" />
                <span onClick={add}> &nbsp;Add Delivery Partner</span>
            </div>

            {loading &&
                <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}


            <div className='searchDiv'>
                <span className='searchSpan'>
                    <label htmlFor="search">
                        <input id="search" type="text" value={search} onKeyUp={(e)=>{setSearch(e.target.value);load();}} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                    </label>
                </span>
            </div>

        </div>
        <div className='appTable'>
            {displayTable && <ResponsiveTable  dataFetchFn={load} headers={headers} data={data} pagination={{ setPage: setPage, total: total, pages: pages, page: page }}></ResponsiveTable>}
            {displayForm && <DeliveryPartnerForm setDisplayForm={setDisplayForm} setDisplayTable={setDisplayTable}></DeliveryPartnerForm>}
        </div>
    </div>
    )
}