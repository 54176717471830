import { createSlice } from '@reduxjs/toolkit'
const defaultval_ ={ 
    title: '',
    designationTitle: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    emailAddress: '',
    phoneNumber: '',
    dateOfBirth: '',
    meansOfIdentification: '',
    identificationNumber: '',
    signatureUrl: '',
    shareClass: '',
    identificationDocumentUrl: '',
    allotedShares: '',
    registrationCode: '',
    userId: '',
    nationality: {}
  };
  export const directorFormData = createSlice({
    name: 'directorFormData',
    initialState: {
      value: defaultval_,
    },
    reducers: {
      setTitle:(state,action) => {
        state.value.title = action.payload;
      },
      setDesignationTitle:(state,action) => {
        state.value.designationTitle = action.payload;
      },
      setFirstName:(state,action) => {
        state.value.firstName = action.payload;
      },
      setMiddleName:(state,action) => {
        state.value.middleName = action.payload;
      },
      setLastName:(state,action) => {
        state.value.lastName = action.payload;
      },
      setGender:(state,action) => {
        state.value.gender = action.payload;
      },
      setEmailAddress:(state,action) => {
        state.value.emailAddress = action.payload;
      },
      setPhoneNumber:(state,action) => {
        state.value.phoneNumber = action.payload;
      },
      setDateOfBirth:(state,action) => {
        state.value.dateOfBirth = action.payload;
      },
      setMeansOfIdentification:(state,action) => {
        state.value.meansOfIdentification = action.payload;
      },
      setIdentificationNumber:(state,action) => {
        state.value.identificationNumber = action.payload;
      },
      setAllotedShares:(state,action) => {
        state.value.allotedShares = action.payload;
      },
      setSignatureUrl:(state,action) => {
        state.value.signatureUrl = action.payload;
      },
      setShareClass:(state,action) => {
        state.value.shareClass = action.payload;
      },
      setIdentificationDocumentUrl:(state, action) => {
        state.value.identificationDocumentUrl = action.payload;
      },
      setRegistrationCode:(state,action) => {
        state.value.registrationCode = action.payload;
      },
      setUserId:(state,action) => {
        state.value.userId = action.payload;
      },
      setNationality:(state,action) => {
        state.value.nationality = action.payload;
      },
      
      clear: (state) => {
        state.value = defaultval_
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setTitle, setDesignationTitle,setFirstName,setMiddleName,setLastName, setGender,setEmailAddress, setPhoneNumber, setDateOfBirth, setMeansOfIdentification,setIdentificationNumber,setRegistrationCode,setUserId,setNationality,setSignatureUrl,setShareClass,setIdentificationDocumentUrl,setAllotedShares,clear} = directorFormData.actions
  
  export default directorFormData.reducer