import React, { useState, useEffect } from 'react';
import BusinessDashboardPanel from './business-dashboard-panel/business-dashboard-panel';
import BusinessAddressForm from '../business-address-form/business-address-form';
import useAxios from '../../../../../../hooks/axios';
import ResponsiveTable from '../../../../../../common/responsive-table/responsive-table';
import Director from '../director/director';
import ShareCapital from '../share-capital/share-capital';
import ShareHolder from '../share-holder/share-holder';
import BusinessNames from '../business-names/business-names';


const get_business_address_url = `${process.env.API_BASE_URL}/address`;
const get_business_names = `${process.env.API_BASE_URL}/business-name`;
const get_directors = `${process.env.API_BASE_URL}/director`;
const  get_share_capital_url = `${process.env.API_BASE_URL}/share-capital`;
const get_share_holders = `${process.env.API_BASE_URL}/share-holder`;


export default function BusinessDashboard(props) {

    const registrationCode = props.registrationCode;
    const setNavigation = props.setNavigation;
    const showDashboardPanel = props.showDashboardPanel;
    const setShowDashboardPanel = props.setShowDashboardPanel;
    const showAddressForm = props.showAddressForm;
    const setShowAddressForm = props.setShowAddressForm;
    const displayAddressTable = props.displayAddressTable;
    const setDisplayAddressTable = props.setDisplayAddressTable;
    const showDirectorForm = props.showDirectorForm;
    const setShowDirectorForm = props.setShowDirectorForm;
    const showShareCapitalForm= props.showShareCapitalForm;
    const setShowShareCapitalForm=props.setShowShareCapitalForm;
    const showShareHolderForm = props.showShareHolderForm;
    const setShowShareHolderForm = props.setShowShareHolderForm;
    const showBusinessNames = props.showBusinessNames;
    const setShowBusinessNames = props.setShowBusinessNames;
    const showDirectorTable = props.showDirectorTable;
    const setShowDirectorTable = props.setShowDirectorTable;
    const showDirectorDash=props.showDirectorDash;
    const setShowDirectorDash=props.setShowDirectorDash;
    const showShareCapitalTable = props.showShareCapitalTable;
    const setShowShareCapitalTable = props.setShowShareCapitalTable;
    
    const showShareCapitalDash = props.showShareCapitalDash;
    const setShowShareCapitalDash = props.setShowShareCapitalDash;

    const [businessAddressCount,setBusinessAddressCount] = useState(0);
    const [shareHolderCount, setShareHolderCount] = useState(0);
    
    const [loading, setLoading] =  useState(false);

    const axios = useAxios.useAxios({}).axios;


    const businessAddressesHeaders = ['Country','State','District','City','Town','Postal Addr.','Street Address'];
    const [businessAddressesData, setBusinessAddressesData] = useState([]);
    const [businessNamesCount, setBusinessNamesCount] = useState(0);
    const [directorCount, setDirectorCount] = useState(0);
    const [shareCapitalCount, setShareCapitalCount] = useState(0);

    

    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const [search, setSearch] = useState();


    async function countBusinessNames(){
        if(axios){
            axios.get(`${get_business_names}/${registrationCode}`).then((response)=>{
                setBusinessNamesCount(response.data.length);
            });
        }
    }

    async function countShareCapitalEntries(){
        if(axios){
            axios.get(`${get_share_capital_url}/${registrationCode}`).then((response)=>{
                setShareCapitalCount(response.data.shareCapital.length);
            });
        }
    }


    async function countDirectors(){
        if(axios){
            axios.get(`${get_directors}/${registrationCode}`).then((response)=>{
                setDirectorCount(response.data.directors.length);
            });
        }
    }


    async function countShareHolders(){
        if(axios){
            axios.get(`${get_share_holders}/${registrationCode}`).then((response)=>{
                setShareHolderCount(response.data.shareHolders.length);
            });
        }
    }

    

    async function loadBusinessAddresses(page_, limit_, search_) {

        setBusinessAddressCount(0);
        if(axios){
            await axios.get(`${get_business_address_url}/${registrationCode}?page=${page_||page}&limit=${limit_||limit}&search=${search_||search||''}`).then((resp)=>{
                setBusinessAddressCount(resp?.data?.addresses?.length);
                setBusinessAddressesData(resp?.data?.addresses);
                
                const total = resp.data.total;
                const pages = resp.data.pages;
                const page = resp.data.page;
                setPages(pages);
                setTotal(total);
                const data = [];
                resp?.data?.addresses.forEach((address)=>{
                    data.push([
                        address.country?.name,
                        address.state,
                        address.district,
                        address.city?.name,
                        address.town,
                        address.postalAddress,
                        address.streetAddress
                    ]);
                });
                setBusinessAddressesData(data);

                
            });
        }

    }

    useEffect(() => {
        if(axios){
            loadBusinessAddresses();
            countBusinessNames();
            countDirectors();
            countShareCapitalEntries();
            countShareHolders();
        }
        
        setShowAddressForm(false);
    }, [axios]);

    function handleSearch(event){
        const page_ = 0;
        const limit_ = 5;
        const search_ = event.target.value;
        setLimit(limit_);
        setPage(page_);
        setSearch(search_);
        loadBusinessAddresses(page_, limit_, search_);
    }

    function load(){
        loadBusinessAddresses();
    }

    function add(){
        setShowAddressForm(true);
        setDisplayAddressTable(false);
    }

    return (
        <div className="businessArea">
            <div className="dasbhoard-form-header"><span>Business Registration</span>  {registrationCode ? (<div className="regcodeDiv">{`${registrationCode}`}</div>) : ''} </div>
            {showDashboardPanel && <BusinessDashboardPanel shareHolderCount={shareHolderCount} shareCapitalCount={shareCapitalCount} setShowShareCapitalTable={setShowShareCapitalTable}  directorCount={directorCount} setDirectorCount={setDirectorCount} showDirectorDash={showDirectorDash} setShowDirectorDash={setShowDirectorDash} showDirectorTable={showDirectorTable} setShowDirectorTable={setShowDirectorTable} businessNamesCount={businessNamesCount} setShowBusinessNames={setShowBusinessNames} setShowShareHolderForm={setShowShareHolderForm} setShowShareCapitalDash={setShowShareCapitalDash} setShowDirectorForm={setShowDirectorForm} setDisplayAddressTable={setDisplayAddressTable} businessAddressCount={businessAddressCount} setNavigation={setNavigation} setShowAddressForm={setShowAddressForm} setShowDashboardPanel={setShowDashboardPanel} registrationCode={registrationCode}></BusinessDashboardPanel>}
            
            
            
            {displayAddressTable && <div className='searchStrip'>
                <div className='newAddress'>
                    <span className="fa fa-address-book" />
                    <span onClick={add}> &nbsp;Add Address</span> 
                </div>

                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}

                
                <div className='searchDiv'>
                    <span className='searchSpan'>
                        <label htmlFor="search">
                            <input id="search" type="text" onKeyUp={(e) => { setSearch(e.target.value); load(); }} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                        </label>
                    </span>
                </div>
            </div>}

            {displayAddressTable  && <ResponsiveTable dataFetchFn={loadBusinessAddresses} headers={businessAddressesHeaders} data={businessAddressesData} pagination={{ setPage: setPage, total: total, pages: pages, page: page }}></ResponsiveTable>}
            {showDirectorDash && <Director loading={loading} setLoading={setLoading} directorCount={directorCount} setDirectorCount={setDirectorCount} showDirectorForm={showDirectorForm} setShowDirectorDash={setShowDirectorDash} showDirectorTable={showDirectorTable} setShowDirectorTable={setShowDirectorTable}  setShowDirectorForm={setShowDirectorForm} registrationCode={registrationCode}></Director>}
            {showShareCapitalDash && <ShareCapital shareCapitalCount={shareCapitalCount} setShowShareCapitalDash={setShowShareCapitalDash} showShareCapitalDash={showShareCapitalDash} showShareCapitalForm={showShareCapitalForm} setShowShareCapitalForm={setShowShareCapitalForm} setShareCapitalCount={setShareCapitalCount} shareCapitalCount={shareCapitalCount} showShareCapitalTable={showShareCapitalTable} setShowShareCapitalTable={setShowShareCapitalTable}  showShareCapitalForm={showShareCapitalForm} registrationCode={registrationCode}> </ShareCapital>}
            {showShareHolderForm && <ShareHolder setShareHolderCount={setShareHolderCount}  registrationCode={registrationCode}></ShareHolder>}
            {showBusinessNames && <BusinessNames setBusinessNamesCount={setBusinessNamesCount} registrationCode={registrationCode}></BusinessNames>}
            {showAddressForm && <BusinessAddressForm setShowAddressForm={setShowAddressForm} setDisplayAddressTable={setDisplayAddressTable} loadData={loadBusinessAddresses} setBusinessAddressCount={setBusinessAddressCount} setShowDashboardPanel={setShowDashboardPanel} registrationCode={registrationCode}  setNavigation={setNavigation}></BusinessAddressForm>}
        </div>
    );
}