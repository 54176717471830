import React, { useState, useEffect } from 'react';
import './international-address.css'
import 'font-awesome/css/font-awesome.min.css'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/chakra-ui'
import { usePagination } from "@table-library/react-table-library/pagination";
import ReactLoading from "react-loading";

import { useCustomTableTheme } from './table-theme'
import useAxios from '../../../../../hooks/axios'
import InternationalAddressForm from './international-address-form/international-address-form';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../../../../hooks/auth-hook'
import { setUserId } from '../../../../../redux/actions/setUserId';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCustom } from "@table-library/react-table-library/table";
import { ResponsiveTable } from "responsive-table-react";
import { useMediaQuery } from 'react-responsive';

const BASE_URL = `${process.env.API_BASE_URL}/v1/town`;
const USER_INT_ADDR_URL = `${process.env.API_BASE_URL}/international-address`

const nodes = [];


//const data = { nodes };

export default function InternationalAddress() {

    const customTableTheme = useCustomTableTheme();
    const { user, roles } = useAuth();
    const { axios, initialized, token } = useAxios.useAxios({});
    const [displayTable, setDisplayTable] = useState(true);
    const [displayAddressForm, setDisplayAddressForm] = useState(false);
    const [internationalAddresses, setInternationalAddresses] = useState({ nodes });
    const [copySuccess, setCopySuccess] = useState('');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const userId = useSelector((state) => state.userId);
    const dispatch = useDispatch();
    const [data_, setData_] = useState([]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });


    const COLUMNS = [
        { label: '#', renderCell: (item) => item.position },
        { label: 'Country',  renderCell: (item) => item.country },
        { label: 'City',  renderCell: (item) => item.city },
        { label: 'Town',  renderCell: (item) => item.town },
        { label: 'Address', renderCell: (item) => item.address },
        { label: '',  renderCell: (item) => <span>  <i onClick={() => handleCopy(item)}  className="fa fa-copy" style={{fontSize:'18px'}}></i>&nbsp;&nbsp;<i className="fa fa-trash" aria-hidden="true" style={{fontSize:'18px'}}></i>&nbsp;&nbsp;<i className="fa fa-edit" style={{fontSize:'18px'}}></i>  </span>}
    ];


    const columns_ = [
        {
          "id": "country",
          "text": "Country"
        },
        {
          "id": "city",
          "text": "City"
        },
        {
          "id": "town",
          "text": "Town"
        },
        {
          "id": "address",
          "text": "Address"
        }
      ]
    
       


    const handleSearch = (event) => {
        setSearch(event.target.value);
      };

    useCustom("search", internationalAddresses, {
        state: { search },
        onChange: onSearchChange,
    });

    function onSearchChange(action, state) {
        fetchInternationalAddresses();
      }

    const pagination = usePagination(internationalAddresses, {
        state: {
            page: 0,
            size: 10,
        },
        onChange: onPaginationChange,
    });
    
    function onPaginationChange(action, state) {
       console.log(action, state);
    }
    

    useEffect(() => {

        if (initialized && user) {
            fetchInternationalAddresses();
        }

    }, [initialized, axios, user]);



    const fetchInternationalAddresses = async () => {
        const userId = user.id;
        if (userId) {
            dispatch(setUserId(userId));
            setLoading(true);
            await axios.get(`${USER_INT_ADDR_URL}/${userId}`)
                .then((response) => {

                    try {
                        setLoading(false);
                        const nodes = response.data.filter((item) =>{
                            
                                            return item?.country?.toLowerCase().includes(search.toLowerCase()) ||
                                            item?.city?.toLowerCase().includes(search.toLowerCase()) ||
                                            item?.address?.toLowerCase().includes(search.toLowerCase());
                                        });
                        setData_(nodes)
                        setInternationalAddresses({ nodes });
                    } catch (Error) {
                        console.error(Error);
                    }
                }
                );
        }
    }

    function addAddress() {
        setDisplayTable(false);
        setDisplayAddressForm(true);
    }

    const showToast = (toastMsg) => {
        console.log(toast);
        toast(toastMsg);
    }

    async function handleCopy(item) {
        try {
            const address = `${item.country}, ${item.city}, ${item.address}`;
            await navigator.clipboard.writeText(address);
            const msg = `Address ${address} copied to clipboard!`;
            setCopySuccess(msg);
            showToast(msg);
        } catch (err) {
            const msg = 'Failed to copy!';
            setCopySuccess(msg);
            showToast(msg);
        }
    }

    return (
        <div>
            <div className='searchStrip'>
                <div className='newAddress'>
                    <span className="fa fa-plus-square-o" />
                    <span onClick={addAddress}> &nbsp;Add Address..</span>
                </div>

                {loading &&
                <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}


                <div className='searchDiv'>
                    <span className='searchSpan'>
                    <label htmlFor="search">
                        <input id="search" type="text" value={search} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                   </label>
                    </span>
                </div>

            </div>
            <div className='appTable'>
                {(displayTable && !isTabletOrMobile) && <CompactTable pagination={pagination} columns={COLUMNS} data={internationalAddresses} theme={customTableTheme} layout={{ custom: true }} />}
                <ToastContainer />
                {(displayTable && isTabletOrMobile) &&   <ResponsiveTable columns={columns_} data={data_} /> }
                {displayTable && pagination.state.getTotalPages(internationalAddresses.nodes)>1 && 
                    <div style={{width: '100%', fontWeight:200,padding:'10px', display: "flex", justifyContent: "space-between" }}>
                    <span>Total Pages: {pagination.state.getTotalPages(internationalAddresses.nodes)}</span>
                    <span>
                    Page:{" "}
                    {pagination.state.getPages(internationalAddresses.nodes).map((_, index) => (
                        <button
                        className="pagingBtns"
                        key={index}
                        type="button"
                        style={{
                            fontWeight: pagination.state.page === index ? "bold" : "normal",
                        }}
                        onClick={() => pagination.fns.onSetPage(index)}
                        >
                        {index + 1}
                        </button>
                    ))}
                    </span>
                </div>}
                {displayAddressForm && <InternationalAddressForm fetchInternationalAddresses={fetchInternationalAddresses} setDisplayTable={setDisplayTable} setDisplayAddressForm={setDisplayAddressForm}></InternationalAddressForm>}
            </div>
        </div>
    );
}