import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth-hook';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import './signup.css';
import './signup-max-width-600px.css';
import './signup-min-width-600px.css';
import './signup-min-width-768px.css';
import './signup-min-width-992px.css';
import { useKeycloak } from '@react-keycloak/web';
import MD5 from 'crypto-js/md5';
import { useSelector, useDispatch } from 'react-redux';
import {
    setBusinessName, setUserName, setPassword, setConfirmPassword,
    setAimUserId, setRealmId, setRealmName, setWebClientId, setApiClientId,
    setApiClientSecret, setSynchronized, setEmailAddress, setTenantId, setTenantName,
    setCurrentRealm, setKeycloaks, setCurrentKeycloak, setUserType, clear
} from '../../util/redux/registrationDataSlice';
import useAxios from '../../hooks/axios';
import DOMPurify from 'dompurify';
import CrobodLoader from '../loaders/CrobodLoader';

const registrationUpdateUrl = `${process.env.API_BASE_URL}/registration/put`;
const registrationUrl = `${process.env.API_BASE_URL}/registration/register`;
const getregistrationRequestSessionUrl = `${process.env.API_BASE_URL}/registration/get`;
const contextPropsUrl = `${process.env.API_BASE_URL}/context/switch`;

export default function Signup(props) {

    const switchKeycloakContext = props.switchKeycloakContext;
    const switchRealm = props.switchRealm;
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };


    const registrationData = useSelector((state) => state.registrationData.value);
    const dispatch = useDispatch();

    useDocumentTitle(`${registrationData.tenantName || 'Crobod Africa Technologies'} | Signup ::`);

    const { keycloak } = useKeycloak(true);
    //const { keycloak, authenticated, initializeKeycloak } = useKeycloak();

    const auth = useAuth();
    const { axios, initialized, token } = useAxios.useAxios({});

    
    var businessName = registrationData.businessName;
    var userName = registrationData.userName;
    var emailAddress = registrationData.emailAddress;
    var password = registrationData.password || '';
    var confirmPassword = registrationData.confirmPassword || '';

    var aimUserId = registrationData.aimUserId;
    var realmId = registrationData.realmId;
    var realmName = registrationData.realmName;
    var webClientId = registrationData.webClientId;
    var apiClientId = registrationData.apiClientId;
    var apiClientSecret = registrationData.apiClientSecret;
    var synchronized = registrationData.synchronized;
    var tenantId = registrationData.tenantId;
    var userType = registrationData.userType;
    var tenantName = registrationData.tenantName;
    const [displayLoader, setDisplayLoader] = useState(false);
    const [loaderMsg, setLoaderMsg] = useState([]);
    const [progressTimer, setProgressTimer] = useState();

    const [loaderInterval, setLoaderInterval] = useState();
    const [regIsComplete, setRegIsComplete] = useState(false);

    const [setupInProgress, setSetupInProgress] = useState(false);
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [pollingInProgress, setPollingInProgress] = useState(false);
    const [emailAddress_, setEmailAddress_] =  useState('');


    useEffect(() => {

        const getSessionData = async function () {
            try {
                await axios.get(getregistrationRequestSessionUrl)
                    .then((response) => {
                        let respData = DOMPurify.sanitize(JSON.stringify(response.data));
                        
                        var regDataFromSession = JSON.parse(respData);
                        if (regDataFromSession.userName) {//Only if we at least have a username
                            updateRegistrationData(regDataFromSession.response);//Cascade through redux and UI
                        }
                    });
            } catch (Error) {
                if (Error.message === 'Network Error') {
                    console.log(`Network error: ${Error.stack}`);
                } else {
                    console.log(Error);
                }
            }
        }
        const getContextProps = async function () {
            try {
                const response = await axios.get(`${contextPropsUrl}/${tenantId || -1}`)///${businessName}
                    .then((response) => {
                        let respData = DOMPurify.sanitize(JSON.stringify(response.data));
                        const data_ = JSON.parse(respData);

                        return respData;
                    });
                return response;
            } catch (Error) {
                console.log(Error);
            }

        }

        if (initialized) {
            getSessionData();
            getContextProps().then((response) => {
                
                if (response) {
                    const respData = JSON.parse(response);
                    if (respData.id && respData.id > -1) {
                        dispatch(setTenantName(registrationData.businessName || respData.name));
                        dispatch(setBusinessName(registrationData.businessName || respData.name || ''));
                        dispatch(setUserName(registrationData.userName || respData.userName || 'newtenant@website.com'));
                        dispatch(setRealmId(respData.realmId || registrationData.businessName || -1));
                        const realmId = registrationData.realmId;

                        if (respData.realmId && respData.realmId !== '') {
                            sessionStorage['realmId'] = respData.realmId;
                            
                            var dologout_or_open_new_tab = keycloak.realm !== respData.realmId;
                            if (auth.isAuthenticated && dologout_or_open_new_tab) {
                            }
                        }
                        if (respData.tenantId) {
                            dispatch(setTenantId(`${respData.tenantId}`));
                        }
                    }
                } else {
                    sessionStorage['realmId'] = process.env.CORE_KC_REALM_NAME || 'crobod-dev';
                }
            });

            // Clean up the socket connection when the component unmounts
            return () => {

            };
        }

    }, [initialized]);

    function componentWillUnmount() {
        clearTimeout(progressTimer);
    }

    async function pollProgress(isFinal) {//Because we could not make sockts work.. Reminder 3 days were wasted before arriving to this solution. Never waste time again if there is a ready solution!!!

        if(!pollingInProgress) {
            if (!displayLoader || !(isFinal || false)) {

                setPollingInProgress(true);

                const businessNameAndEmailAddress = `${registrationData.businessName}${registrationData.userName}`;
                console.log(`:::::: businessNameAndEmailAddress  ->  ${businessNameAndEmailAddress}`);
                const businessNameEmailHash = MD5(businessNameAndEmailAddress).toString();
                const accountProgressSetupUpdateUrl = `${process.env.API_BASE_URL}/registration/progress/${businessNameEmailHash}`;


                await axios.get(accountProgressSetupUpdateUrl)
                    .then((response) => {

                        setPollingInProgress(false);//release for next interval

                        const updateStream = response.data;
                        if (updateStream && updateStream.length > 0) {

                            const newMsgs = [];
                            updateStream.forEach((update) => {
                                newMsgs.push(`${update.status}#${update.processId}#${update.processDescription}`);
                            });

                            setLoaderMsg(newMsgs);

                        } else {
                        }

                    });

            } else {
                console.log('Loader has been disabled, won\'t make API calls.');
            }
        } else {
            //stopCallerInterval();
            console.log('Wait to poll...');
        }

    }


    async function updateRegistrationData(data) {

        try {
            businessName = data.updatedRegistration.businessName;
            userName = data.updatedRegistration.userName;
            emailAddress = data.updatedRegistration.emailAddress;
            password = data.updatedRegistration.password;
            confirmPassword = data.updatedRegistration.confirmPassword;
            aimUserId = data.updatedRegistration.aimUserId;
            realmName = data.updatedRegistration.realmName;
            webClientId = data.updatedRegistration.webClientId;
            apiClientId = data.updatedRegistration.apiClientId;
            apiClientSecret = data.updatedRegistration.apiClientSecret;
            synchronized = data.updatedRegistration.synchronized;
            realmId = data.updatedRegistration.businessName;
            tenantId = data.tenantId;
            userType = data.userType;

            tenantName = data.updatedRegistration.businessName;//Tenant name and business name are the same.

            if (data.updatedRegistration.tenantName || data.updatedRegistration.name)
                dispatch(setTenantName(data.updatedRegistration.tenantName
                    || data.updatedRegistration.name
                ));
            if (tenantId)
                dispatch(setTenantId(tenantId));
            if (businessName)
                dispatch(setBusinessName(businessName));
            if (userName)
                dispatch(setUserName(userName));
            if (password)
                dispatch(setPassword(password));
            if (confirmPassword)
                dispatch(setConfirmPassword(confirmPassword));
            if (aimUserId)
                dispatch(setAimUserId(aimUserId));
            if (realmId)
                dispatch(setRealmId(realmId));
            if (realmName)
                dispatch(setRealmName(realmName));
            if (webClientId)
                dispatch(setWebClientId(webClientId));
            if (apiClientId)
                dispatch(setApiClientId(apiClientId));
            if (apiClientSecret)
                dispatch(setApiClientSecret(apiClientSecret));
            if (synchronized)
                dispatch(setSynchronized(synchronized));
            if (emailAddress)
                dispatch(setEmailAddress(emailAddress));
            if (userType)
                dispatch(setUserType(userType));
        } catch (Error) {
            console.error(Error);
        }

    }

    async function doResetForm(event) {
        event.preventDefault();
        checkIfAllFieldsOk();
        document.getElementById('userType').value = '';
        await dispatch(clear());
    }

    async function doRegister(event) {

        event.preventDefault();

        setIsDisabled(true);//Disable the Submit button

        setRegIsComplete(false);

        setSetupInProgress(true);

        stopCallerInterval();//Stop any prior loader interval

        setPollingInProgress(false);

        const registrationObject = {
            businessName: registrationData.businessName,
            userName: registrationData.userName,
            emailAddress: registrationData.userName,
            password: registrationData.password,
            confirmPassword: confirmPassword,
            aimUserId: registrationData.aimUserId,
            realmId: registrationData.businessName.replaceAll(' ','_'),
            realmName: registrationData.businessName,
            webClientId: registrationData.webClientId,
            apiClientId: registrationData.apiClientId,
            apiClientSecret: registrationData.apiClientSecret,
            synchronized: registrationData.synchronized,
            userType: registrationData.userType
        };

        setEmailAddress_(registrationData.userName);//So we can log in leter.
        const purifiedInput = DOMPurify.sanitize(JSON.stringify(registrationObject));

        if (!tenantId || tenantId <= -1) {

            setLoaderMsg([`Setting up <b><i>${businessName}</i></b>. Please wait...`]);
            setDisplayLoader(true);

            const progressTimer_ = () => {
                const isFinal = false;
                pollProgress(isFinal);
            };

            setLoaderInterval(setInterval(progressTimer_, 500));

            setProgressTimer(progressTimer_);

            progressTimer_();

            setSubmitInProgress(true);

            await axios.post(registrationUrl, purifiedInput)
                .then((response) => {

                    let respData = DOMPurify.sanitize(JSON.stringify(response.data));
                    console.log(`\n\t::: respData -> ${respData}`);
                    const data_ = JSON.parse(respData);
                    
                    if (!data_.cause
                        && ((response.status == 200 || response.status === 201) )) {//Ony if we have an Id, meaning we have a db entry

                        try {
                            
                            //updateRegistrationData(data_);//Cascade through redux and UI

                            sessionStorage['realmId'] = data_.realmId;
                            tenantId = data_.tenantId;
                            auth.meta.keycloak.realm = data_.realmId;
                            switchKeycloakContext(data_.realmId, 'login-required');
                            keycloak.realm = data_.realmId;
                            dispatch(  setRealmId(data_.realmId) );
                            dispatch(  setTenantId(data_.tenantId) );

                        } catch (Error) {
                            console.log(Error);
                        }

                    } else {
                        console.error(data_);
                    }

                });

            const tenantId_ = tenantId;
            clearForm();
            await dispatch(setTenantId(tenantId_));

        } else {


            if (!auth.isAuthenticated) {
                if (keycloak.realm !== process.env.CORE_KC_REALM_NAME) {
                    auth.login();
                } else {
                    console.log('Not allowed to log into crobod app realms');
                }
            }

            const purifiedInput = DOMPurify.sanitize(JSON.stringify(registrationObject));

            try {

                setLoaderMsg([`Updating <b><i>${businessName}</i></b>. Please wait...`]);
                setDisplayLoader(true);

                const progressTimer_ = () => {
                    const isFinal = false;
                    pollProgress(isFinal);
                };

                setLoaderInterval(setInterval(progressTimer_, 500));

                setProgressTimer(progressTimer_);

                progressTimer_();

                setSubmitInProgress(true);

                await axios.put(`${registrationUpdateUrl}/${tenantId}?tenantId=${tenantId}`, purifiedInput)
                    .then((response) => {
                        let respData = DOMPurify.sanitize(JSON.stringify(response.data));
                        const data_ = JSON.parse(respData);
                        if (data_ && !data_.cause
                            && ((response.status == 200 || response.status === 201)
                                && data_.id)) {//Ony if we have an Id, meaning we have a db entry

                            const updateObject = { updatedRegistration: data_, tenantId: data_.id };
                            const updateObject_ = JSON.stringify(updateObject);
                            updateRegistrationData(JSON.parse(updateObject_));//Cascade through redux and UI


                        } else {

                        }
                    });
            } catch (Error) {
                if (Error.code === 'ERR_NETWORK') {
                    console.info('Connectivity problem. Could not connect to network.');
                } else {
                    console.error(Error);
                }
            }
        }

    }


    function loginLogout() {
        
        if (auth.isAuthenticated) {
            clearForm();
            sessionStorage['realmId'] = process.env.CORE_KC_REALM_NAME ;
        }
        const loginOpts = {
            scope: 'openid',
            redirectUri: `${process.env.BASE_URL}/dashboard`,
            prompt: 'login',
            action: 'tologin',
            maxAge: '100000',
            loginHint: emailAddress_,
            idpHint: 'idpHint',
            cordovaOptions: null
        };
        auth.isAuthenticated ? auth.logout() : keycloak.realm.indexOf('crobod-') === -1 ? auth.meta.keycloak.login(loginOpts) : auth.meta.keycloak.login(loginOpts);
    }

    function hasSpecialCharacters(str) {
        const regex = /[^a-zA-Z0-9\s]/;
        return regex.test(str);
    }

    const [emailFieldError, setEmailFieldError] = useState('* Required');
    const [isDisabled, setIsDisabled] = useState(true);
    const [biznameFieldError, setBiznameFieldError] = useState('* Required');
    const [passFieldError, setPassFieldError] = useState('* Required');
    const [userTypeFieldError, setUserTypeFieldError] = useState('* Required');


   function getInnerHTMLByElementId(id){
       const element = document.getElementById(id);
       return element?.value;
   }

    async function checkIfAllFieldsOk(fieldId, value) {
       
        const userTypeValid = getInnerHTMLByElementId('userType')!=='--' && getInnerHTMLByElementId('userType')!=='';
        const businessNameValid =  getInnerHTMLByElementId('businessName') && getInnerHTMLByElementId('businessName').length>=document.getElementById('businessName').minLength;
        const emailAddressValid =  getInnerHTMLByElementId('emailAddress');
        const passwordValid =   getInnerHTMLByElementId('password');
        const confirmPasswordValid =  getInnerHTMLByElementId('confirmPassword');
        const passwordsDoMatch = passwordValid===confirmPasswordValid;

        if(!passwordValid){
            setPassFieldError('* Required');
        }
        if(!emailAddressValid){
            setEmailFieldError('* Required');
        }
        if(!businessNameValid){
            setBiznameFieldError('* Required');
        }
        if(!userTypeValid){
            setUserTypeFieldError('* Required');
        }
        
        const allfieldsValid = passwordsDoMatch && userTypeValid && businessNameValid && emailAddressValid && passwordValid &&  confirmPasswordValid;
        
        setIsDisabled(!allfieldsValid);

        return allfieldsValid;
    }


    async function handleEmailInputChange(e) {

        dispatch(setUserName(e.target.value));

        if (!validateEmail(e.target.value)) {
            setEmailFieldError('Email is invalid.');
        } else {
            setEmailFieldError('');
        }
        checkIfAllFieldsOk();
    }



    function handleBusinessNameInputChange(e) {

        dispatch(setBusinessName(e.target.value));

        if (hasSpecialCharacters(e.target.value)) {
            setBiznameFieldError('Business names should not contain special characters');
        }else if(e.target.value===''){
            setBiznameFieldError('* Required');
        }else if(e.target.value.length<document.getElementById('businessName').minLength){
            setBiznameFieldError('* Should be more than 3 characters long');
        } else {
            setBiznameFieldError('');
        }
        checkIfAllFieldsOk();
    }



    const [passLenEr, setPassLenEr] = useState();
    const [passMatchEr, setPassMatchEr] = useState();
    
    
    function handlePassInputChange(e) {

        dispatch(setPassword(e.target.value));

        const passLenError = 'Passwords must be at least 3 characters long.';
        let errormsg = '';

        if (e.target.value.length < 3) {
            errormsg = passLenError;
            setPassLenEr(passLenError);

        } else {
            if (confirmPassword != '' || e.target.value === confirmPassword) {
                setPassLenEr('');
                //setPassFieldError('');
            }
        }


        let errormsg2 = '';

        if (confirmPassword && confirmPassword != '') {
            const passNoMatch = 'Passwords do not match!';
            if (e.target.value !== confirmPassword) {
                errormsg2 = passNoMatch;
                setPassMatchEr(passNoMatch);
            } else {
                setPassMatchEr('');
                if (e.target.value.length >= 3) {
                    //setPassFieldError('');
                }
            }
        }

        setPassFieldError(`${errormsg}${errormsg2 && errormsg2.length > 0 ? '.' : ''}${errormsg2}`);

        checkIfAllFieldsOk();
    }

    function handlePassConfirmation(e) {
        dispatch(setConfirmPassword(e.target.value));


        let errormsg2 = '';

        if (password && password != '') {
            const passNoMatch = 'Passwords do not match!';
            if (e.target.value !== password) {
                errormsg2 = passNoMatch;
            }
        }

        const passLenError = 'Passwords must be at least 3 characters long.';
        let errormsg = '';

        if (e.target.value.length < 3) {
            errormsg = passLenError;
        }

        setPassFieldError(`${errormsg}${errormsg2 && errormsg2.length > 0 ? '.' : ''}${errormsg2}`);

        checkIfAllFieldsOk();
    }

    function clearForm() {
        document.getElementById('userType').value = '';
        dispatch(clear());
        checkIfAllFieldsOk();
    }


    function stopCallerInterval() {
        clearInterval(loaderInterval);
    }

    async function handleUserTypeChange(e){
        e.preventDefault();

        if(e.target.value!=='--'){
            dispatch( setUserType(e.target.value)) ;
            setUserTypeFieldError('');
        }else{
            dispatch( setUserType('')) ;
            setUserTypeFieldError('* Required');
        }
        
    }

    return (


        <div className="signupPageIndex">

            <CrobodLoader setRegIsComplete={setRegIsComplete} setupInProgress={setupInProgress} setSetupInProgress={setSetupInProgress} submitInProgress={submitInProgress} auth={auth} displayLoader={displayLoader} regIsComplete={regIsComplete} clearForm={clearForm} setDisplayLoader={setDisplayLoader} loginLogout={loginLogout} stopCallerInterval={stopCallerInterval} message={loaderMsg} loader='spin' display={displayLoader} />


            <div className="signupSection">
                {auth.isAuthenticated && (
                    <div className="signupTopBannerIndex">
                        {auth.isAuthenticated && (
                            <div className="sibnupButtonDivIndex">
                                {auth.isAuthenticated && (
                                    <p className="welcomeSpan welcomespantext" >Welcome, <b><i className="welcomespantext">{auth ?.user ?.fullName}</i></b></p>
                                )}
                                <div className="topLoginButtonIndex"  onClick={() => { loginLogout() }}>{auth.isAuthenticated ? 'Logout' : 'Login'}</div>
                            </div>
                        )}
                    </div>
                )}

               

                <div className="formBodyIndex" onMouseLeave={checkIfAllFieldsOk}>

                <div className="signupHeaderIndex">
                    {(registrationData.tenantId !== '' && registrationData.tenantId > -1) ? 'Update' : 'Sign Up'}
                </div>

                    <div className="formDivIndex" onClick={checkIfAllFieldsOk} >
                        <div className="formFieldIndex" >
                            <label className="formLabelIndex" >
                                I am a;
                            </label>
                            <select required id="userType" onChange={async (e)=>{await handleUserTypeChange(e); await checkIfAllFieldsOk();}} name="userType" className="formInputIndex">
                                    <option value="--"></option>
                                    <option value="borrower">Borrower</option>
                                    <option value="lender">Lender</option>
                            </select>
                            <div>
                                {userTypeFieldError && <p id="userTypeFieldError" className="fieldError">{userTypeFieldError}</p>}
                            </div>
                        </div>
                        <div className="formFieldIndex" >
                            <label className="formLabelIndex" >
                                Business Name (Can change later)
                            </label>
                            <input id="businessName" className="formInputIndex" onKeyUp={checkIfAllFieldsOk} value={businessName} onChange={async (e) => { handleBusinessNameInputChange(e); await checkIfAllFieldsOk(); }} minLength="3" name="password" type="text" required />
                            <div>
                                {biznameFieldError && <p id="biznameFieldError"  className="fieldError">{biznameFieldError}</p>}
                            </div>
                        </div>

                        <div className="formFieldIndex" >
                            <label className="formLabelIndex">
                                Username (Email Address)
                        </label>
                            <input id="emailAddress" className="formInputIndex" onKeyUp={checkIfAllFieldsOk} value={userName} onChange={async (e) => { await handleEmailInputChange(e); await checkIfAllFieldsOk(); }}  name="username" type="email" placeholder="user@example.com" required></input>
                            <div>
                                {emailFieldError && <p id="emailFieldError" className="fieldError">{emailFieldError}</p>}

                            </div>
                        </div>

                        <div className="formFieldIndex" >
                            <label className="formLabelIndex">
                                Password:
                            </label>
                            <input id="password" className="formInputIndex" value={password} onChange={async (e) => { await handlePassInputChange(e); await checkIfAllFieldsOk();  }}  name="password" type="password" minLength="3" maxLength="20" required></input>
                            <div>
                                {passFieldError && <p id="passFieldError" className="fieldError" >{passFieldError}</p>}
                            </div>
                        </div>

                        <div className="formFieldIndex"  >
                            <label className="formLabelIndex">
                                Confirm Password:
                            </label>
                            <input id="confirmPassword" className="formInputIndex" onKeyUp={checkIfAllFieldsOk} value={confirmPassword} onChange={async (e) => { await handlePassConfirmation(e); await checkIfAllFieldsOk(); }} name="confirmPassword" type="password" minLength="3" maxLength="20" required></input>
                        </div>

                        <div className="buttonzDivIndex" >

                            <div className="formButtonzIndex">
                                <button className="formButtonStyleIndex" onClick={(e) => { return doResetForm(e) }} >Reset Form</button>
                            </div>

                            <div className="formButtonzIndex">
                                <button className="formButtonStyleIndex" onClick={(e) => { return doRegister(e) }} disabled={isDisabled} >{(registrationData.tenantId && registrationData.tenantId !== -1) ? 'Update.' : 'Register'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}