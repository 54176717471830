import React from 'react';
import './international-address-form.css'
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useAuth } from '../../../../../../hooks/auth-hook'
import CitySelect from './city-select/city-select';
import Country from '../../../../../../common/model/country';
import useAxios from '../../../../../../hooks/axios';
import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from 'react-redux';
import { setUserId } from '../../../../../../redux/actions/setUserId';
import ReactLoading from "react-loading";

const country_url = `${process.env.API_BASE_URL}/v1/country`;
const search_country_url = `${process.env.API_BASE_URL}/v1/country/search/`;
const internationalAddrUrl = `${process.env.API_BASE_URL}/v1/international-address`;



export default function InternationalAddressForm(props) {

    const setDisplayAddressForm = props.setDisplayAddressForm;
    const setDisplayTable = props.setDisplayTable;
    const fetchInternationalAddresses = props.fetchInternationalAddresses;

    const [countrySearchTerm, setCountrySearchTerm] = useState({});
    const [countries, setCountries] = useState([{ value: '-1', label: 'Select Countries' }]);
    const { token, initialized: authInitialized, user, roles } = useAuth();
    const [loading, setLoading] = useState(false);
    const [inputValue, setValue] = useState('');
    const [selectedCountry, setSelectedCountry] = useState();
    const [options, setOptions] = useState([]);
    const [address, setAddress] = useState([]);
    const [citySelect, setCitySelect] = useState(<></>);
    const [selectedCity, setSelectedCity] = useState();
    const [citySelectError, setCitySelectError] = useState('* Required');
    const [countrySelectError, setCountrySelectError] = useState('* Required');
    const [addressError, setAddressError] = useState('* Required');
    const [allFieldsValid, setAllFieldsValid] = useState();
    var selectedCity_  = '';

    const axios = useAxios.useAxios({}).axios;
    const userId = useSelector((state) => state.userId);
    const navigationStateData = useSelector((state) => state.navigationStateDataSlice);
    const dispatch = useDispatch();

    function setselectedCity_(sl){
        selectedCity_ = sl; 
    }
    useEffect(() => {
        setCitySelect(<CitySelect validateAllFields={validateAllFields} setCitySelectError={setCitySelectError} selectedCity={selectedCity} setSelectedCity={setSelectedCity} token={token} country={selectedCountry}></CitySelect>);
    }, [selectedCountry]);


    // handle input change event
    const handleInputChange = async value => {
        setValue(value);
        await validateAllFields();
    };

    // handle selection
    const handleChange = async value => {
        setSelectedCountry(value);
        await validateAllFields();
        if(value){
            setCountrySelectError('');
        }
    }

    async function clearForm(event){
        event.preventDefault();
        setDisplayAddressForm(false);
        setDisplayTable(true);
        fetchInternationalAddresses();
    }


    async function validateAllFields(){
        const selectedCountryValid = selectedCountry?true:false;
        const selectedCityValid = selectedCity?true:false;
        const addressValid = address!==undefined && address!=='' && address.length>=3;
        if(!selectedCountryValid){
            setCountrySelectError('* Required');
        }else{
            setCountrySelectError('');
        }
        if(!selectedCityValid){
            setCitySelectError('* Required');
        }else{
            setCitySelectError('');
        }
        if(!addressValid){
            setAddressError('* Required');
        }else{
            setAddressError('');
        }
        const allFieldsValid =  selectedCountryValid && selectedCityValid && addressValid;
        setAllFieldsValid(allFieldsValid);
        return allFieldsValid;
    }

    async function post(event) {
        event.preventDefault();

        const allFieldsValid = await validateAllFields();
        
        if(allFieldsValid===true){
            
            dispatch(setUserId(user.id));

            setAllFieldsValid(false);

            setLoading(true);

            const internationalAddress = {
                countryId: selectedCountry.id,
                cityId: selectedCity.id,
                address: address,
                userId: user.id
            };

            const axios_ = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: authInitialized ? `Bearer ${token}` : undefined
                }
            });
            await axios_.post(internationalAddrUrl, internationalAddress)
                .then((response) => {
                    let respData = DOMPurify.sanitize(JSON.stringify(response.data));
                    setLoading(false);
                });

        }else{
            console.log(`not all fields are valid`);
        }

    }


    const promiseOptions = (inputValue) =>  new Promise((resolve, reject) => {
            const axios_ = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: authInitialized ? `Bearer ${token}` : undefined
                }
            });

            try {

                if (inputValue) {
                    setLoading(true);
                    const search_url = `${search_country_url}${inputValue}`;
                    axios_.get(search_url).then(result => {
                        const res = result.data;
                        setLoading(false);
                        setOptions(res);
                        resolve(res);
                    }).then((resss) => {

                        if (resss) {
                            console.log(resss);
                            resolve(resss);
                        } else {
                            //console.log('resss->', resss);
                        }
                    });

                } else {
                    setLoading(true);
                    axios_.get(country_url).then(result => {
                        const countries__ = result.data;
                        setLoading(false);
                        setOptions(countries__);
                        return countries__;
                    });
                    setCountries(countries);
                    resolve(countries);
                }
            } catch (e) {
                console.error(e);
                reject(e);
            }

        });

    return (

        <div className="internationalAddressForm">

                <div className="formHeader">International Address</div>

            
                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="countrySelect">Country</label>
                    <AsyncSelect
                        id="countrySelect"
                        cacheOptions
                        defaultOptions
                        placeholder='Start typing country name'
                        value={selectedCountry}
                        options={options}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e.id}
                        loadOptions={promiseOptions}
                        onInputChange={handleInputChange}
                        onChange={handleChange} />
                       {countrySelectError && <span className="formError" id="countrySelectError">{countrySelectError}</span>}
                </div>
                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="citySelect">Select City</label>
                    {citySelect}
                    {citySelectError && <span className="formError" id="citySelectError">{citySelectError}</span>}
                </div>
                <div className="formFieldInput">
                    <label  className="formLabels" htmlFor="address">Address</label>
                    <input id="address" className="formInputs" onChange={async (e) => {   setAddress(e.target.value); await validateAllFields(); }} placeholder=''></input>
                    {addressError && <span className="formError" id="addressError">{addressError}</span>}
                </div>
                {loading &&   
            <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                <div className="formFieldInput btns">
                    <button className="cancelBtn" onClick={(e) =>{return clearForm(e)}}>Cancel</button>
                    <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
                </div>
           
        </div>
    )

}