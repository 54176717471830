import React from 'react'
import checklist from '../../assets/checklist.png'
import './feature.css'

const Feature = ( {heading, main}) => {
  return (
    <div className='feature'>
        <div className="feature-content">
          <h2>{heading}</h2>
          <img src={checklist} alt="Icon" />
          <p>{main}</p>
        </div>
    </div>
  )
}

export default Feature