import { useCallback, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { useKeycloak } from '@react-keycloak/web'
//import { useKeycloak } from '../util/KeycloakContext';
import { commonNotification } from '../common/common'
import {
  setBusinessName, setUserName, setPassword, setConfirmPassword,
  setAimUserId, setRealmId, setRealmName, setWebClientId, setApiClientId,
  setApiClientSecret, setSynchronized, setEmailAddress, setTenantId, setTenantName, clear
} from '..//util/redux/registrationDataSlice';
import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from 'react-redux';
import useAxios from './axios';
import axios from 'axios';

const kcConfigUrl = `${process.env.API_BASE_URL}/context/get`;//:tenantId as path param


/**
 * Returns the auth info and some auth strategies.
 *
 */
export const useAuth = () => {
  const { keycloak, initialized } = useKeycloak(false);
  //const { keycloak, authenticated, initializeKeycloak } = useKeycloak();
  //c/onst [initialized, setInitialized] = useState();

  const setNotification = useSetRecoilState(commonNotification);
  const [user, setUser] = useState({});
  const registrationData = useSelector((state) => state.registrationData.value)
  const dispatch = useDispatch();
  const [intervalIDs, setIntervalIDs] = useState([]);

  // fetch user profile
  useEffect(() => {


    if (!initialized) {

    }

    if (intervalIDs.length > 0) {

      intervalIDs.forEach(intervl => {
        clearInterval(intervl);
      });
    }

    if (initialized && keycloak && keycloak?.isAuthenticated) {
      const iID = setInterval(() => {

        keycloak.updateToken(70)
          .then(
            function (refreshed) {
              if (refreshed) {
                //console.log('Token was successfully refreshed');
              } else {
                //console.log('Token is still valid');
              }
            })
          .catch(() => {
            keycloak.login();
          });

      }, 60000);
      intervalIDs.push(iID);
    }

    const fetchKcContext = async function () {
      if (initialized) {
        try {
          const config = {};
          const instance = axios.create({
            ...config,
            headers: {
              ...(config.headers || {}),
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: initialized ? `Bearer ${keycloak.token}` : undefined,
            },
          });
          const url = `${kcConfigUrl}/${((registrationData.tenantId === '' || !registrationData.tenantId) ? -1 : registrationData.tenantId)}`;
          const response = await instance.get(url)
            .then((response) => {
              let respData = DOMPurify.sanitize(JSON.stringify(response.data));
              const respData_ = JSON.parse(respData);
              const kcConfig = {
                url: process.env.CORE_KC_BASE_URL,
                realmId: respData_.realmId || process.env.CORE_KC_REALM_NAME,
                clientId: respData_.uiClientId || process.env.KC_WEB_CLIENT_ID,
                pkceMethod: process.env.KC_WEB_CLIENT_PKCE_METHOD,
                KeycloakResponseType: process.env.KC_RESPONSE_TYPE,
                onLoad: process.env.KC_ONLOAD || 'check-sso',//'login-required'//,
                checkLoginIframe: process.env.KC_CHECK_LOGIN_IFRAME || false
              };
              //console.log(`:::::  kcConfig    ->   ${kcConfig}`);
              if (keycloak && kcConfig && kcConfig.realmId && (kcConfig.realmId !== keycloak.realm)) {
                kcConfig.realmId = keycloak.realm;
              }

              return kcConfig;
            });
          return response;
        } catch (Error) {

          if (Error.code === 'ERR_NETWORK') {
            console.log('API readiness check.');
          } else {
            console.log(Error.code);
            console.log(Error);
          }
        }
      }

    }

    fetchKcContext().then((kcConfig) => {
      if (kcConfig && kcConfig.realmId) {
        dispatch(setRealmId(kcConfig.realmId));
        if (keycloak.realm !== kcConfig.realmId) {

        }
      }
    });


    if (!initialized) {
      return;
    }

    const fetchUserInfo = async () => {
      try {
        const userProfile = await keycloak.loadUserProfile();
        setUser({ ...userProfile, fullName: `${userProfile.firstName} ${userProfile.lastName}` });
      } catch (err) {
        console.log(err);
        setNotification({ isVisible: true, message: err.message });
      }
    };

    if (keycloak.authenticated) {
      fetchUserInfo();
    }
  }, [keycloak, initialized]);

  return {
    isAuthenticated: !!keycloak.authenticated,
    initialized,
    meta: {
      keycloak,
    },
    token: keycloak.token,
    user,
    roles: keycloak.realmAccess,
    login: useCallback((opts) => { 
      if(opts){
        if(keycloak){
          keycloak.login(opts);
        }
      }else{
        if(keycloak && keycloak.login ){
          try{
            keycloak.login();
          }catch(Error){
            console.error(Error);
          }
        }
      }
    },[keycloak]), 
    logout: useCallback(() => { keycloak.logout(); }, [keycloak]),
    register: useCallback(() => { keycloak.register(); }, [keycloak]),
  };
};

export default {
  useAuth,
};