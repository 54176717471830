import { createSlice } from '@reduxjs/toolkit'
const defaultval_ = {
    url: '',
    page: '',
    menus: [],
    clickedLinks: [],
};
export const navigationStateData = createSlice({
    name: 'navigationStateData',
    initialState: {
        value: defaultval_,
    },
    reducers: {
        setUrl: (state, action) => {
            state.value.url = action.payload;
        },
        setPage: (state, action) => {
            state.value.page = action.payload;
        },
        setMenus: (state, action) => {
            state.value.menus = action.payload;
        },
        setClickedLinks: (state, action) => {
            state.value.clickedLinks = action.payload;
        },

        clear: (state) => {
            state.value = defaultval_
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUrl, setPage, setMenus, setClickedLinks, clear } = navigationStateData.actions

export default navigationStateData.reducer