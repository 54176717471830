import React, { useEffect, useState } from 'react';
import './shipper-form.css';
import useAxios from '../../../../../../hooks/axios';
import AsyncSelect from 'react-select/async';
import ReactLoading from "react-loading";

const country_url = `${process.env.API_BASE_URL}/v1/country`;
const search_country_url = `${process.env.API_BASE_URL}/v1/country/search/`;
const shipper_post_url = `${process.env.API_BASE_URL}/shipper`;


export default function ShipperForm(props) {

    const setDisplayShipperForm = props.setDisplayShipperForm;
    const setDisplayTable = props.setDisplayTable;

    const [shipperNameError, setShipperNameError] = useState('* Required');
    const [shipperName, setShipperName] = useState('');
    const [loading, setLoading] = useState(false);
    const [allFieldsValid, setAllFieldsValid] = useState();
    const { axios, initialized, token } = useAxios.useAxios({});
    const [options, setOptions] = useState([]);
    const [countries, setCountries] = useState([{ value: '-1', label: 'Select Countries' }]);
    const [selectedCountry, setSelectedCountry] = useState();
    const [countrySelectError, setCountrySelectError] = useState('* Required');
    const [distanceCoveredError, setDistanceCoveredError] = useState('* Required initial');
    const [distanceCovered, setDistanceCovered] = useState(0);
    const [shipperStatusError, setShipperStatusError] = useState('* Required');
    const [shipperStatus, setShipperStatus] = useState('');


    const handleShipperStatusChange = (event) => {
        setShipperStatus(event.target.value);
        validateAllFields();
    };
    const handleChange = async value => {
        setSelectedCountry(value);
        await validateAllFields();
        if (value) {
            setCountrySelectError('');
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            setSelectedCountry(null);
            promiseOptions().then((countries) => {
                setCountries(countries);
                setOptions(countries);
            })
        }
    };

    async function validateAllFields() {
        const shipperNameInput = document.getElementById('shipperName');
        const shipperNameInputValue = shipperNameInput.value;
        const shipperNameValid = shipperNameInputValue && shipperNameInputValue ?.length > 0;

        if (shipperNameValid === true) {
            setShipperNameError('');
        } else {
            setShipperNameError('* Required');
        }

        const distanceCoveredInput = document.getElementById('distanceCovered');
        const distanceCoveredInputValue = distanceCoveredInput.value;
        const distanceCoveredValid = distanceCoveredInputValue && distanceCoveredInputValue ?.length > 0;

        if (distanceCoveredValid === true) {
            setDistanceCoveredError('');
        } else {
            setDistanceCoveredError('* Required');
        }


        const shipperStatusSelect = document.getElementById('shipperStatus');
        const shipperStatusValue = shipperStatusSelect.value;
        const shipperStatusValid = shipperStatusValue !== '' && shipperStatusValue !== '--';

        if (shipperStatusValid) {
            setShipperStatusError('');
        } else {
            setShipperStatusError('* Required');
        }

        const allFieldsValid = shipperStatusValid && distanceCoveredValid && shipperNameValid;

        setAllFieldsValid(allFieldsValid);

        return allFieldsValid;

    }

    async function clearForm(event) {
        event.preventDefault();
        setDisplayShipperForm(false);
        setDisplayTable(true);
    }

    const handleInputChange = async value => {
        await validateAllFields();
    };


    const promiseOptions = (inputValue) => new Promise((resolve, reject) => {

        if (initialized)
            try {

                if (inputValue) {
                    setLoading(true);
                    const search_url = `${search_country_url}${inputValue}`;
                    axios.get(search_url).then(result => {
                        const res = result.data;
                        setLoading(false);
                        setOptions(res);
                        resolve(res);
                    }).then((resss) => {

                        if (resss) {
                            console.log(resss);
                            resolve(resss);
                        } else {
                            //console.log('resss->', resss);
                        }
                    });

                } else {
                    setLoading(true);
                    axios.get(country_url).then(result => {
                        const countries__ = result.data;
                        setLoading(false);
                        setOptions(countries__);
                        setCountries(countries__);
                        resolve(countries__);
                    });

                }
            } catch (e) {
                console.error(e);
                reject(e);
            }

    });


    function hideSaveStatus() {
        const saveStatusSpan = document.getElementById('saveStatusSpan');
        saveStatusSpan.style.display = 'none';
        setDisplayShipperForm(false);
        setDisplayTable(true);
    }


    async function post(event) {

        event.preventDefault();
        const allFieldsValid = validateAllFields();

        if (allFieldsValid) {

            const data = {
                name: shipperName,
                countryId: selectedCountry.id,
                distanceCovered: distanceCovered,
                status: shipperStatus
            }

            setLoading(true);
            axios.post(shipper_post_url, data).then((response) => {
                setLoading(false);
                if (response.status >= 200 && response.status <= 210) {
                    const saveStatusSpan = document.getElementById('saveStatusSpan');
                    saveStatusSpan.style.display = 'flex';
                    setTimeout(hideSaveStatus, 2000);
                }
            });


        }
    }


    return (
        <div className="shipperForm">

            <div className="shipperFormHeader">Shipper</div>
            <div className="formFieldInput">
                <label className="formLabels" htmlFor="address">Shipper Name</label>
                <input id="shipperName" className="formInputs" onChange={async (e) => { setShipperName(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {shipperNameError && <span className="formError" id="shipperNameError">{shipperNameError}</span>}
            </div>
            <div className="formFieldInput">
                <label className="formLabels" htmlFor="countrySelect">Country</label>
                <AsyncSelect
                    loading={loading}
                    id="countrySelect"
                    cacheOptions
                    defaultOptions
                    placeholder='Start typing country name'
                    value={selectedCountry}
                    options={options}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    loadOptions={promiseOptions}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown} />
                {countrySelectError && <span className="formError" id="countrySelectError">{countrySelectError}</span>}
            </div>
            <div className="formFieldInput">
                <label className="formLabels" htmlFor="distanceCovered">Distance Covered (Kms)</label>
                <input value={distanceCovered} id="distanceCovered" type="number" className="formInputs" onChange={async (e) => { setDistanceCovered(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {distanceCoveredError && <span className="formError" id="distanceCoveredError">{distanceCoveredError}</span>}
            </div>
            <div className="formFieldInput">
                <label className="formLabels" htmlFor="shipperStatus">Status</label>
                <select value={shipperStatus} className="formInputs" id="shipperStatus" onChange={handleShipperStatusChange} >
                    <option key="0" value="--" defaultValue>--Select--</option>
                    <option key="1" value="Available">Available</option>
                    <option key="2" value="Unavailable">Unavailable</option>
                </select>
                {shipperStatusError && <span className="formError" id="shipperStatusError">{shipperStatusError}</span>}
            </div>


            <div className="formFieldInput btns">
                <button className="cancelBtn" onClick={(e) => { return clearForm(e) }}>Cancel</button>
                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                <span id="saveStatusSpan" className="saveStatusSpan">Saved.</span>
                <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
            </div>

        </div>
    );

}