import React, {useState, useEffect} from 'react';
import './business-names.css';
import useAxios from '../../../../../../hooks/axios';
import ResponsiveTable from '../../../../../../common/responsive-table/responsive-table';


const get_business_names = `${process.env.API_BASE_URL}/business-name`;

export default function BusinessNames(props){

    const headers = ['No.','Name','Status'];
    const registrationCode = props.registrationCode;
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const [search, setSearch] = useState();
    const setBusinessNamesCount = props.setBusinessNamesCount;

    const { axios, initialized, token } = useAxios.useAxios({});

    useEffect(()=>{
        if(axios && initialized){
            getBusinessNames();
        }
    },[axios, initialized]);

    function getBusinessNames(){
        try{

            if(axios){
                axios.get(`${get_business_names}/${registrationCode}`).then((response)=>{
                    const total = 1;
                    const pages = 1;
                    const page = 1;
                    setPages(pages);
                    setTotal(total);

                    if(response.data){
                        const data_ = [];
                        setBusinessNamesCount(response.data.length);
                        
                        response.data.forEach((data, idx)=>{
                            data_.push([
                                <div><b>{idx+1}</b>.</div>,
                                <div className="biznameTableListing" key={data.id}>
                                    <div><span className="bizNameSpanInTbl">{data.name}</span></div>
                                    <div>&nbsp;&nbsp;&nbsp;<i className="fa fa-edit clickable"></i>&nbsp;&nbsp;&nbsp;<i className="fa fa-trash clickable"></i> </div>
                                </div> ,
                                <div>{data.approved? 'Approved':'Not Approved'}</div>
                            ]);
                        });
                        setData(data_);
                    }
                    
                });
            }

        }catch(Error){
            console.error(Error);
        }
    }

    return(
        <>
            <h1 className="dasbhoard-form-header">Business Names</h1>
            <ResponsiveTable dataFetchFn={getBusinessNames} headers={headers} data={data} pagination={{ setPage: setPage, total: total, pages: pages, page: page }} ></ResponsiveTable>
        </>
    )
}