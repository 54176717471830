import React from 'react';
import './city-select.module.css'
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { useEffect, useState } from 'react';

export default function CitySelect(props){
    const selectedCountry = props.country;
    const token = props.token;
    const setCitySelectError = props.setCitySelectError;
    const validateAllFields = props.validateAllFields;
    const selectedCity = props.selectedCity;
    const setSelectedCity = props.setSelectedCity;
    
    const town_url = `${process.env.API_BASE_URL}/v1/city/by-country-id/`;
    const [towns, setTowns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [typedTownSearchTerm, setTtypedTownSearchTerm] = useState();
    

    async function handleTownInputChange(value){
        setTtypedTownSearchTerm(value);
    }

    async function handleTownChange(value){
        await setSelectedCity(value);
        if(value){
            await setCitySelectError('');
            await validateAllFields();
        }else{
            await validateAllFields();
            await setCitySelectError('* Required');
        }
        await validateAllFields();
    }


    async function getTowns(){

        const axios_ = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization:  `Bearer ${token}` 
            }
        });
        
        const country_id = selectedCountry?.id;
        
        if (country_id) {
            setLoading(true);
            await axios_.get(`${town_url}${country_id}`).then(result => {
                const towns = result.data;
                setTowns(towns);
                //console.log('Towns', towns);
                setLoading(false);
            });
        }
        return towns;

    }

    const promiseOptions = (inputValue) => /*new Promise((resolve, reject) => {}) ;*/
        new Promise((resolve, reject) => {
            const axios_ = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization:  `Bearer ${token}` 
                }
            });
            
            const country_id = selectedCountry?.id;
            let fetchUrl = typedTownSearchTerm ?  `${town_url}${country_id}/${typedTownSearchTerm}`  : `${town_url}${country_id}`;
            //console.log(' fetchUrl -> ', fetchUrl);
            
            if (country_id) {
                setLoading(true);
                axios_.get(  fetchUrl )
                .then(result => {
                    const towns = result.data;
                    setTowns(towns);
                    //console.log('Towns', towns);
                    setLoading(false);
                    resolve(towns);
                });
            }else{
                reject([]);
            }

            //resolve(towns);
    });


    return(
        <div>
        <AsyncSelect
                    id="citySelect"
                    isClearable
                    isSearchable
                    isLoading={loading}
                    key={selectedCountry?.name}
                    placeholder='Select City'
                    defaultOptions
                    value={selectedCity}
                    /*options={towns}*/
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    loadOptions={promiseOptions}
                    onInputChange={handleTownInputChange}
                    onChange={handleTownChange} />
        </div>

    )
}