import React,{useState, useEffect} from 'react';
import './financing-form.css';
import useAxios from '../../../../../hooks/axios';
import ReactLoading from "react-loading";

const financing_form_post_url = `${process.env.API_BASE_URL}/financing`;
const generate_loan_code_url = `${process.env.API_BASE_URL}/financing/code`;


export default function FinancingForm(props){
    const setDisplayForm = props.setDisplayForm;
    const setDisplayTable = props.setDisplayTable;
    const loadData = props.loadData;
    const loanPurpose = props.loanPurpose;
    const formTitle = props.formTitle;
    const user = props.user;

    const [loanNoError, setLoanNoError] = useState('* Required');
    const [loanNo, setLoanNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [allFieldsValid, setAllFieldsValid] = useState();
    const { axios, initialized, token } = useAxios.useAxios({});
    const [purposeError, setPurposeError] = useState('* Required');
    const [purpose, setPurpose] = useState(loanPurpose);
    const [balance, setBalance] = useState(0.00);
    const [balanceError, setBalanceError] = useState('* Required');
    const [currency, setCurrency] = useState('USD');
    const [currencyError, setCurrencyError] = useState('* Required');
    const [amountIssued, setAmountIssued] = useState(0.00);
    const [amountIssuedError, setAmountIssuedError] = useState('* Required');
    const [amountRequested, setAmountRequested] = useState(0.00);
    const [amountRequestedError, setAmountRequestedError] = useState('* Required');
    const [amountUsed, setAmountUsed] = useState(0.00);
    const [amountUsedError, setAmountUsedError] = useState('* Required');
    
    

    useEffect(() => {
        if(axios){
            generateCode();
        }
        validateAllFields();
    }, [axios]);


    async function generateCode(){
       axios.get(`${generate_loan_code_url}?purpose=${loanPurpose}`)
       .then((response)=>{
           setLoanNo(  response.data );
           document.getElementById('loanNo').value = response.data;
           validateAllFields();
       });
    }

    const handlePurposeChange = (event) => {
        setPurpose(event.target.value);
        validateAllFields();
    };


    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
        validateAllFields();
    }

    async function validateAllFields() {
        
        const loanNoInput = document.getElementById('loanNo');
        const loanNoValue = loanNoInput.value;
        const loanNoValid = loanNoValue && loanNoValue!='';
        
        if(loanNoValid){
            setLoanNoError('');
        }else{
            setLoanNoError('* Required');
        }

        const purposeSelect = document.getElementById('purpose');
        const purposeValue = purposeSelect.value;
        const purposeValid = purposeValue && purposeValue!='' && purposeValue!='--';
        
        if(purposeValid){
            setPurposeError('');
        }else{
            setPurposeError('* Required');
        }


        const amountIssuedInput = document.getElementById('amountIssued');
        const amountIssuedValue = amountIssuedInput.value;
        const amountIssuedValid = amountIssuedValue && amountIssuedValue!='';
        
        if(amountIssuedValid){
            setAmountIssuedError('');
        }else{
            setAmountIssuedError('* Required');
        }


        const amountUsedInput = document.getElementById('amountUsed');
        const amountUsedValue = amountUsedInput.value;
        const amountUsedValid = amountUsedValue && amountUsedValue!='';
        
        if(amountUsedValid){
            setAmountUsedError('');
        }else{
            setAmountUsedError('* Required');
        }

        const balanceInput = document.getElementById('balance');
        const balanceValue = balanceInput.value;
        const balanceValid = balanceValue && balanceValue!='';
        
        if(balanceValid){
            setBalanceError('');
        }else{
            setBalanceError('* Required');
        }


        const currencyInput = document.getElementById('currency');
        const currencyValue = currencyInput.value;
        const currencyValid = currencyValue && currencyValue!='' && currencyValue!='--';
        
        if(currencyValid){
            setCurrencyError('');
        }else{
            setCurrencyError('* Required');
        }

        

        const amountRequestedInput = document.getElementById('amountRequested');
        const amountRequestedValue = amountRequestedInput.value;
        const amountRequestedValid = amountRequestedValue && amountRequestedValue!='' && amountRequestedValue>0;
        
        if(amountRequestedValid){
            setAmountRequestedError('');
        }else{
            setAmountRequestedError('* Required, must be greater than 0');
        }

        const allFieldsValid = loanNoValid && loanNoValid && amountIssuedValid && amountUsedValid && purposeValid && balanceValid && currencyValid && amountRequestedValid;
        
        setAllFieldsValid(allFieldsValid);
        
        return allFieldsValid;

    }

    function hideSaveStatus() {
        setDisplayForm(false);
        setDisplayTable(true);
        const saveStatusSpan = document.getElementById('saveStatusSpan');
        saveStatusSpan.style.display = 'none';
    }


    async function clearForm(event) {
        event.preventDefault();
        setDisplayForm(false);
        setDisplayTable(true);
    }
    
    async function post(event) {

        event.preventDefault();
        const allFieldsValid = validateAllFields();

        if (allFieldsValid) {

            const data = {
                userId: user.id,
                currency: currency,
                amountRequested: amountRequested,
                amountIssued: amountIssued,
                amountUsed: amountUsed,
                balance: balance,
                purpose: purpose,
                balance: balance,
                status: 'Requested',
                loanNo: loanNo
            }

            setLoading(true);
            axios.post(financing_form_post_url, data).then((response) => {
                setLoading(false);
                if (response.status >= 200 && response.status <= 210) {
                    const saveStatusSpan = document.getElementById('saveStatusSpan');
                    saveStatusSpan.style.display = 'flex';
                    setTimeout(hideSaveStatus, 500);
                    loadData();
                }
            });


        }
    }


    return (
        <div className="shipperForm">

            <div className="shipperFormHeader">{formTitle}</div>
            
            <div className="formFieldInput">
                <label className="formLabels" htmlFor="loanNo">Loan No.</label>
                <input disabled id="loanNo" type="text" className="formInputs" onChange={async (e) => { setLoanNo(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {loanNoError && <span className="formError" id="loanNoError">{loanNoError}</span>}
            </div>


            <div className="formFieldInput hiddenFieldInput.">
                <label className="formLabels" htmlFor="purpose">Purpose</label>
                <select disabled value={purpose} className="formInputs" id="purpose" onChange={handlePurposeChange} >
                    <option key="0" value="--" >--Select--</option>
                    <option key="1" value="Import">Import</option>
                    <option key="2" value="Export">Export</option>
                    <option key="3" value="Working Capital">Working Capital</option>
                </select>
                {purposeError && <span className="formError" id="purposeError">{purposeError}</span>}
            </div>

            

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="amountRequested">Request Amount</label>
                <input value={amountRequested} id="amountRequested" type="number" className="formInputs" onChange={async (e) => { setAmountRequested(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {amountRequestedError && <span className="formError" id="amountIssuedError">{amountRequestedError}</span>}
            </div>

            <div className="formFieldInput hiddenFieldInput">
                <label className="formLabels" htmlFor="amountIssued">Amount Issued</label>
                <input value={amountIssued} id="amountIssued" type="number" className="formInputs" onChange={async (e) => { setAmountIssued(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {amountIssuedError && <span className="formError" id="amountIssuedError">{amountIssuedError}</span>}
            </div>


            <div className="formFieldInput hiddenFieldInput">
                <label className="formLabels" htmlFor="amountUsed">Amount Used</label>
                <input value={amountUsed} id="amountUsed" type="number" className="formInputs" onChange={async (e) => { setAmountUsed(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {amountUsedError && <span className="formError" id="amountUsedError">{amountUsedError}</span>}
            </div>


            <div className="formFieldInput hiddenFieldInput">
                <label className="formLabels" htmlFor="balance">Balance</label>
                <input disabled value={amountIssued - amountUsed} id="balance" type="number" className="formInputs" onChange={async (e) => { setBalance(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {balanceError && <span className="formError" id="balanceError">{balanceError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="currency">Currency</label>
                <select value={currency} className="formInputs" id="currency" onChange={handleCurrencyChange} >
                    <option key="0" value="--" >--Select--</option>
                    <option key="1" value="USD" defaultValue>United States Dollar (USD)</option>
                    <option key="2" value="KES">Kenya Shillings (KES)</option>
                    <option key="3" value="NGN">Nigerian Naira (NGN)</option>
                    <option key="4" value="UGX">Ugandan Shilling (UGX)</option>
                    <option key="5" value="TZS">Tanzanian Shilling (TZS)</option>
                    <option key="6" value="BTC">Bitcoin (BTC)</option>
                </select>
                {currencyError && <span className="formError" id="currencyError">{currencyError}</span>}
            </div>
            <div className="formFieldInput btns">
                <button className="cancelBtn" onClick={(e) => { return clearForm(e) }}>Cancel</button>
                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                <span id="saveStatusSpan" className="saveStatusSpan">Saved.</span>
                <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
            </div>

        </div>
    );
}