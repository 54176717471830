import React from 'react';
import {useAuth} from '../hooks/auth-hook';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {

  const registrationData = useSelector((state) => state.registrationData.value);
  var realmId = registrationData.realmId;
  
 // console.log(`::::: realmId --> ${realmId}`);

  const auth = useAuth();
  //keycloak.realm = realmId;
  //auth.meta.keycloak.realm = realmId;

  /*
  if (!auth?.initialized) {
    return <div>Loading...</div>;
  }
  if (!auth?.isAuthenticated) {
    auth.login();
  }*/

  return children;
};

export default PrivateRoute;
