import React, { useState, useEffect } from 'react';
import './share-capital.css';
import ShareCapitalForm from './share-capital-form/share-capital-form';
import ResponsiveTable from '../../../../../../common/responsive-table/responsive-table';
import useAxios from '../../../../../../hooks/axios';
import ReactLoading from "react-loading";

const get_share_capital_url = `${process.env.API_BASE_URL}/share-capital`;

export default function ShareCapital(props) {

    const { axios, initialized, token } = useAxios.useAxios({});

    const showShareCapitalForm = props.showShareCapitalForm;
    const setShowShareCapitalForm = props.setShowShareCapitalForm;
    const registrationCode = props.registrationCode;
    const showShareCapitalTable = props.showShareCapitalTable;
    const setShowShareCapitalTable = props.setShowShareCapitalTable;
    const setShareCapitalCount = props.setShareCapitalCount;
    const setShowShareCapitalDash = props.setShowShareCapitalDash;
    const showShareCapitalDash = props.showShareCapitalDash;
    const shareCapitalCount = props.shareCapitalCount;
    
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const [search, setSearch] = useState();
    const [showAddShareCapital, setShowAddShareCapital] = useState(true);
    const [displaySearch, setDisplaySearch] = useState(false);
    const headers = ['Share Cap Value', 'Has Foreigners', 'Company Structure', 'Structure description',''];

    useEffect(() => {
        if (initialized) {
            loadData();
        }
    }, [axios, initialized]);


    async function loadData(page_, limit_, search_) {
        try {

            if (axios) {
                setLoading(true);
                await axios.get(`${get_share_capital_url}/${registrationCode}?page=${page_ || page || ''}&limit=${limit_ || limit}&search=${search_ || search || ''}`)
                    .then((response) => {
                        
                        setLoading(false);
                        const resp = response.data;
                        
                        const data_ = [];
                        const total = resp.total;
                        const pages = resp.pages;
                        const page = resp.page;
                        setPages(pages);
                        setTotal(total);
                        setShareCapitalCount(total);
                        resp ?.shareCapital ?.forEach((shareCapital) => {
                            
                            data_.push([
                                shareCapital.shareCapitalValue,
                                (shareCapital.hasForeignerAsShareHolder?'Yes':'No'),
                                shareCapital.companyStructure,
                                shareCapital.companyStructureDescription,
                                (<div><span className="fa fa-edit"></span><span className="fa fa-trash"></span></div>)
                            ])

                        });
                        setData(data_);
                    });
            }

        } catch (Error) {
            console.log(Error);

        }
    }

    function add() {
        setShowShareCapitalTable(false);
        setDisplaySearch(false);
        setShowShareCapitalForm(true);
        setShowAddShareCapital(false);

    }

    async function handleSearch(event) {
        const page_ = 0;
        const limit_ = 5;
        const search_ = event.target.value;
        setLimit(limit_);
        setPage(page_);
        setSearch(search_);
        loadData(page_, limit_, search_);
    }

    return (
        <>
            {shareCapitalCount<1 && <div className='searchStrip'>

                <div className='newAddress' onClick={add}>
                    {showAddShareCapital && <>
                        <span onClick={add} className="fa fa-user-plus" />
                        <span onClick={add}> &nbsp;Add Share Capital</span></>
                    }
                </div>

                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}

                {displaySearch &&
                    <div className='searchDiv'>
                        <span className='searchSpan'>
                            <label htmlFor="search">
                                <input id="search" type="text" onKeyUp={(e) => { setSearch(e.target.value); loadData(); }} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                            </label>
                        </span>
                    </div>}

            </div>}
            {showShareCapitalTable && <ResponsiveTable dataFetchFn={loadData} headers={headers} data={data} pagination={{ setPage: setPage, total: total, pages: pages, page: page }}></ResponsiveTable>}
            {showShareCapitalForm && <ShareCapitalForm setShowAddShareCapital={setShowAddShareCapital} setShowShareCapitalTable={setShowShareCapitalTable} setShowShareCapitalForm={setShowShareCapitalForm} registrationCode={registrationCode} setShowShareCapitalForm={setShowShareCapitalForm}></ShareCapitalForm>}
        </>
    )
}