import React from 'react';
import { useState } from 'react'
import './1-dashboard.css'
import InternationalAddress from './1-international-address/international-address'
import CrobodShippers from './2-crobod-shippers/crobod-shippers'
import LocalDeliveryPartners from './3-local-delivery-partners/local-delivery-partners'
import VirtualWallet from './4-virtual-wallet/virtual-wallet'

export default function DashboardModule(props) {

    const [submodule, setSubmodule] = useState({id: 'international-addres', name: 'International Address'});

    function menuNavigate(event, submodule){
        event.preventDefault();
        setSubmodule(submodule.id);
        props.setSubmoduleName(submodule.name);
    }


    return (

        <div>
            <div className='appCardContainer'>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'international-address', name:'International Address'}) }}  className='appCardText'>
                        International address
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'crobod-shippers', name:'Crobod Shippers'}) }}   className='appCardText'>
                        Crobod shippers
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'local-delivery-partners', name:'Local Delivery Partners'}) }}  className='appCardText'>
                        Local delivery Partners
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'virtual-wallet', name:'Virtual Wallet'}) }} className='appCardText'>
                        Virtual Wallet
                    </div>
                </div>
            </div>

            {submodule==='international-address' && <InternationalAddress ></InternationalAddress>}
            {submodule==='crobod-shippers' && <CrobodShippers ></CrobodShippers>}
            {submodule==='local-delivery-partners' && <LocalDeliveryPartners></LocalDeliveryPartners>}
            {submodule==='virtual-wallet' && <VirtualWallet></VirtualWallet>}

        </div>
    )
}