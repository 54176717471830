import React, { useRef, useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import './login.css';
import './login-min-width-992px.css';
import './login-max-width-600px.css';
import './login-min-width-600px.css';
import './login-min-width-768px.css';
import useAxios from '../../hooks/axios';
import { useAuth } from '../../hooks/auth-hook';
import { useKeycloak } from '@react-keycloak/web';
import {
    setBusinessName, setUserName, setPassword, setConfirmPassword,
    setAimUserId, setRealmId, setRealmName, setWebClientId, setApiClientId,
    setApiClientSecret, setSynchronized, setEmailAddress, setTenantId, setTenantName,
    setCurrentRealm, setKeycloaks, setCurrentKeycloak, setLoginBusinessName, setLoginEmailAddress, clear
} from '../../util/redux/registrationDataSlice';
import { useSelector, useDispatch } from 'react-redux';
import OtherBusiness from './otherBusinessses/OtherBusinesses';
import ReactLoading from "react-loading";
import { RxReload } from "react-icons/rx";



const accountLookupUrl = `${process.env.API_BASE_URL}/account/lookup`;

export default function Login(props) {

    const [statusSpan, setStatusSpan] = useState();
    const { axios, token } = useAxios.useAxios({});
    const auth = useAuth();
    const { keycloak, initialized } = useKeycloak(false);
    const switchKeycloakContext = props.switchKeycloakContext;
    const [showOtherBusinesses, setShowOtherBusinesses] = useState(false);
    const [otherBusinesses, setOtherBusinesses] = useState(false);
    const [businessNameError, setBusinessNameError] = useState('Required');
    const [emailAddressError, setEmailAddressError] = useState('Required');
    const [loginBtnDisabled, setLoginBtnDisabled] = useState(true);
    const [emailAddressIsValid, setEmailAddressIsValid] = useState(false);
    const [businessNameisValid, setBusinessNameisValid] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [showLoginButton, setShowLoginButton] = useState(true);
    const [countDownSeconds, setCountDownSeconds] = useState(0);
    const [otpNounce, setOtpNounce] = useState();
    const [otpBtnDisabled, setOtpBtnDisabled] = useState(false);
    const [otp, setOtp] = useState();
    const [countDownInterval, setCountDownInterval] = useState();
    const [validatedViaOtp, setValidatedViaOtp] = useState(false);


    const registrationData = useSelector((state) => state.registrationData.value);
    const dispatch = useDispatch();

    

    var emailAddress = registrationData.loginEmailAddress;
    var businessName = registrationData.loginBusinessName;

    const businessNameInputRef = useRef(null);

    useEffect(() => {

        if (auth.isAuthenticated) {
            window.location = '/crobod/dashboard';
        }

        businessNameInputRef.current.focus();

        validate('email-address');
        validate('business-name');

    }, [initialized]);

    function componentDidMount() {
        console.log('comp did mount');
        businessNameInputRef.current.focus();
    }

    async function affirm(realmId, action) {
        if (action === 'login') {
            sessionStorage['realmId'] = realmId;
            keycloak.realm = realmId;
            switchKeycloakContext(realmId, 'login-required');
            const loginOpts = {
                scope: 'openid',
                redirectUri: `${process.env.BASE_URL}/dashboard`,
                prompt: 'login',
                action: 'tologin',
                maxAge: '100000',
                loginHint: emailAddress,
                idpHint: 'idpHint',
                cordovaOptions: null
            };
            keycloak.login(loginOpts);
        } else {
            closePopup();
        }
    }

    function didNotMeanThatOne(allSimilarTenants, theOneIdidNotMean) {
        setShowOtherBusinesses(true);
        setOtherBusinesses(allSimilarTenants);

    }

    function closePopup() {
        setShowOtherBusinesses(false);
        setShowLoginButton(true);
    }

    function resetForm() {

        document.getElementById("statusSpan").innerHTML = '';
        setEmailAddress('');
        setBusinessName('');
        setLoginBtnDisabled(true);

        dispatch(setLoginBusinessName(''));
        //dispatch(setLoginEmailAddress(''));
        dispatch(setTenantId(-1));
        dispatch(setRealmId('crobod-dev'));
        sessionStorage['realmId'] = process.env.CORE_KC_REALM_NAME || 'crobod-dev';
        keycloak.realm = process.env.CORE_KC_REALM_NAME || 'crobod-dev';
        switchKeycloakContext((process.env.CORE_KC_REALM_NAME || 'crobod-dev'), 'check-sso');
        businessNameInputRef.current.focus();

    }


    async function searchAccountsByEmailAddress(event) {

        event.preventDefault();

        //emailAddress = registrationData.loginEmailAddress;
        businessName = registrationData.loginBusinessName;

        const searchParams = {
            emailAddress: emailAddress,
            businessName: businessName,
            password: ''//In future maybe we can handle passwords/passcodes/PIN
        };

        const accountQuery = DOMPurify.sanitize(JSON.stringify(searchParams));

        if (businessName) {
            
            setFetchingData(true);
            setShowLoginButton(false);
            document.getElementById('statusSpan').innerHTML = '';


            await axios.post(`${accountLookupUrl}`, accountQuery)
                .then((response) => {

                    setFetchingData(false);

                    const respData_ = DOMPurify.sanitize(JSON.stringify(response.data));
                    const respData = JSON.parse(respData_);
                    console.log(respData);

                    if (respData.atLeastOneIsAuthenticated==true) {//At least one of the accounts should have authenticated you
                        
                        const authenticatedTenant = respData.authenticatedTenants.length > 0 ? respData.authenticatedTenants[0] : undefined;

                        if (authenticatedTenant) {

                            dispatch(setRealmId(authenticatedTenant.realmId));
                            dispatch(setTenantId(authenticatedTenant.id));

                            keycloak.realm = authenticatedTenant.realmId;

                            switchKeycloakContext(authenticatedTenant.realmId, 'login-required');
                            sessionStorage['realmId'] = authenticatedTenant.realmId;

                            if (authenticatedTenant.businessName === registrationData.loginBusinessName && respData.accounts.length === 1) {
                                setStatusSpan(`Logging in...`);
                                const loginOpts = {
                                    scope: 'openid',
                                    redirectUri: `${process.env.BASE_URL}/dashboard`,
                                    prompt: 'login',
                                    action: 'tologin',
                                    maxAge: '100000',
                                    loginHint: emailAddress,
                                    idpHint: 'idpHint',
                                    cordovaOptions: null
                                };
                                keycloak.login(loginOpts);
                                document.getElementById('statusSpan').innerHTML = '';
                                document.getElementById('otherBusinesses').innerHTML = '';
                            } else {
                                const statusSpanEl = document.getElementById('statusSpan');
                                statusSpanEl.innerHTML = '';

                                document.getElementById('otherBusinesses').innerHTML = '';

                                let span = document.createElement('span');
                                span.innerHTML = 'Did you mean ';
                                let anchorLink = document.createElement('a');
                                anchorLink.className = 'bizLink';
                                anchorLink.addEventListener('click', () => { affirm(authenticatedTenant.realmId, 'login'); });
                                

                                const search_term = businessName.toLowerCase();
                                const start_ = authenticatedTenant.businessName.toLowerCase().indexOf(search_term.toLowerCase());
                                const end_ = start_ + search_term.length;
                                const len = search_term.length;
                                const to_end = authenticatedTenant.businessName.toLowerCase().length;

                                const searchTermReplace = authenticatedTenant.businessName.substring(start_, end_);

                                const finalStr = `${authenticatedTenant.businessName.substring(0, start_)}`;
                                
                                // {tenant.businessName.substring(0, start_)}<span key={`${tenant.id}_s`} className='searchTermSpan'>{searchTermReplace}</span>{tenant.businessName.substring(start_ + len, to_end)}
                                
                                
                                anchorLink.innerHTML = finalStr;//authenticatedTenant.businessName;
                                
                                const highlightedEl = document.createElement('span');
                                highlightedEl.className = 'searchTermSpan';
                                highlightedEl.innerHTML = searchTermReplace;
                                anchorLink.appendChild(highlightedEl);
                                anchorLink.appendChild(  document.createTextNode(authenticatedTenant.businessName.substring(start_ + len, to_end)));
                                span.appendChild(anchorLink);
                                

                                
                                span.appendChild(document.createTextNode(" ? "));


                                let yesAnchorLink = document.createElement('a');
                                yesAnchorLink.addEventListener('click', () => { affirm(authenticatedTenant.realmId, 'login'); });
                                yesAnchorLink.innerHTML = 'Yes';
                                yesAnchorLink.className = 'loginYesLink';
                                span.appendChild(yesAnchorLink);

                                span.appendChild(document.createTextNode(" | "));

                                let noAnchorLink = document.createElement('a');
                                noAnchorLink.addEventListener('click', () => { didNotMeanThatOne(respData.accounts, authenticatedTenant.businessName); });
                                noAnchorLink.innerHTML = 'No';
                                noAnchorLink.className = 'loginNoLink';
                                span.appendChild(noAnchorLink);

                                span.id = 'linkSpan';
                                statusSpanEl.appendChild(span);
                            }
                        } else {
                            const statusSpanEl = document.getElementById('statusSpan');
                            statusSpanEl.innerHTML = 'Coudl not find any account';
                            if(businessName){
                                setBusinessNameError('This field is required')
                            }
                            if(emailAddress){
                                setEmailAddressError('This field is required');
                            }
                        }  
                    }else{
                        console.log(`respData.atLeastOneIsAuthenticated ${respData.atLeastOneIsAuthenticated}`);
                        const statusSpanEl = document.getElementById('statusSpan');
                        statusSpanEl.innerHTML = `<div id='searchResultsEmpty' class='searchResultsEmpty'> No account found with business name <span class='bizLinkUnlink'>${businessName}</span> and email address <b class='searchedEmailAddress'>${emailAddress}</b>&nbsp;</div>`;

                        const retryBtn = document.createElement('a');
                        retryBtn.addEventListener('click', () => { displayLoginButton(); });
                        retryBtn.className = 'searchRetryBtn';

                        const rxReload = document.getElementById('rxReload');
                        rxReload.style.display = 'block';
                        const htmlCopyofIcon = `${rxReload.innerHTML} Search Again`;
                        retryBtn.innerHTML = htmlCopyofIcon; 
                        rxReload.style.display = 'none';
                        
                        document.getElementById('searchResultsEmpty').appendChild(retryBtn);

                        

                     }
                });
        } else {
            document.getElementById('statusSpan').innerHTML = 'Both business name and email address are required for this search';
        }
    }

    const displayLoginButton = () => {
        resetForm();
        setShowLoginButton(true);
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    function validate(fieldName){
        
        if(fieldName==='business-name'){
            const value = registrationData.loginBusinessName;
            if((value || value!=='')){
                setBusinessNameError('');
                setLoginBtnDisabled(false);
                setBusinessNameisValid(true);
            }else{
                setBusinessNameisValid(false);
                setBusinessNameError('Business name is required');
                setLoginBtnDisabled(true);
            }
        }

        if(fieldName==='email-address'){
            const value = registrationData.loginEmailAddress;
            if((value || value!=='')){
                setEmailAddressError('');

                if(!validateEmail(value)){
                    setEmailAddressError('Email is invalid');
                    setLoginBtnDisabled(true);
                    setEmailAddressIsValid(false);
                }else{
                    setEmailAddressIsValid(true);
                    setLoginBtnDisabled(false);
                }

            }else{
                setEmailAddressError('Email address is required');
                setLoginBtnDisabled(true);
            }
        }

    }

    let counter_ = 60;
    let countDownInterval_ = countDownInterval;

    function updateCounter() {
        try {
            counter_ = counter_ - 1;
            setCountDownSeconds(counter_);
            if (counter_ === 0) {
                clearInterval(countDownInterval_);
            }
        } catch (Error) {
            console.error(Error);
        }
    }

    async function validateOtp(otp) {

        setOtp(otp);

        if (otp.length >= 4) {
            try {
                const _emailAddress = registrationData.loginEmailAddress;
                const _phoneNumber = '--';
                const otpUrl = `${process.env.API_BASE_URL}/otp/validate/${_emailAddress}/${_phoneNumber}/${otpNounce}/${otp}`;
                console.log(`::: otpUrl -> ${otpUrl}`);
                await axios.get(otpUrl).then((response) => {
                    const otpValidateResponse = response.data;
                    const generalNotification = document.getElementById('generalNotification');
                    if (otpValidateResponse.exists === true) {
                        if (otpValidateResponse.valid === true) {
                            setShowLoginButton(true);
                            setValidatedViaOtp(true);
                            generalNotification.style.display = 'flex';
                            generalNotification.style.color = 'green';
                            generalNotification.innerHTML = 'OTP is valid. Click \'Login\' to login';
                            const fn_ = () => {
                                try {
                                    document.getElementById('generalNotification').innerHTML = '';
                                    document.getElementById('generalNotification').style.color = 'red';
                                    document.getElementById('generalNotification').style.display = 'none';
                                } catch (Error) {
                                    console.error(Error);
                                }
                            }
                            setTimeout(fn_, 5000);
                        } else {
                            if (otp.length >= 5)
                                generalNotification.innerHTML = 'OTP has already been used. Generate another';
                            //Exists, but invalid
                            setShowLoginButton(false);
                            setValidatedViaOtp(false);

                            generalNotification.style.display = 'flex';
                            generalNotification.style.color = 'red';
                            generalNotification.innerHTML = 'This OTP has already been used. Please generate another one.';

                        }
                    } else {
                        if (otp.length >= 5)
                            generalNotification.innerHTML = 'OTP invalid. Try again';
                        //doesn't exist at all
                        setGetStartedButtonIsDisabled(true);
                        setValidatedViaOtp(false);
                    }
                });
            } catch (Error) {
                console.log(Error);
            }
        }
    }

    async function generateOtp() {
        try {
            const payload = {
                phoneNumber: '--',
                emailAddress: registrationData.loginEmailAddress,
            }
            const allFieldsValid = registrationData.loginEmailAddress!=='';// && registrationData.loginEmailAddress!==undefined ;
            console.log(`\n otpBtnDisabled => ${otpBtnDisabled} \n allFieldsValid -> ${allFieldsValid} \n countDownSeconds -> ${countDownSeconds}`);
            if (otpBtnDisabled === false && allFieldsValid === true && countDownSeconds <= 0) {

                const otpUrl = `${process.env.API_BASE_URL}/otp/generate`;
                setFetchingData(true);
                await axios.post(otpUrl, payload)
                    .then((response) => {
                        setOtpNounce(response.data.otpNounce);
                        const interval_ = () => {
                            updateCounter();
                        }
                        countDownInterval_ = setInterval(interval_, 1000, countDownSeconds);
                        setCountDownInterval(countDownInterval_);
                        setCountDownSeconds(60);
                        const otpLink = document.getElementById('otpLink');
                        otpLink.className = 'send-otp-div-disabled';
                        otpLink.onclick = () => {};
                        otpLink.removeEventListener('click', generateOtp);
                        otpLink.removeEventListener('click', () => { console.log('Wait for countdown') });
                        setFetchingData(false);

                        const generalNotification = document.getElementById('generalNotification');
                        generalNotification.innerHTML = `An OTP has been sent to your email address ${registrationData.loginEmailAddress}. Please check your inbox. `;
                    });
            } else {
                // 'OTP requests Disabled. Wait timeout to expire'
            }
        } catch (Error) {
            console.log(Error);
        }
    }

    return (
        <div className="loginPage">

            <div className="formBody">

                <div className="LoginHeader">
                    Sign In
                </div>

                <div className="formDiv">
                    <div className="formField">
                        <input onKeyUp={(e)=>{ validate('business-name')}} ref={businessNameInputRef} className="formInput" type="text" className="formInput" value={businessName} onChange={(e) => { dispatch(setLoginBusinessName(e.target.value)) }} placeholder="Type business name" required></input>
                        <div className="error_" id="businessNameError">{businessNameError}</div>
                    </div>

                    <div className="formField">
                        <input onKeyUp={(e)=>{ validate('email-address')}}  className="formInput" type="email" className="formInput" value={emailAddress} onChange={(e) => { dispatch(setLoginEmailAddress(e.target.value)) }} placeholder="user@example.com" required></input>
                        <div className="error_" id="emailAddressError">{emailAddressError}</div>
                    </div>
                    <br />

                    <div className="statusDiv_">
                        <span id="statusSpan" className="statusSpan">{statusSpan && statusSpan}</span>
                    </div>
                    
                    <div id="loadingDiv" className="loadingDiv">
                        {fetchingData && 
                        <ReactLoading className="loaderElLogin" type='cylon' color="rgb(48 96 91)"  />
                        }
                    </div>


                    <div className="otpDiv">
                        {countDownSeconds>0 &&
                        <span className="sendAgainIn" id="sendAgainIn">Send Again in <b>{countDownSeconds}</b>s</span>
                        }
                        {countDownSeconds<=0 &&
                        <span onClick={generateOtp} className="send-otp-div" id="otpLink" >Send Email Otp</span>
                        }
                        <label className="otpLabel" htmlFor="otp">OTP</label>
                        <input onChange={(e) => {  setOtp(e.target.value) ; validateOtp(e.target.value); }}  className="otp-input" id="otp" type="text"></input>
                    </div>

                    {(showLoginButton && validatedViaOtp) &&
                        <div className="formField">
                            <div className="formButtonz">
                                <button className="formButtonStyle" onClick={(e) => { searchAccountsByEmailAddress(e) }} disabled={loginBtnDisabled}>Login</button>
                            </div>
                        </div>
                    }
                   
                </div>
                <span id="generalNotification" className="generalNotification"></span>
                <div id="otherBusinesses"> </div>
            </div>

            {showOtherBusinesses &&
                        <OtherBusiness resetForm={resetForm} closePopup={closePopup} emailAddress={registrationData.loginEmailAddress} affirm={affirm} loginBusinessName={registrationData.loginBusinessName} otherBusinesses={otherBusinesses} />}
                   
                   <div id="rxReload" style={{display:'none'}}><RxReload /></div>
        </div>
    );
}