import React, { useState, useEffect } from 'react';
import './director-form.css';
//import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import ReactLoading from "react-loading";
import AsyncSelect from 'react-select/async';
import { useSelector, useDispatch } from 'react-redux';
import useAxios from '../../../../../../../hooks/axios';
import { useAuth } from '../../../../../../../hooks/auth-hook';


import { setTitle, setDesignationTitle, setFirstName, 
    setMiddleName, setLastName, setGender, setEmailAddress, 
    setPhoneNumber, setDateOfBirth, setMeansOfIdentification, 
    setIdentificationNumber, setRegistrationCode, setSignatureUrl, 
    setAllotedShares, setUserId, setNationality, setShareClass, setIdentificationDocumentUrl, clear } from '../../../../../../../redux/reducers/directorFormStateReducer';

const country_url = `${process.env.API_BASE_URL}/v1/country`;
const search_country_url = `${process.env.API_BASE_URL}/v1/country/search/`;
const save_director_url = `${process.env.API_BASE_URL}/director`;
const get_director_url = `${process.env.API_BASE_URL}/director`;

const save_director_to_session_url = `${process.env.API_BASE_URL}/director/session`;
const get_director_from_session_url = `${process.env.API_BASE_URL}/director/session`;



export default function DirectorForm(props) {

    const directorFormStateReducer = useSelector((state) => state.directorFormStateReducer.value);
    const dispatch = useDispatch();

    const { user, roles } = useAuth();
    const { axios, initialized, token } = useAxios.useAxios({});


    var firstName = directorFormStateReducer.firstName;
    var middleName = directorFormStateReducer.middleName;
    var lastName = directorFormStateReducer.lastName;
    var designationTitle = directorFormStateReducer.designationTitle;
    var dateOfBirth = directorFormStateReducer.dateOfBirth;
    var gender = directorFormStateReducer.gender;
    var emailAddress = directorFormStateReducer.emailAddress;
    var phoneNumber = directorFormStateReducer.phoneNumber;
    var nationality = directorFormStateReducer.nationality;
    var meansOfIdentification = directorFormStateReducer.meansOfIdentification;
    var identificationNumber = directorFormStateReducer.identificationNumber;
    var signatureUrl = directorFormStateReducer.signatureUrl;
    var shareClass = directorFormStateReducer.shareClass;
    var allotedShares = directorFormStateReducer.allotedShares;
    var identificationDocumentUrl = directorFormStateReducer.identificationDocumentUrl;
    var title = directorFormStateReducer.title;

    const setShowDirectorForm = props.setShowDirectorForm;
    const registrationCode = props.registrationCode;
    const setShowRegisterDirector = props.setShowRegisterDirector;
    const setDisplaySearch = props.setDisplaySearch;
    const setShowDirectorTable = props.setShowDirectorTable;
    const showDirectorTable = props.showDirectorTable;
    const loadData = props.loadData;

    
    const [loading, setLoading] = useState(false);
    const [allFieldsValid, setAllFieldsValid] = useState(false);
    const [inputValue, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const [countries, setCountries] = useState([{ value: '-1', label: 'Select Countries' }]);
    const [countrySelectError, setCountrySelectError] = useState('* Required');
    const [firstNameError, setFirstNameError] = useState('* Required');
    const [middleNameError, setMiddleNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('* Required');
    const [dateOfBirthError, setDateOfBirthError] = useState('* Required');
    const [genderError, setGenderError] = useState('* Required');
    const [emailAddressError, setEmailAddressError] = useState('* Required');
    const [phoneNumberError, setPhoneNumberError] = useState('* Required');
    const [meansOfIdentificationError, setMeansOfIdentificationError] = useState('* Required');
    const [identificationNumberError, setIdentificationNumberError] = useState('* Required');
    const [identificationDocumentUrlError, setIdentificationDocumentUrlError] = useState('');
    const [allotedSharesError, setAllotedSharesError] = useState('* Required');
    const [shareClassError, setShareClassError] = useState('* Required');
    const [titleError, setTitleError] = useState('* Required');
    const [signatureUrlError, setSignatureUrlError] = useState('* Required');
    const [designationTitleError, setDesignationTitleError] = useState('');
    


    const validateEmailAddress = (emailAddress) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(emailAddress).toLowerCase());
    };


    useEffect(()=>{
    },[axios, initialized, token]);

    function saveDirectorToSession(){
        const director = buildDirectorData();
        axios.post(`${save_director_to_session_url}`, director)
        .then((response)=>{
            
        });
    }

   
    function clearForm(e) {
        e.preventDefault();
        setShowDirectorForm(false);
        setDisplaySearch(true);
        setShowRegisterDirector(true);
        setShowDirectorTable(true);
    }



    const handleInputChange = async value => {
        setValue(value);
        await validateAllFields();
    }

    const handleChange = async value => {
        setSelectedCountry(value);
        await validateAllFields();
        if (value) {
            setCountrySelectError('');
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            setSelectedCountry(null);
            promiseOptions().then((countries) => {
                setCountries(countries);
                setOptions(countries);
            })
        }
    };

    async function validateAllFields() {
        var countrySelectedValid = false;
        if (selectedCountry ?.id) {
            countrySelectedValid = true;
            setCountrySelectError('');
        } else {
            setCountrySelectError('* Required');
        }
        const firstNameInput = document.getElementById('firstName');
        const firstNameValue = firstNameInput.value;
        const firstNameValid = firstNameValue && firstNameValue !== '';
        if (firstNameValid) {
            setFirstNameError('');
        } else {
            setFirstNameError('* Required');
        }
        const middleNameInput = document.getElementById('middleName');
        const middleNameValue = middleNameInput.value;
        const middleNameValid = true;//middleNameValue && middleNameValue!=='';
        if (middleNameValid) {
            setMiddleNameError('');
        } else {
            setMiddleNameError('* Required');
        }
        const lastNameInput = document.getElementById('lastName');
        const lastNameValue = lastNameInput.value;
        const lastNameValid = lastNameValue && lastNameValue !== '';
        if (lastNameValid) {
            setLastNameError('');
        } else {
            setLastNameError('* Required');
        }
        const dateOfBirthInput = document.getElementById('dateOfBirth');
        const dateOfBirthValue = dateOfBirthInput.value;
        const dateOfBirthValid = dateOfBirthValue && dateOfBirthValue != 'mm.dd.yyy' && dateOfBirthValue !== '';
        if (dateOfBirthValid) {
            setDateOfBirthError('');
        } else {
            setDateOfBirthError('* Required');
        }
        const genderInput = document.getElementById('gender');
        const genderValue = genderInput.value;
        const genderValid = genderValue && genderValue != '--' && dateOfBirthValue !== '';
        if (genderValid) {
            setGenderError('');
        } else {
            setGenderError('* Required');
        }

        const emailAddressInput = document.getElementById('emailAddress');
        const emailAddressValue = emailAddressInput.value;
        const emailAddressValid = emailAddressValue && emailAddressValue !== '';
        if (emailAddressValid) {
            setEmailAddressError('');
            if (!validateEmailAddress(emailAddressValue)) {
                setEmailAddressError('Invalid Email Address');
            }
        } else {
            setEmailAddressError('* Required');
        }

        const phoneNumberInput = document.getElementById('phoneNumber');
        const phoneNumberValue = phoneNumberInput.value;
        const phoneNumberValid = phoneNumberValue && phoneNumberValue !== '';
        if (phoneNumberValid) {
            setPhoneNumberError('');
        } else {
            setPhoneNumberError('* Required');
        }

        const meansOfIdentificationInput = document.getElementById('meansOfIdentification');
        const meansOfIdentificationValue = meansOfIdentificationInput.value;
        const meansOfIdentificationValid = meansOfIdentificationValue && meansOfIdentificationValue !== '--';
        if (meansOfIdentificationValid) {
            setMeansOfIdentificationError('');
        } else {
            setMeansOfIdentificationError('* Required');
        }


        const identificationNumberInput = document.getElementById('identificationNumber');
        const identificationNumberValue = identificationNumberInput.value;
        const identificationNumberValid = identificationNumberValue && identificationNumberValue !== '';
        if (identificationNumberValid) {
            setIdentificationNumberError('');
        } else {
            setIdentificationNumberError('* Required');
        }

        const allotedSharesInput = document.getElementById('allotedShares');
        const allotedSharesValue = allotedSharesInput.value;
        const allotedSharesValid = allotedSharesValue && allotedSharesValue !== '';
        if (allotedSharesValid) {
            setAllotedSharesError('');
        } else {
            setAllotedSharesError('* Required');
        }

        const shareClassInput = document.getElementById('shareClass');
        const shareClassValue = shareClassInput.value;
        const shareClassValid = shareClassValue && shareClassValue !== '--' && shareClassValue !== '';
        if (shareClassValid) {
            setShareClassError('');
        } else {
            setShareClassError('* Required');
        }

        const titleInput = document.getElementById('title');
        const titleValue = titleInput.value;
        const titleValid = titleValue && titleValue !== '--' && titleValue !== '';
        if (titleValid) {
            setTitleError('');
        } else {
            setTitleError('* Required');
        }


        const designationTitleInput = document.getElementById('designationTitle');
        const designationTitleValue = designationTitleInput.value;
        const designationTitleValid = designationTitleValue && designationTitleValue !== '--' && designationTitleValue !== '';
        if (designationTitleValid) {
            setDesignationTitleError('');
        } else {
            setDesignationTitleError('* Required');
        }

        const signatureUrlInput = document.getElementById('signatureUrl');
        const signatureUrlValue = signatureUrlInput.value;
        const signatureUrlValid = signatureUrlValue && signatureUrlValue !== '';
        if (designationTitleValid) {
            setSignatureUrlError('');
        } else {
            setSignatureUrlError('* Required');
        }


        const allFieldsValid = signatureUrlValid && designationTitleValid && titleValid && shareClassValid && allotedSharesValid && identificationNumberValid && meansOfIdentificationValid && emailAddressValid && genderValid && dateOfBirthValid && countrySelectedValid && firstNameValid && middleNameValid && lastNameValid;
        setAllFieldsValid(allFieldsValid);
        return allFieldsValid;
    }

    const promiseOptions = (inputValue) => new Promise(async (resolve, reject) => {

        try {

            if (inputValue) {
                const search_url = `${search_country_url}${inputValue}`;
                await axios.get(search_url).then(result => {
                    const res = result.data;
                    setOptions(res);
                    resolve(res);
                }).then((resss) => {

                    if (resss) {
                        resolve(resss);
                    } else {
                    }
                });

            } else {
                await axios.get(country_url).then(result => {
                    const countries__ = result.data;
                    setOptions(countries__);
                    return countries__;
                });
                setCountries(countries);
                resolve(countries);
            }
        } catch (e) {
            reject(e);
        }

    });

    function buildDirectorData(){
        const person = {
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            dateOfBirth: new Date( `${dateOfBirth} 00:00:00` ).getTime(),
            gender: gender,
            emailAddress: emailAddress,
            phoneNumber: phoneNumber,
            nationality: selectedCountry?.id,
            meansOfIdentification: meansOfIdentification,
            identificationNumber: identificationNumber,
            identificationDocumentUrl: identificationDocumentUrl,
            title: title,
            signatureUrl: signatureUrl
        }
        const business = {
            registrationCode: registrationCode,
            userId: user.id,
            country: selectedCountry?.id
        }
        const director = {
            registrationCode: registrationCode,
            designationTitle: designationTitle,
            person: person,
            business: business
        }
       
        return  director;
    }

    function hideSaveStatus() {
        setShowDirectorForm(false);
        setShowDirectorTable(true);
        const saveStatusSpan = document.getElementById('saveStatusSpan');
        saveStatusSpan.style.display = 'none';
    }

    function post(e) {
        e.preventDefault();
        
        const director = buildDirectorData();

        setLoading(true);
        axios.post(save_director_url,director).then((response)=>{
            setLoading(false);
            const saveStatusSpan = document.getElementById('saveStatusSpan');
            saveStatusSpan.style.display = 'flex';
            setTimeout(hideSaveStatus, 500);
            loadData();
        });
    }

    return (
        <div className="businessArea">
            
            <div className="dasbhoard-form-header">Add Director</div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="title">
                    Title
                    </label>
                <select value={directorFormStateReducer.title} id="title" className="formInputs" onChange={(e) => { dispatch( setTitle(e.target.value) ); validateAllFields(); }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='title'>
                    <option value="--">-- Select Title--</option>
                    {[{ value: "Mr.", name: "Mr." }, { value: "Mrs.", name: "Mrs." }, { value: "Miss.", name: "Miss." }].map((shareClass) => (
                        <option key={shareClass.value} value={shareClass.value}>{shareClass.name}</option>
                    ))}
                </select>
                {titleError && <span className="formError" id="titleError">{titleError}</span>}

            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="designationTitle">
                    Designation Title
                    </label>
                <select  value={directorFormStateReducer.designationTitle}  id="designationTitle" className="formInputs" onChange={(e) => { console.log(e.target.value); dispatch(setDesignationTitle(e.target.value)); validateAllFields();  }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='title'>
                    <option value="--">-- Select Title--</option>
                    {[{ value: "CHAIRPERSON", name: "Chair person" },
                    { value: "SECRETARY", name: "Secretary" },
                    { value: "TREASURER", name: "Treasurer" },
                    { value: "VICE_CHAIRPERSON", name: "Vice Chairperson" },
                    { value: "VICE_SECRETARY", name: "Vice Secretary" },
                    { value: "VICE_TREASURER", name: "Vice Treasurer" },
                    { value: "OTHER_ADMINISTRATOR", name: "Other Administrator" }].map((shareClass) => (
                        <option key={shareClass.value} value={shareClass.value}>{shareClass.name}</option>
                    ))}
                </select>
                {designationTitleError && <span className="formError" id="designationTitleError">{designationTitleError}</span>}

            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="firstName">
                    First Name
                    </label>
                <input value={directorFormStateReducer.firstName} id="firstName" className="formInputs" onChange={(e) => { dispatch(setFirstName(e.target.value)); validateAllFields();   }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} ></input>
                {firstNameError && <span className="formError" id="firstNameError">{firstNameError}</span>}
            </div>


            <div className="formFieldInput">
                <label className="formLabels">
                    Middle Name
                    </label>
                <input value={directorFormStateReducer.middleName} id="middleName" className="formInputs" onChange={(e) => { dispatch(setMiddleName(e.target.value)); validateAllFields();}} onClick={()=>{saveDirectorToSession(); validateAllFields();}} ></input>
                {middleNameError && <span className="formError" id="firstNameError">{middleNameError}</span>}
            </div>


            <div className="formFieldInput">
                <label className="formLabels" htmlFor="lastName">
                    Last Name
                    </label>
                <input value={directorFormStateReducer.lastName}  id="lastName" className="formInputs" onChange={(e) => { dispatch(setLastName(e.target.value)); validateAllFields(); }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} ></input>
                {lastNameError && <span className="formError" id="lastNameError">{lastNameError}</span>}
            </div>


            <div className="formFieldInput shareholderDateFormControl">
                <label className="formLabels shareHolderDateFormLabel" htmlFor='dateOfBirth'>
                    Date of Birth
                    </label>
                {/*<div className={styles.dateDiv}>
                        <DatePicker className={styles.shareHolderDateFormInput} selected={dateOfBirth} onChange={(date) => setDateOfBirth(date)} />
                    </div>*/}
                <input value={directorFormStateReducer.dateOfBirth}  id='dateOfBirth' className="formInputs" onChange={(e) => { dispatch( setDateOfBirth(e.target.value) ); validateAllFields(); }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='dateOfBirth' type="date"></input>
                {dateOfBirthError && <span className="formError" id="dateOfBirthError">{dateOfBirthError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="gender">
                    Gender
                    </label>
                <select value={directorFormStateReducer.gender}  id="gender" className="formInputs" onChange={(e) => { dispatch(setGender(e.target.value)); validateAllFields(); }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='gender'>
                    <option value="--">-- Select Gender--</option>
                    {[{ value: "Male", name: "Male" }, { value: "Female", name: "Female" }, { value: "Other", name: "Other" }].map((shareClass) => (
                        <option key={shareClass.value + "x"} value={shareClass.value}>{shareClass.name}</option>
                    ))}
                </select>
                {genderError && <span className="formError" id="genderError">{genderError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="emailAddress">
                    Email Address
                    </label>
                <input value={directorFormStateReducer.emailAddress}  id="emailAddress" className="formInputs" onChange={(e) => { dispatch(setEmailAddress(e.target.value)); validateAllFields();  }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='emailAddress' ></input>
                {emailAddressError && <span className="formError" id="emailAddressError">{emailAddressError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="phoneNumber">
                    Phone Number
                    </label>
                <input value={directorFormStateReducer.phoneNumber}  id="phoneNumber" className="formInputs" onChange={(e) => { dispatch(setPhoneNumber(e.target.value)); validateAllFields();  }}  onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='phoneNumber'></input>
                {phoneNumberError && <span className="formError" id="phoneNumberError">{phoneNumberError}</span>}
            </div>


            <div className="formFieldInput">
                <label className="formLabels" htmlFor="nationality">
                    Nationality (Country of origin)
                    </label>
                <AsyncSelect
                    id="nationality"
                    cacheOptions
                    defaultOptions
                    placeholder='Start typing country name'
                    value={selectedCountry}
                    options={options}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    loadOptions={promiseOptions}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown} />
                {countrySelectError && <span className="formError" id="countrySelectError">{countrySelectError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="meansOfIdentification">
                    Means of Identification
                    </label>
                <select value={directorFormStateReducer.meansOfIdentification} id="meansOfIdentification" className="formInputs" onChange={(e) => { dispatch(setMeansOfIdentification(e.target.value)); validateAllFields();  }} onClick={()=>{saveDirectorToSession(); validateAllFields();}}>
                    <option key="xf" value="--">-- Select ID Means --</option>
                    {[{ value: 'Passport', name: 'Passport' }, { value: 'National Id', name: 'National Id' }, { value: 'Party Number', name: 'Party Number' }].map((means) => (
                        <option key={means.value} value={means.value}>{means.name}</option>
                    ))}
                </select>
                {meansOfIdentificationError && <span className="formError" id="meansOfIdentificationError">{meansOfIdentificationError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="identificationNumber">
                    Identification Number
                    </label>
                <input value={directorFormStateReducer.identificationNumber} id="identificationNumber" className="formInputs" onChange={(e) => { dispatch( setIdentificationNumber(e.target.value)); validateAllFields(); }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='identificationNumber'></input>
                {identificationNumberError && <span className="formError" id="identificationNumberError">{identificationNumberError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="identificationDocumentUrl">
                    Identification Document
                    </label>
                <input value={directorFormStateReducer.identificationDocumentUrl} id="identificationDocumentUrl" className="formInputs" onChange={(e) => { dispatch(setIdentificationDocumentUrl(e.target.value)); validateAllFields(); }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='identificationDocument'></input>
                {identificationDocumentUrlError && <span className="formError" id="identificationDocumentUrlError">{identificationDocumentUrlError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor={signatureUrl}>
                    Signature
                    </label>
                    <input value={directorFormStateReducer.signatureUrl} id="signatureUrl" value={signatureUrl} className="formInputs" onChange={(e) => { dispatch( setSignatureUrl(e.target.value)); validateAllFields();  }}  onClick={saveDirectorToSession} name='signature'></input>
                {signatureUrlError && <span className="formError" id="signatureUrlError">{signatureUrlError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="allotedShares">
                    Alloted Shares
                    </label>
                <input value={directorFormStateReducer.allotedShares}  id="allotedShares" className="formInputs" onChange={(e) => { dispatch( setAllotedShares(e.target.value)); validateAllFields();  }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='allotedShares' type='number'></input>
                {allotedSharesError && <span className="formError" id="allotedSharesError">{allotedSharesError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="shareClass">
                    Share Class
                    </label>
                <select value={directorFormStateReducer.shareClass} id="shareClass" className="formInputs" onChange={(e) => { dispatch( setShareClass(e.target.value)); validateAllFields();  }} onClick={()=>{saveDirectorToSession(); validateAllFields();}} name='shareClass'>
                    <option value="--">-- Select Share Class--</option>
                    {[{ value: "ORDINARY", name: "Ordinary" }, { value: "PREFERRED", name: "Preferred" }].map((shareClass) => (
                        <option key={shareClass.value} value={shareClass.value}>{shareClass.name}</option>
                    ))}
                </select>
                {shareClassError && <span className="formError" id="shareClassError">{shareClassError}</span>}

            </div>

            <div className="formFieldInput btns">
                <button className="cancelBtn" onClick={(e) => { return clearForm(e) }}>Cancel</button>
                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                <span id="saveStatusSpan" className="saveStatusSpan">Saved.</span>
                <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
            </div>
        </div>
    )
}