import React, { useState, useEffect } from 'react';
import './business-registration.module.css'
import BusinessNamesForm from './business-names/business-names-form/business-names-form';
import useAxios from '../../../../../hooks/axios';
import { useAuth } from '../../../../../hooks/auth-hook';
import ResponsiveTable from '../../../../../common/responsive-table/responsive-table';
import validator from 'validator';
import ReactLoading from "react-loading";
import BusinessDashboard from './business-dashboard/business-dashboard';

const get_business_registrations_by_userId_url = `${process.env.API_BASE_URL}/business-registration`;

export default function BusinessRegistration(props) {

    const { axios, initialized, token } = useAxios.useAxios({});
    const { user, roles } = useAuth();
    
    var [showAddressForm, setShowAddressForm ] = useState(false);
    const [displayTable, setDisplayTable] = useState(true);
    const [displayForm, setDisplayForm] = useState(false);
    const [displayDashboard, setDisplayDashboard] = useState(false);
    const [showDashboardPanel, setShowDashboardPanel] = useState(true);
    const [displaySearch, setDisplaySearch] = useState(true);
    const [showNewBusinessButton, setShowNewBusinessButton] = useState(true);
    const [displayAddressTable, setDisplayAddressTable] = useState(false);
    const [showDirectorForm, setShowDirectorForm] = useState(false);
    const [showShareCapitalForm, setShowShareCapitalForm] = useState(false);
    const [showShareCapitalDash, setShowShareCapitalDash] = useState(false);
    const [showShareHolderForm, setShowShareHolderForm] = useState(false);
    const [showBusinessNames, setShowBusinessNames] = useState(false);
    const [showDirectorTable, setShowDirectorTable] = useState(true);
    const [showDirectorDash, setShowDirectorDash] = useState(true);
    const [showShareCapitalTable, setShowShareCapitalTable] = useState(true);

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const [search, setSearch] = useState();
    const headers = ['#','Request Date','Reg. Country','Reg Code','Type',  'Biz Names', 'Status',' '];
    const [loading, setLoading] = useState(false);
    const [showBackBtn, setShowBackBtn] = useState(false);
    const [registrationCode, setRegistrationCode] = useState();
    const [activeRegistrationCode, setActiveRegistrationCode] = useState();
    const [navigationTrail, setNavigationTrail] = useState([]);

    useEffect(() => {
        load();
    }, [axios, initialized, user]);

    const sanitize = (input) => {
        return validator.escape(String(input));
    };

    function setNavigation(page){
        let exists = false;
        navigationTrail.forEach((e)=>{
            if(e===page){
                exists = true;
            }
        });
        if(!exists){
            navigationTrail.push(page);
        }
    }

    function handleSearch(event){
        const page_ = 0;
        const limit_ = 5;
        const search_ = event.target.value;
        setLimit(limit_);
        setPage(page_);
        setSearch(search_);
        load(page_, limit_, search_);
    }

    function showBusinessDashboard(registrationCode_){
        navigationTrail.push('business-dashboard');
        setShowAddressForm(false);
        setShowDashboardPanel(true);
        setActiveRegistrationCode(registrationCode_);
        setRegistrationCode(registrationCode_);
        setDisplayTable(false);
        setDisplayForm(false);
        setDisplayDashboard(true);
        setShowBackBtn(true);
        setDisplaySearch(false);
        setShowNewBusinessButton(false);
        setDisplayAddressTable(false);
        setShowShareCapitalForm(false);
        setShowShareHolderForm(false);
        setShowBusinessNames(false);
        setShowDirectorForm(false);
        setShowDirectorTable(false);
        setShowDirectorDash(false);
        setShowShareCapitalTable(false);
        setShowShareCapitalDash(false);
    }


    function navigateBack(){
        const lastPage = navigationTrail.pop();
        if(lastPage==='register-new-business'){
            showBusinessRegistrationHome();
            setDisplaySearch(true);
            setShowNewBusinessButton(true);
        }
        if(lastPage==='business-dashboard'){
            showBusinessRegistrationHome();
            setDisplaySearch(true);
            setShowNewBusinessButton(true);
        }
        if(lastPage==='business-address-form'){
            showBusinessDashboard(activeRegistrationCode);
        }else{
            showBusinessRegistrationHome();
            setDisplaySearch(true);
            setShowNewBusinessButton(true);
            setNavigationTrail([]);
        }
    }

    function showBusinessRegistrationHome(){
        navigationTrail.push('business-registration-home');
        setDisplayTable(true);
        setDisplayForm(false);
        setDisplayDashboard(false);
        setShowBackBtn(false);
        setShowDirectorDash(false);
        setShowDirectorForm(false);
        setShowShareCapitalTable(false);
        setShowShareCapitalForm(false);
        setShowShareCapitalDash(false);
    }

    async function load(page_,limit_, search_) {
        if(axios){
            setLoading(true);
            const businessRegistrations = await axios.get(`${get_business_registrations_by_userId_url}/${user?.id||''}?page=${page_||page}&limit=${limit_||limit}&search=${sanitize(search_ || search || '')}`).then((response) => {
                setLoading(false);
                const total = response.data.total;
                const pages = response.data.pages;
                const page = response.data.page;
                setPages(pages);
                setTotal(total);
                
                if(response.data.businessRegistrations){
                    
                    const data_ = [];
                    response.data.businessRegistrations.forEach((businessRegistration)=>{
                        data_.push([
                            businessRegistration.id,
                            new Date(businessRegistration.createdAt).toDateString(),
                            businessRegistration.country.name,
                            <span className="clickableTableItem" onClick={()=>{showBusinessDashboard(businessRegistration.registrationCode)}}>{businessRegistration.registrationCode}</span>,
                            businessRegistration.type,
                            <div className="businessNamesDiv">
                                {businessRegistration.businessNames.map((bizName, idx)=>(
                                    <div className="biznameTableListing" key={bizName.id}>
                                        <div><b>{idx+1}</b>. <span className="bizNameSpanInTbl">{bizName.name}</span></div><div>&nbsp;&nbsp;&nbsp;<i className="fa fa-edit clickable"></i>&nbsp;&nbsp;&nbsp;<i className="fa fa-trash clickable"></i> </div></div> 
                                ))}
                            </div>,
                            businessRegistration.status,
                            <span><i className="fa fa-edit clickable"></i>&nbsp;&nbsp;&nbsp;<i className="fa fa-trash clickable"></i></span>
                        ]);
                    });
                    setData(data_);
                }
                return response.data;
            });
            return businessRegistrations;
        }
    }


    function add(){
        setNavigation('register-new-business');
        setDisplayForm(true);
        setDisplayTable(false);
        setShowBackBtn(true);
    }

    return (

        <div className="businessNamePanel">
            <div className='searchStrip'>
                
                <div className='newAddress'>
                    {showNewBusinessButton && <>
                        <span className="fa fa-plus-square-o" />
                        <span onClick={add}> &nbsp;Register new business</span></>
                    } 
                    {showBackBtn && <>
                        <span>{`| `}<span onClick={navigateBack} className="fa fa-arrow-left">{`  Back`}</span></span> </>}
                </div>

                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}

                {displaySearch &&
                <div className='searchDiv'>
                    <span className='searchSpan'>
                        <label htmlFor="search">
                            <input id="search" type="text" onKeyUp={(e) => { setSearch(e.target.value); load(); }} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                        </label>
                    </span>
                </div>}

            </div>

            {displayTable && <ResponsiveTable dataFetchFn={load} headers={headers} data={data} pagination={{ setPage: setPage, total: total, pages: pages, page: page }}></ResponsiveTable>}
            {displayForm && <BusinessNamesForm setShowBackBtn={setShowBackBtn} loadData={load} setDisplayForm={setDisplayForm} setDisplayTable={setDisplayTable}></BusinessNamesForm>}
            {displayDashboard && <BusinessDashboard showShareCapitalDash={showShareCapitalDash}  setShowShareCapitalDash={setShowShareCapitalDash}  showShareCapitalForm={showShareCapitalForm} setShowShareCapitalForm={setShowShareCapitalForm} showShareCapitalTable={showShareCapitalTable}  setShowShareCapitalTable={setShowShareCapitalTable} showDirectorDash={showDirectorDash} setShowDirectorDash={setShowDirectorDash} showDirectorTable={showDirectorTable} setShowDirectorTable={setShowDirectorTable} showBusinessNames={showBusinessNames} setShowBusinessNames={setShowBusinessNames} showShareHolderForm={showShareHolderForm} setShowShareHolderForm={setShowShareHolderForm} showShareCapitalForm={showShareCapitalForm} setShowShareCapitalForm={setShowShareCapitalForm} showDirectorForm={showDirectorForm} setShowDirectorForm={setShowDirectorForm} displayAddressTable={displayAddressTable} setDisplayAddressTable={setDisplayAddressTable} setShowAddressForm={setShowAddressForm} showAddressForm={showAddressForm} setShowDashboardPanel={setShowDashboardPanel} showDashboardPanel={showDashboardPanel}  setNavigation={setNavigation} registrationCode={registrationCode}></BusinessDashboard>}
        </div>
    )
}