import React from 'react'

const Input = ( {value, label, type, id, placeholder, onChange, onKeyUp, minLength,  error, onKeyPress} ) => {
  return (
    <div>
        <label htmlFor={id}>{label}</label><br />
        <input value={value} onKeyPress={onKeyPress} minLength={minLength||0} onKeyUp={onKeyUp} type={type} id={id} placeholder={placeholder} onChange={onChange}/>
        <div className='errorDiv'>&nbsp;{error}</div>
    </div>
  )
}

export default Input