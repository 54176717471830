import React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminDashboard from './components/dashboards/admin/AdminDashboard';
import PrivateRoute from './protected-routes/PrivateRoute';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloakInstance1, keycloakInstance2 } from './util/keycloakInstances';
import Keycloak from 'keycloak-js';
import Login from './components/login/login';
import Signup from './components/signup/signup';
import Index from './components/Index';
import DashboardIndex from './components/dashboard/';
import Dashboard from './components/dashboard/Dashboard';

const baseUrl = process.env.REACT_APP_BASE_URL || '';
const contextPropsUrl = `${process.env.API_BASE_URL}/context/switch`;

const eventLogger = (event, error) => {
};

const tokenLogger = (tokens) => {  
};

export default function App() {

    const dispatch = useDispatch();
    const registrationData = useSelector((state) => state.registrationData.value);
    const [keycloak, setKeycloak] = useState(registrationData.currentKeycloak.realm || keycloakInstance1);


    
    
    const switchKeycloakContext = (realmId, onLoad_) => {

        if (sessionStorage['currentRealmId'] !== realmId) {
            const keycloakConfig = {
                url: process.env.CORE_KC_BASE_URL || 'https://auth.crobod.africa',
                realm: realmId || sessionStorage['realmId'] || process.env.CORE_KC_REALM_NAME || 'crobod-dev',
                clientId: process.env.KC_WEB_CLIENT_ID || 'crobod-ui',
                pkceMethod: process.env.KC_WEB_CLIENT_PKCE_METHOD || 'S256',
                KeycloakResponseType: process.env.KC_RESPONSE_TYPE || 'code',
                onLoad: onLoad_ || process.env.KC_ONLOAD || 'check-sso',//'login-required'//,
                checkLoginIframe: process.env.KC_CHECK_LOGIN_IFRAME || false
            };
            setKeycloak(new Keycloak(keycloakConfig));
            sessionStorage['currentRealmId'] = realmId;
        } else {
        }


    };

    
    switchKeycloakContext(sessionStorage['currentRealmId']);

    return (


       
            <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'check-sso'/*'check-sso or login-required'*/ }} onEvent={eventLogger} onTokens={tokenLogger} >
                <React.StrictMode>                   
                    <Router basename={baseUrl}>
                        <Routes>
                            <Route path="/" element={<Index switchKeycloakContext={switchKeycloakContext} />} />
                            <Route path="/signup" element={<Signup switchKeycloakContext={switchKeycloakContext} />} />
                            <Route path="/login" element={<Login switchKeycloakContext={switchKeycloakContext} />} />
                            <Route path="/dashboard" element={<PrivateRoute><DashboardIndex /></PrivateRoute>} />
                            <Route path="/di" element={<Dashboard></Dashboard>} />
                        </Routes>
                    </Router>
                </React.StrictMode>
            </ReactKeycloakProvider>
      



    )
}

