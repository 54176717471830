import React from 'react';
import '../../App.css'
import { useNavigate, NavLink } from 'react-router-dom'
import { useRef, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import useAxios from '../../hooks/axios'
import DOMPurify from 'dompurify'
import FileUploadForm from '../file-upload-form/file-upload-form'
import  './Dashboard.module.css'

let test = [{ country: 'Kenya' }];

export default function Dashboard() {

	const vars = {}

	const axios = useAxios.useAxios({}).axios;


	async function testAxios() {


		console.log(axios)

		let testUrl = `${process.env.API_BASE_URL}/v1/shipper`

		test = await
			axios.get(testUrl)
				.then((response) => {
					let spanxxe = document.getElementById("spanxxe")
					spanxxe.innerHTML = '<br/> Status: ' + response.status + '<br/><br/>' + DOMPurify.sanitize(JSON.stringify(response.data));
					console.log('test', test);
					console.log(response);
				});
	}



	function getUseNavigate() {
		return useNavigate()
	}

	function getNavref() {
		return useRef()
	}

	const navRef = getNavref()
	const navigate = getUseNavigate()
	const { keycloak, initialized } = useKeycloak();


	const showNavbar = () => {
		navRef().current.classList.toggle("responsive-nav")
	}

	async function logout() {
		keycloak.logout()
	}

	async function home() {
		navigate('/')
	}

	async function navigateToP(path) {
		navigate(path)
	}



	return (
		keycloak?.authenticated &&

		<div className="dashBody">
			<div>Hello Dashboard</div>
			<div className="nav-mobile">
				<button onClick={keycloak?.authenticated ? logout : logIn}>{keycloak?.authenticated ? "Log Out" : "Log In"}</button>
			</div>
			<div className="nav-btn">
				<button onClick={keycloak?.authenticated ? logout : logIn}>{keycloak?.authenticated ? "Log Out" : "Log In"}</button>
			</div>
			<div className="nav-btn">
				<button onClick={home}>Home</button>
			</div>
			<div className="nav-btn">
				<button onClick={testAxios}>TestAxios</button>
			</div>

			<h1>Forms (should be re-styled by Oyedolapo.)</h1>
			<div className="nav-btn">
				<button onClick={(e)=>{return navigateToP('/business-registration-form')}}>Business Registration Form</button>
			</div>
			<div className="nav-btn">
				<button onClick={(e)=>{return navigateToP('/address-form')}}>Address Form</button>
			</div>
			<div className="nav-btn">
				<button onClick={(e)=>{return navigateToP('/sharecapital-form')}}>Share Capital Form</button>
			</div>
			<div className="nav-btn">
				<button onClick={(e)=>{return navigateToP('/share-holder-form')}}>Share Holder Form</button>
			</div>
			<div className="nav-btn">
				<button onClick={(e)=>{return navigateToP('/director-form')}}>Director Form</button>
			</div>

			<div className="nav-mobile">
				<button onClick={(e)=>{return navigateToP('/business-registration-form')}}>Business Registration Form</button>
			</div>
			<div className="nav-mobile">
				<button onClick={(e)=>{return navigateToP('/address-form')}}>Address Form</button>
			</div>
			<div className="nav-mobile">
				<button onClick={(e)=>{return navigateToP('/sharecapital-form')}}>Share capital form</button>
			</div>
			<div className="nav-mobile">
				<button onClick={(e)=>{return navigateToP('/share-holder-form')}}>Share holder form</button>
			</div>
			<div className="nav-mobile">
				<button onClick={(e)=>{return navigateToP('/director-form')}}>Director form</button>
			</div>

			<div className="nav-btn">
				<span id="spanxxe">Test</span>
			</div>
			<div className="nav-mobile">
				<span id="spanxxe"></span>
			</div>

			
			<FileUploadForm></FileUploadForm>
		</div>
	)
}