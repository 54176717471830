export const useCustomTableTheme = () => {

  const COLORS = {
    FONT_PRIMARY: '#234E4A',
    FONT_SECONDARY: '#234E4A',
    FONT_DISABLED: '#234E4A',
    BORDER: '#234E4A'
  }

  return {

    Table: ` 
      width:100%;
      --data-table-library_grid-template-columns:  8% 20% 15% 15% 20% 22%;
      `,
    Header: '',
    Body: '',
    BaseRow: `
      font-size: 16px;
      color: var(--Primay-green, #234E4A);
      text-align: left;
      font-family: Prompt;
      font-size: 14px;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
    `,
    HeaderRow: `
      color: var(--Primay-green, #234E4A);
      text-align: center;
      font-family: 'Prompt';
      font-size: 14px;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
      background: var(--Background, #F6F8F8);
      border-bottom: none;
    `,
    Row: `
      /*color: ${COLORS.FONT_SECONDARY};*/
      border-bottom: none;
  
      &.disabled {
        color: ${COLORS.FONT_DISABLED};
      }
  
      &:hover {
        color: ${COLORS.FONT_PRIMARY};
        font-weight: 300;
        cursor: pointer;
      }
  
      &:not(:last-of-type) > .td {
        border-bottom: none !important;
        border-bottom: none !important;
      }
    `,
    BaseCell: `
      padding: 6px 12px;
    `,
    HeaderCell: `
      color: var(--Primay-green, #234E4A);
      text-align: left;
      font-family: Prompt;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      border-bottom: none;
  
      .resizer-handle {
        background-color: ${COLORS.BORDER};
      }
  
      svg,
      path {
        fill: currentColor;
      }
    `,
    Cell: `
      &:focus {
        outline: dotted;
        outline-width: 1px;
        outline-offset: -1px;
      }
    `,
  }
}