import React, { useState, useEffect } from 'react';
import './business-address-form.css';
import useAxios from '../../../../../../hooks/axios';
import AsyncSelect from 'react-select/async';
import { useAuth } from '../../../../../../hooks/auth-hook';
import ReactLoading from "react-loading";
import CitySelect from '../../../1-dashboard/1-international-address/international-address-form/city-select/city-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DOMPurify from 'dompurify';

const post_business_address_url = `${process.env.API_BASE_URL}/address`;

const country_url = `${process.env.API_BASE_URL}/v1/country`;
const search_country_url = `${process.env.API_BASE_URL}/v1/country/search/`;


export default function BusinessAddressForm(props) {

    const setNavigation = props.setNavigation;
    const registrationCode = props.registrationCode;
    const setShowAddressForm = props.setShowAddressForm;
    const setShowDashboardPanel = props.setShowDashboardPanel;
    const loadData = props.loadData;
    const setDisplayAddressTable = props.setDisplayAddressTable;


    //setDisplayAddressTable(true);
    setNavigation('business-address-form');

    const axios = useAxios.useAxios({}).axios;

    const [loading, setLoading] = useState(false);
    const [allFieldsValid, setAllFieldsValid] = useState(false);
    const [town, setTown] = useState();
    const [district, setDistrict] = useState();
    const [postalCode, setPostalCode] = useState();
    const [state, setState] = useState();
    const [country, setCountry] = useState();
    const [description, setDescription] = useState();
    const [streetNumber, setStreetNumber] = useState();
    const [streetAddress, setStreetAddress] = useState();
    const [postalAddress, setPostalAddress] = useState();
    const [inputValue, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();

    const [citySelect, setCitySelect] = useState(<></>);
    const [selectedCity, setSelectedCity] = useState();
    const [citySelectError, setCitySelectError] = useState('');
    const [countrySelectError, setCountrySelectError] = useState('* Required');
    const { token, initialized: authInitialized, user, roles } = useAuth();
    const [countries, setCountries] = useState([{ value: '-1', label: 'Select Countries' }]);
    const [showLoader, setShowLoader] = useState(false);

    useState(()=>{
        setCitySelect(<CitySelect validateAllFields={validateAllFields} setCitySelectError={setCitySelectError} selectedCity={selectedCity} setSelectedCity={setSelectedCity} token={token} country={selectedCountry}></CitySelect>)
        if(axios){
            loadData();
        }
    },[selectedCountry, axios]);


    async function clearForm(event) {
        event.preventDefault();
        setShowAddressForm(false);
        setShowDashboardPanel(false);
        setDisplayAddressTable(true);
    }

    function hideSaveStatus() {
        setShowAddressForm(false);
        setShowDashboardPanel(true);
        const saveStatusSpan = document.getElementById('saveStatusSpan');
        saveStatusSpan.style.display = 'none';
    }

    


    // handle input change event
    const handleInputChange = async value => {
        setValue(value);
        await validateAllFields();
    };

    // handle selection
    const handleChange = async value => {
        setSelectedCountry(value);
        setCitySelect(<CitySelect validateAllFields={validateAllFields} setCitySelectError={setCitySelectError} selectedCity={selectedCity} setSelectedCity={setSelectedCity} token={token} country={value}></CitySelect>)
        await validateAllFields();
        if(value){
            setCountrySelectError('');
        }
    }

    async function validateAllFields(){
        var countrySelectedValid = false;
        if(selectedCountry?.id){
            countrySelectedValid = true;
            setCountrySelectError('');
        }else{
            setCountrySelectError('* Required');
        }
        const allFieldsValid = countrySelectedValid;
        setAllFieldsValid(allFieldsValid);
        return allFieldsValid;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            setSelectedCountry(null);
            promiseOptions().then((countries) => {
                setCountries(countries);
                setOptions(countries);
            })
        }
    };

    async function post(event) {

        event.preventDefault();
        const allFieldsValid = validateAllFields();

        if (allFieldsValid) {

            const data = {
                town: town,
                district: district,
                postalCode: postalCode,
                state: state,
                country: selectedCountry.id,
                city: selectedCity.id,
                description: description,
                streetNumber: streetNumber,
                streetAddress: streetAddress,
                postalAddress: postalAddress,
                ownerId: registrationCode,
                ownerType: 'Business'
            }

            setShowLoader(true);
            axios.post(post_business_address_url, data).then((response) => {
                setShowLoader(false);
                if (response.status >= 200 && response.status <= 210) {
                    const saveStatusSpan = document.getElementById('saveStatusSpan');
                    saveStatusSpan.style.display = 'flex';
                    setTimeout(hideSaveStatus, 500);
                    loadData();
                }
            });


        }
    }

    const promiseOptions = (inputValue) =>  new Promise((resolve, reject) => {
        
        try {

            if (inputValue) {
                setLoading(true);
                const search_url = `${search_country_url}${inputValue}`;
                axios.get(search_url).then(result => {
                    const res = result.data;
                    setLoading(false);
                    setOptions(res);
                    resolve(res);
                }).then((resss) => {

                    if (resss) {
                        resolve(resss);
                    } else {
                    }
                });

            } else {
                setLoading(true);
                axios.get(country_url).then(result => {
                    const countries__ = result.data;
                    setLoading(false);
                    setOptions(countries__);
                    return countries__;
                });
                setCountries(countries);
                resolve(countries);
            }
        } catch (e) {
            reject(e);
        }

    });

    return (
        <div className="addrFormContainer">

            <br/>
            <div className="dasbhoard-form-header">Add Address</div>

            
            <form className="formAddr">

                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="countrySelect2">Country</label>
                    <AsyncSelect
                        id="countrySelect2"
                        cacheOptions
                        defaultOptions
                        placeholder='Start typing country name'
                        value={selectedCountry}
                        options={options}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e.id}
                        loadOptions={promiseOptions}
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        onKeyUp={handleKeyDown} />
                       {countrySelectError && <span className="formError" id="countrySelectError">{countrySelectError}</span>}
                </div>

                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="citySelect">City</label>
                    {citySelect}
                    {citySelectError && <span className="formError" id="citySelectError">{citySelectError}</span>}
                </div>

                <div className="formFieldInput">
                    <label className="formLabels">
                        Town
                    </label>
                    <input className="formInputs" onChange={(e) => { return setTown(e.target.value) }} name='town'></input>
                </div>


                <div className="formFieldInput">
                    <label className="formLabels">
                        District
                    </label>
                    <input className="formInputs" onChange={(e) => { return setDistrict(e.target.value) }} name='district'></input>
                </div>

                <div className="formFieldInput">
                    <label className="formLabels">
                        Postal Code
                    </label>
                    <input className="formInputs" onChange={(e) => { return setPostalCode(e.target.value) }} name='postalCode'></input>
                </div>


                <div className="formFieldInput">
                    <label className="formLabels">
                        State
                    </label>
                    <input className="formInputs" onChange={(e) => { return setState(e.target.value) }} name='state'></input>
                </div>

                <div className="formFieldInput">
                    <label className="formLabels">
                        Street Number
                    </label>
                    <input className="formInputs" onChange={(e) => { return setStreetNumber(e.target.value) }} name='streetNumber'></input>
                </div>

                <div className="formFieldInput">
                    <label className="formLabels">
                        Street Address
                    </label>
                    <input className="formInputs" onChange={(e) => { return setStreetAddress(e.target.value) }} name='streetNumber'></input>
                </div>

                <div className="formFieldInput">
                    <label className="formLabels">
                        Postal Address
                    </label>
                    <input className="formInputs" onChange={(e) => { return setPostalAddress(e.target.value) }} name='streetAddress'></input>
                </div>

                <div className="formFieldInput">
                    <label className="formLabels">
                        Description
                    </label>
                    <input className="formInputs" onChange={(e) => { return setDescription(e.target.value) }} name='description'></input>
                </div>

                <div className="formFieldInput btns">
                    <button className="cancelBtn" onClick={(e) => { return clearForm(e) }}>Cancel</button>
                    {showLoader &&
                        <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                    <span id="saveStatusSpan" className="saveStatusSpan">Saved.</span>
                    <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
                </div>


            </form>

        </div >
    )
}