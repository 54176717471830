import React, { useState, useEffect } from 'react';
import './business-names-form.css';
import { useAuth } from '../../../../../../../hooks/auth-hook';
import useAxios from '../../../../../../../hooks/axios';
import AsyncSelect from 'react-select/async';
import ReactLoading from "react-loading";

const get_registration_code_url = `${process.env.API_BASE_URL}/business-registration/code`;
const post_business_registration_url = `${process.env.API_BASE_URL}/business-registration`;
const search_country_url = `${process.env.API_BASE_URL}/v1/country/search/`;
const country_url = `${process.env.API_BASE_URL}/v1/country`;


export default function     BusinessNamesForm(props) {

    const setDisplayForm = props.setDisplayForm;
    const setDisplayTable = props.setDisplayTable;
    const loadData = props.loadData;
    const setShowBackBtn = props.setShowBackBtn;

    const openCountries = ['Kenya','Nigeria','United States'];
    const [businessName1, setBusinessName1] = useState('');
    const [businessName2, setBusinessName2] = useState('');
    const [businessName3, setBusinessName3] = useState('');
    const [businessName4, setBusinessName4] = useState('');
   

    const [businessName1Error, setBusinessName1Error] = useState('* Required');
    const [businessName2Error, setBusinessName2Error] = useState('');
    const [businessName3Error, setBusinessName3Error] = useState('');
    const [businessName4Error, setBusinessName4Error] = useState('');
    const [countrySelectError, setCountrySelectError] = useState('* Required');
    const [registrationCode, setRegistrationCode] = useState();
    const [selectedCountry, setSelectedCountry] = useState();
    const [options, setOptions] = useState([]);
    const [countries, setCountries] = useState([{ value: '-1', label: 'Select Countries' }]);



    const [allFieldsValid, setAllFieldsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user, roles } = useAuth();
    const { axios, initialized, token } = useAxios.useAxios({});
    
    useEffect(() => {
        if (axios && initialized) {
            getRegistrationCode();
        }
    }, [axios, initialized, user]);


    const handleChange = async value => {
        setSelectedCountry(value);
        await validateAllFields();
        if (value) {
            const inOpen = openCountries.filter( (openC)=>{return value.name===openC} );
            if(inOpen.length<=0){
                setCountrySelectError('Only '+(openCountries.map((e, idx)=>(e!==''?(''+e+''):(idx+1)===openCountries.length ? ' & ':(' , ['+idx+']'))))+'are allowed currently.');
                setAllFieldsValid(false);
            }else{
                setCountrySelectError('');
            }
        }else{
            setCountrySelectError('* Required');
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            setSelectedCountry(null);
            promiseOptions().then((countries) => {
                setCountries(countries);
                setOptions(countries);
            })
        }
    };

    const handleInputChange = async value => {
        await validateAllFields();
    };

    const promiseOptions = (inputValue) => new Promise((resolve, reject) => {

        if (initialized)
            try {

                if (inputValue) {
                    setLoading(true);
                    const search_url = `${search_country_url}${inputValue}`;
                    axios.get(search_url).then(result => {
                        const res = result.data;
                        setLoading(false);
                        const filteredCountries = res;
                        setOptions(filteredCountries);
                        setCountries(filteredCountries);
                        resolve(filteredCountries);
                    }).then((resss) => {

                        if (resss) {
                            console.log(resss);
                            resolve(resss);
                        } else {
                        }
                    });

                } else {
                    setLoading(true);
                    axios.get(country_url).then(result => {
                        const countries__ = result.data;
                        const filteredCountries = countries__;
                        setLoading(false);
                        setOptions(filteredCountries);
                        setCountries(filteredCountries);
                        resolve(filteredCountries);
                    });

                }
            } catch (e) {
                console.error(e);
                reject(e);
            }

    });

    

    async function getRegistrationCode() {

        return await axios.get(get_registration_code_url).then((response) => {
            const registrationCodeInput = document.getElementById('registrationCode');
            if(registrationCodeInput){
                registrationCodeInput.value = response.data;
            }
            setRegistrationCode(response.data);
            return response.data;
        });

    }



    function validateAllFields() {

        const businessName1Input = document.getElementById('businessName1');
        const businessName1Value = businessName1Input.value;
        const businessName1Valid = businessName1Value && businessName1Value != '';

        if (businessName1Valid) {
            setBusinessName1Error('');
        } else {
            setBusinessName1Error('* Required');
        }

        const allFieldsValid = businessName1Valid;
        setAllFieldsValid(allFieldsValid);

        return allFieldsValid;

    }

    function clearForm(e) {
        e.preventDefault();
        props.setDisplayForm(false);
        props.setDisplayTable(true);
    }


    function hideSaveStatus() {
        setDisplayForm(false);
        setDisplayTable(true);
        const saveStatusSpan = document.getElementById('saveStatusSpan');
        saveStatusSpan.style.display = 'none';
    }

    async function post() {

        const businessNames = [
            { name: businessName1, approved: false, registrationCode: registrationCode },
            { name: businessName2, approved: false, registrationCode: registrationCode },
            { name: businessName3, approved: false, registrationCode: registrationCode },
            { name: businessName4, approved: false, registrationCode: registrationCode }
        ]
        const data = {
            userId: user.id,
            country: selectedCountry.id,
            registrationCode: registrationCode,
            type: 'Registration',
            businessNames: businessNames
        }
        setLoading(true);
        await axios.post(post_business_registration_url, data).then(async (response) => {
            setLoading(false);
            if (response.status >= 200 && response.status <= 210) {
                const saveStatusSpan = document.getElementById('saveStatusSpan');
                saveStatusSpan.style.display = 'flex';
                setTimeout(hideSaveStatus, 500);
                loadData();
            }
        });
    }

    
    return (            
            <div className="businessNamesForm">
                <div className="dasbhoard-form-header">Business Names</div>

                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="registrationCode">Business Registration Code.</label>
                    <input disabled id="registrationCode" type="text" className="formInputs" onChange={async (e) => { setRegistrationCode(e.target.value); await validateAllFields(); }} placeholder=''></input>
                </div>

                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="countrySelect">Country to register to</label>
                    <AsyncSelect
                        
                        id="countrySelect"
                        cacheOptions
                        defaultOptions
                        placeholder='Start typing country name'
                        value={selectedCountry}
                        options={options}
                        getOptionLabel={e => e.name}
                        getOptionValue={e => e.id}
                        loadOptions={promiseOptions}
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown} />
                    {countrySelectError && <span className="formError" id="countrySelectError">{countrySelectError}</span>}
                </div>

                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="businessName1">Business Name 1</label>
                    <input value={businessName1} id="businessName1" type="text" className="formInputs" onChange={async (e) => { setBusinessName1(e.target.value); await validateAllFields(); }} placeholder=''></input>
                    {businessName1Error && <span className="formError" id="businessName1Error">{businessName1Error}</span>}
                </div>

                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="businessName2">Business Name 2</label>
                    <input value={businessName2} id="businessName2" type="text" className="formInputs" onChange={async (e) => { setBusinessName2(e.target.value); await validateAllFields(); }} placeholder=''></input>
                    {businessName2Error && <span className="formError" id="businessName2Error">{businessName2Error}</span>}
                </div>

                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="businessName3">Business Name 3</label>
                    <input value={businessName3} id="businessName3" type="text" className="formInputs" onChange={async (e) => { setBusinessName3(e.target.value); await validateAllFields(); }} placeholder=''></input>
                    {businessName3Error && <span className="formError" id="businessName3Error">{businessName3Error}</span>}
                </div>

                <div className="formFieldInput">
                    <label className="formLabels" htmlFor="businessName4">Business Name 4</label>
                    <input value={businessName4} id="businessName4" type="text" className="formInputs" onChange={async (e) => { setBusinessName4(e.target.value); await validateAllFields(); }} placeholder=''></input>
                    {businessName4Error && <span className="formError" id="businessName4Error">{businessName4Error}</span>}
                </div>


                <div className="formFieldInput btns">
                    <button className="cancelBtn" onClick={(e) => { return clearForm(e) }}>Cancel</button>
                    {loading &&
                        <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                    <span id="saveStatusSpan" className="saveStatusSpan">Saved.</span>
                    <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
                </div>
            </div>


    );
}