import React, { useState, useEffect } from 'react';
import Input from '../Inputs/Input';
import flag from '../../assets/flag.png';
import './form.css';
import { useSelector, useDispatch } from 'react-redux';
import { setNames,setBusinessOrCompanyName,setEmailAddress,setRequestedLoanAmount,
    setPurposeOfLoan,setPhoneNumber, setBusinessRegistrationCountry,setBusinessIsRegistered, 
    setTermsAndConditionsAccepted, setOtp, clear} from '../../redux/reducers/startTodayFormDataSlice';
import useAxios from '../../hooks/axios';
import MaskedInput from 'react-text-mask';
import ReactLoading from "react-loading";


const Form = () => {

    const { axios, initialized, token } = useAxios.useAxios({});
    const startTodayFormDataSlice = useSelector((state) => state.startTodayFormDataSlice);
    const dispatch = useDispatch();
    const [nameError, setNameError] = useState('');
    const [businessOrCompanyNameError, setBusinessOrCompanyNameError] = useState('');
    const [emailAddressError, setEmailAddressError] = useState('');
    const [requestedLoanAmountError, setRequestedLoanAmountError] = useState('');
    const [purposeOfLoanError, setPurposeOfLoanError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [businessRegistrationCountryError, setBusinessRegistrationCountryError] = useState('');
    const [businessIsRegisteredError, setBusinessIsRegisteredError] = useState('');
    const [termsAndConditionsAcceptError, setTermsAndConditionsAcceptError] = useState('');
    const [loginButtonEnabled, setLoginButtonEnabled] = useState(false);
    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [otpBtnDisabled, setOtpBtnDisabled] = useState(false);
    const [countDownSeconds, setCountDownSeconds] = useState(0);
    const [countDownInterval, setCountDownInterval] = useState();
    const [otpNounce, setOtpNounce] = useState();
    //const [otp, setOtp] = useState();
    const [getStartedButtonIsDisabled, setGetStartedButtonIsDisabled] = useState(true);

    const [showRequestSubmitConfirm, setShowRequestSubmitConfirm] = useState(false);
    
    var _names, _setNames = startTodayFormDataSlice.value.names;
    var _businessOrCompanyName = startTodayFormDataSlice.value.businessOrCompanyName;
    var _emailAddress = startTodayFormDataSlice.value.emailAddress;
    var _requestedLoanAmount = startTodayFormDataSlice.value.requestedLoanAmount;
    var _purposeOfLoan = startTodayFormDataSlice.value.purposeOfLoan;
    var _phoneNumber = startTodayFormDataSlice.value.phoneNumber;
    var _businessRegistrationCountry = startTodayFormDataSlice.value.businessRegistrationCountry;
    var _businessIsRegistered = startTodayFormDataSlice.value.businessIsRegistered;
    var _termsAndConditionsAccepted = startTodayFormDataSlice.value.termsAndConditionsAccepted;

    useEffect(()=>{

        if(initialized){
            getStartTodayDataFromSession();
        }

    },[initialized]);

    async function getStartTodayDataFromSession(){
        try{
            const startRegId = sessionStorage['startRegId'] ? sessionStorage['startRegId'] : -1; 
            const startTodayUrl = `${process.env.API_BASE_URL}/start-today/${startRegId}`;
            
            await axios.get(startTodayUrl )
                .then((response) => {
                   
                    _names = response.data.names;
                    _businessOrCompanyName = response.data.businessOrCompanyName;
                    _emailAddress = response.data.emailAddress;
                    _requestedLoanAmount = response.data.requestedLoanAmount;
                    _purposeOfLoan = response.data.purposeOfLoan;
                    _phoneNumber = response.data.phoneNumber;
                    _businessRegistrationCountry = response.data.businessRegistrationCountry;
                    _businessIsRegistered = response.data.businessisRegistered;

                    setNames(_names);
                    setBusinessOrCompanyName(_businessOrCompanyName);
                    setEmailAddress(_emailAddress);
                    setRequestedLoanAmount(_requestedLoanAmount);
                    setPurposeOfLoan(_purposeOfLoan);
                    setPhoneNumber(_phoneNumber);
                    setBusinessRegistrationCountry(_businessRegistrationCountry);
                    setBusinessIsRegistered(_businessIsRegistered);
                    
                });
        }catch(Error){
            console.log(Error);
        }
    }

    function validateAllFields(overrideField, overrideValue){

        const namesValid = validate('names', startTodayFormDataSlice.value.names);
        var businessCompanyName =  validate('businessNameOrCompanyName', startTodayFormDataSlice.value.businessOrCompanyName);
        var emailAddrValid = validate('email-address', startTodayFormDataSlice.value.emailAddress);
        var requestedLoanAmtValid = validate('requestedLoanAmount', startTodayFormDataSlice.value.requestedLoanAmount);
        var phoneNumberValid = validate('phoneNumber', startTodayFormDataSlice.value.phoneNumber);
        var purposeOfLoanValid = (overrideField && overrideField==='purposeOfLoan') ? overrideValue :  validate('purposeOfLoan', startTodayFormDataSlice.value.purposeOfLoan);
        var businessRegistrationCountryValid = (overrideField && overrideField==='businessRegistrationCountry') ? overrideValue : validate('businessRegistrationCountry', startTodayFormDataSlice.value.businessRegistrationCountry);
        var businessIsRegisteredValid = (overrideField && overrideField==='businessIsRegistered') ? overrideValue :  validate('businessIsRegistered', startTodayFormDataSlice.value.businessIsRegistered);
        var termsAndConditionsAcceptedValid  = (overrideField && overrideField==='termsAndConditionsAccepted') ? overrideValue : validate('termsAndConditionsAccepted', startTodayFormDataSlice.value.termsAndConditionsAccepted);
        
        if(overrideField && overrideField==='purposeOfLoan'){
            if(overrideValue == true ) {
                setPurposeOfLoanError('');
            }else{
                setPurposeOfLoanError('What\'s the loan\'s purpose?');
            }
        }


        if(overrideField && overrideField==='businessRegistrationCountry'){
            if(overrideValue == true ) {
                setBusinessRegistrationCountryError('');
            }else{
                setBusinessRegistrationCountryError('Where is your business registered?');
            }
        }

        if(overrideField && overrideField==='businessRegistrationCountry'){
            if(overrideValue === 'Nigeria' || overrideValue === 'Ghana'
            || overrideValue === 'Kenya' || overrideValue === 'United Kingdom'
            || overrideValue === 'China' ) {
                setBusinessRegistrationCountryError('');
            }else{
                setBusinessRegistrationCountryError('Where is your business registered?');
            }
        }

        if(overrideField && overrideField==='businessIsRegistered'){
            if(overrideValue==='Yes' || overrideValue==='No' ) {
                setBusinessIsRegisteredError('');
            }else{
                setBusinessIsRegisteredError('Is your business registered?');
            }
        }

        if(overrideField && overrideField==='termsAndConditionsAccepted'){
            if(overrideValue===true) {
                setTermsAndConditionsAcceptError('');
            }else{
                setTermsAndConditionsAcceptError('*Mandatory');
            }
        } 
        const allValid =  namesValid && businessCompanyName && emailAddrValid && requestedLoanAmtValid && phoneNumberValid && purposeOfLoanValid && businessRegistrationCountryValid && businessIsRegisteredValid && termsAndConditionsAcceptedValid;
        if(allValid){
            setLoginButtonEnabled(true);
        }else{
            setLoginButtonEnabled(false);
        }
        return allValid;
    }


    async function getStarted(){ 
        const allFieldsOk = await validateAllFields();
        try{
            if(allFieldsOk){
                setShowRequestSubmitConfirm(true);
                
                var requestSubmitConfirm = document.getElementById('requestSubmitConfirm');
                requestSubmitConfirm.innerHTML = '';
                requestSubmitConfirm.style.display = 'block';
                        
                setShowLoader(true);
                const startTodayUrl = `${process.env.API_BASE_URL}/start-today`;
                await axios.post(startTodayUrl, startTodayFormDataSlice.value )
                .then((response) => {
                    setShowLoader(false);
                    sessionStorage['startRegId'] = response.data.id;
                    requestSubmitConfirm.innerHTML = 'Your request has been received and is being processed. Our representative will reach out to you shortly.';
                    
                    setTimeout(()=>{
                        console.log('Timeout');
                        document.getElementById('requestSubmitConfirm').style.display = 'none';
                        setShowRequestSubmitConfirm(false);
                    }, 15000);
                });
            }
        }catch(Error){
            console.log(Error);
        }
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    function validate(fieldName, value){
        if(fieldName==='businessIsRegistered'){
            if(value==='Yes' || value==='No'){
                setBusinessIsRegisteredError('');
                return true;
            }else{
                setBusinessIsRegisteredError('Is your business registered?');
                return false;
            }
        }

        if(fieldName==='businessRegistrationCountry'){
            if(value === 'Nigeria' || value === 'Ghana'
            || value === 'Kenya' || value === 'United Kingdom'
            || value === 'China'){
                setBusinessRegistrationCountryError('');
                return true;
            }else{
                setBusinessRegistrationCountryError('Where is your business registered?');
                return false;
            }
        }


        if(fieldName==='termsAndConditionsAccepted'){
            if((value==true || termsAndConditionsChecked==true)){
                setTermsAndConditionsAcceptError('');
                return true;
            }else{
                setTermsAndConditionsAcceptError('*Mandatory');
                return false;
            }
        }

        if(fieldName==='phoneNumber'){
            if((value || value!=='')){

                setPhoneNumberError('');
                return true;
            }else{
                setPhoneNumberError('Phone number is required');
                return false;
            }
        }

        if(fieldName==='requestedLoanAmount'){
            if((value || value!=='')){
                setRequestedLoanAmountError('');
                return true;
            }else{
                setRequestedLoanAmountError('Loan amount is required');
                return false;
            }
        }

        if(fieldName==='businessNameOrCompanyName'){
            if((value || value!=='')){
                setBusinessOrCompanyNameError('');
                return true;
            }else{
                setBusinessOrCompanyNameError('Business/Company name is required');
                return false;
            }
        }

        if(fieldName==='purposeOfLoan'){
            if(value==='Import' || value==='Export'){
                setPurposeOfLoanError('');
                return true;
            }else{
                setPurposeOfLoanError('Purpose of loan is required');
                return false;
            }
        }
        
        if(fieldName==='names'){
            if((value || value!=='')){
                setNameError('');
                return true;
            }else{
                setNameError('Your name is required');
                return false;
            }
        }

        if(fieldName==='email-address'){
            
            if((value || value!=='')){
                
                setEmailAddressError('');

                if(!validateEmail(value)){
                    setEmailAddressError('Email is invalid');
                    return false;
                }else{
                    setEmailAddressError('');
                    return true;
                }

            }else{
                setEmailAddressError('Email address is required');
                return false;
            }
        }

    }

    async function handleTermsAndConditionsChange(checked){
        
        dispatch( setTermsAndConditionsAccepted( checked ) );
        
        validate('termsAndConditionsAccepted', checked);  
        
        if(checked==true){
           await setTermsAndConditionsChecked(true);
           
           
        }else{ 
           await setTermsAndConditionsChecked(false); 
        } 

        validateAllFields('termsAndConditionsAccepted', checked);
    }


    async function validateOtp(otp) {

        dispatch( setOtp(otp) );// Not necessary???

        if (otp.length >= 4) {
            try {
                const otpUrl = `${process.env.API_BASE_URL}/otp/validate/${_emailAddress}/${_phoneNumber}/${otpNounce}/${otp}`;
                console.log(`::: otpUrl -> ${otpUrl}`);
                await axios.get(otpUrl).then((response) => {
                    const otpValidateResponse = response.data;
                    const generalNotification = document.getElementById('generalNotification');
                    if (otpValidateResponse.exists === true) {
                        if (otpValidateResponse.valid === true) {
                            setGetStartedButtonIsDisabled(false);
                            generalNotification.style.display = 'flex';
                            generalNotification.style.color = 'green';
                            generalNotification.innerHTML = 'Otp valid. Click \'Get Started\' to proceed.';
                            const fn_ = () => {
                                try {
                                    document.getElementById('generalNotification').innerHTML = '';
                                    document.getElementById('generalNotification').style.color = 'red';
                                    document.getElementById('generalNotification').style.display = 'none';
                                } catch (Error) {
                                    console.error(Error);
                                }
                            }
                            setTimeout(fn_, 5000);
                        } else {
                            if (otp.length >= 5)
                                generalNotification.innerHTML = 'OTP has already been used. Generate another';
                            //Exists, but invalid
                            setGetStartedButtonIsDisabled(true);

                            generalNotification.style.display = 'flex';
                            generalNotification.style.color = 'red';
                            generalNotification.innerHTML = 'This OTP has already been used. Please generate another one.';

                        }
                    } else {
                        if (otp.length >= 5)
                            generalNotification.innerHTML = 'OTP invalid. Try again';
                        //doesn't exist at all
                        setGetStartedButtonIsDisabled(true);
                    }
                });
            } catch (Error) {
                console.log(Error);
            }
        }
    }


    
    let counter_ = 60;
    let countDownInterval_ = countDownInterval;

    function updateCounter() {
        try {
            counter_ = counter_ - 1;
            setCountDownSeconds(counter_);
            if (counter_ === 0) {
                clearInterval(countDownInterval_);
            }
        } catch (Error) {
            console.error(Error);
        }
    }


    async function generateOtp() {
        try {
            const payload = {
                phoneNumber: _phoneNumber,
                emailAddress: _emailAddress,
                names: startTodayFormDataSlice.value.names
            }
            const allFieldsValid = await validateAllFields();
            if (otpBtnDisabled === false && allFieldsValid === true && countDownSeconds <= 0) {

                const otpUrl = `${process.env.API_BASE_URL}/otp/generate`;
                setShowLoader(true);
                await axios.post(otpUrl, payload)
                    .then((response) => {
                        setOtpNounce(response.data.otpNounce);
                        const interval_ = () => {
                            updateCounter();
                        }
                        countDownInterval_ = setInterval(interval_, 1000, countDownSeconds);
                        setCountDownInterval(countDownInterval_);
                        setCountDownSeconds(60);
                        const otpLink = document.getElementById('otpLink');
                        otpLink.className = 'send-otp-div-disabled';
                        otpLink.onclick = () => {};
                        otpLink.removeEventListener('click', generateOtp);
                        otpLink.removeEventListener('click', () => { console.log('Wait for countdown') });
                        setShowLoader(false);
                    });
            } else {
                // 'OTP requests Disabled. Wait timeout to expire'
            }
        } catch (Error) {
            console.log(Error);
        }
    }


    return (
        <div className='forms'>
            <h2>Start Today</h2>
            <div className="first-inputs">
                <Input
                    minLength='2'
                    label="Your name"
                    type="text"
                    id="name"
                    placeholder="Your name"
                    value={_names}
                    onChange={(e)=>{  dispatch( setNames( e.target.value )   )  }}
                    error={nameError}
                    onKeyUp={(e)=>{ validateAllFields(); } }
                />
                

                <Input
                    value={_businessOrCompanyName}
                    minLength='3'
                    label="Business/Company Name"
                    type="text"
                    id="business"
                    placeholder="Your Business name"
                    onChange={(e)=>{  dispatch( setBusinessOrCompanyName( e.target.value )   )   }}
                    error={businessOrCompanyNameError}
                    onKeyUp={(e)=>{ validateAllFields(); } }
                />
               
            </div>

            <div className="second-input">
                <Input
                    value={_emailAddress}
                    minLength='3'
                    label="Email"
                    type="email"
                    id="email"
                    placeholder="Email"
                    onChange={(e)=>{  dispatch( setEmailAddress( e.target.value )   )    }}
                    error={emailAddressError}
                    /*onKeyUp={(e)=>{ validate('email-address', e.target.value)}}*/
                    onKeyUp={(e)=>{ validateAllFields(); } }
                />
                
            </div>

            <div className="third-inputs">
               
                <Input
                    value={_requestedLoanAmount}
                    minLength='3'
                    label="Amount "
                    type="text"
                    id="amount"
                    placeholder="Amount"
                    onChange={(e)=>{   dispatch( setRequestedLoanAmount( e.target.value )   )    }}
                    error={requestedLoanAmountError}
                    /*onKeyUp={(e)=>{ validate('requestedLoanAmount', e.target.value)}}*/
                    onKeyUp={(e)=>{ validateAllFields(); } }
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                />
                

                <div className="datalist-input">
                    <label htmlFor="exchange">Purpose of loan</label><br />
                    <input value={_purposeOfLoan} onChange={(e)=>{   dispatch( setPurposeOfLoan( e.target.value )   ) ; validateAllFields('purposeOfLoan', (e.target.value && e.target.value!=='')); validate('purposeOfLoan', e.target.value);     }} list="exchange" name="exchange" />
                    <datalist id="exchange">
                        <option value="Import">Import</option>
                        <option value="Export">Export</option>
                    </datalist>
                    <div className="errorDiv">&nbsp;{purposeOfLoanError}</div>
                </div>
                
            </div>

            <div className="fourth-inputs">
                <div>
                    <label htmlFor="number">Phone Number</label><br />
                    <MaskedInput
                    value={_phoneNumber}
                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                    className="form-control"
                    placeholder="234"
                    guide={false}
                    id="number"
                    onBlur={() => {}}
                    onChange={(e)=>{   dispatch( setPhoneNumber( e.target.value )   )   }}
                    /*onKeyUp={(e)=>{ validate('phoneNumber', e.target.value)}}*/
                    onKeyUp={(e)=>{ validateAllFields(); } } 
                    />
                    <div className='errorDiv'>&nbsp;{phoneNumberError}</div>
                </div>

                <div className="datalist-input">
                    <label htmlFor="country">Business registration country</label><br />
                    <input value={_businessRegistrationCountry} onChange={(e)=>{   dispatch( setBusinessRegistrationCountry( e.target.value )   )  ; validateAllFields('businessRegistrationCountry', (e.target.value && e.target.value!==''));  validate('businessRegistrationCountry', e.target.value);   }} list="country" name="country" placeholder="Select Country" />
                    <datalist id="country">
                        <option value="Nigeria">Nigeria</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Kenya">Kenya</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="China">China</option>
                    </datalist>
                    <div className="errorDiv">&nbsp;{businessRegistrationCountryError}</div>
                </div>
            </div>

            <div className="fifth-input">
                <div className="datalist-input">
                    <label htmlFor="register">Is your business registered</label><br />
                    <input onChange={ (e)=>{   dispatch( setBusinessIsRegistered( e.target.value  )   ) ;  validateAllFields('businessIsRegistered', e.target.value );   validate('businessIsRegistered', e.target.value);    }} list="register" name="register" placeholder="Yes" />
                    <datalist id="register">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </datalist>
                    <div className="errorDiv">&nbsp;{businessIsRegisteredError}</div>
                </div>
            </div>

            <div className="fifth-and-a-half-input">
                <div className="otpDiv">
                    {countDownSeconds>0 &&
                    <span className="sendAgainIn" id="sendAgainIn">Send Again in <b>{countDownSeconds}</b>s</span>
                    }
                    {countDownSeconds<=0 &&
                    <span onClick={generateOtp} className="send-otp-div" id="otpLink" >Send Email Otp</span>
                    }
                    <label className="otpLabel" htmlFor="otp">OTP</label>
                    <input onChange={(e) => { dispatch( setOtp(e.target.value) ); validateOtp(e.target.value);; validateAllFields(); }}  className="otp-input" id="otp" type="text"></input>
                </div>
            </div>

            <div className="sixth-input">
                <input /*value={_termsAndConditionsAccepted}*/ onChange={ (e)=>{  handleTermsAndConditionsChange(e.target.checked)}} type="checkbox" name="terms" id="terms" />
                <label htmlFor="terms">I accept terms and condition and privacy policy</label>
                <div className="errorDiv">&nbsp;{termsAndConditionsAcceptError}</div>
            </div>

            

            <div className="form-btn">
                <span id="generalNotification" className="generalNotification"></span>
             {showLoader &&   
            <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
            
                <div id="requestSubmitConfirm" className="requestSubmitConfirm"></div>
            {!showLoader &&
                <button type="button" onClick={getStarted} disabled={!loginButtonEnabled || !termsAndConditionsChecked || getStartedButtonIsDisabled} >Get Started</button>}
            </div>
        </div>
    )
}

export default Form