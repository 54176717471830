import React from 'react'
import './footer.css'
import secondLogo from '../../assets/secondLogo.png'
import { FaTwitter, FaLinkedinIn, FaInstagram, FaFacebookF } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-heading">
            <h1>Subscribe to our newsletter</h1>
        </div>
        <div className="footer-input">
            <input type="email" name="email" id="footer-mail" placeholder='Enter your email address'/>
            <span><p>Subscribe</p></span>
        </div>
        <div className="footer-links">
            <div className="first-column">
                <img src={secondLogo} alt="Logo" />
                <p>Crobod funds you to make international trade for your business and equips you with all the tools
                    your business needs for a successful global trade
                </p>
            </div>
            <div className="footer-links_div">
                <p>About Us</p>
                <p>Crobod Biz</p>
                <p>Crobod Loan</p>
                <p>CroboD Prime</p>
            </div>
            <div className="footer-links_div">
                <p>Integrations</p>
                <p>Terms</p>
                <p>Privacy</p>
                <p>Contact</p>
            </div>
            <div className="footer-links_div">
                <p>FAQs</p>
                <p>Careers</p>
                <p>Blog</p>
                <p>Calculator</p>
            </div>
            <div className="last-column">
                <h4>Stay updated</h4>
                <div className="social-links">
                    <p><FaTwitter title="Twitter"/></p>
                    <p><FaLinkedinIn title="LinkedIn"/></p>
                    <p><FaInstagram title="Instagram"/></p>
                    <p><FaFacebookF title="Facebook"/></p>
                </div>
            </div>
        </div>
        <small>Copyright, {new Date().getFullYear()}</small>
    </div>
  )
}

export default Footer