import React from 'react'
import './subscribe.css'

const Subscribe = () => {
  return (
    <div className='subscribe'>
        <div className="subscribe-content">
            <p>If you subscribe for Crobod Prime, delivery from the cargo/port office to your 
                address (and vice versa for exporters) will be free for all shipments below 10kg and
                at 50% discount for all shipments above 10kg
            </p>
            <div className="subscribe-btn">
                <button type="button">Subscribe now</button>
            </div>
        </div>
    </div>
  )
}

export default Subscribe