import React, { useState, useEffect } from 'react';
import './share-holder.css';
import ShareHolderForm from './share-holder-form/share-holder-form';
import useAxios from '../../../../../../hooks/axios';
import ResponsiveTable from '../../../../../../common/responsive-table/responsive-table';
import ReactLoading from "react-loading";
import { Tooltip } from 'react-tooltip';

const search_share_holder_url = `${process.env.API_BASE_URL}/share-holder`;

export default function ShareHolder(props) {


    const setShareHolderCount = props.setShareHolderCount;
    const registrationCode = props.registrationCode;
    const [showShareHolderForm, setShowShareHolderForm] = useState(false);
    const [showShareHolderTable, setShowShareHolderTable] = useState(true);
    const { axios, initialized, token } = useAxios.useAxios({});

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(false);
    const headers = ['Names','Email Address', 'Phone Number', 'No Shares','Share Class',''];


    useEffect(()=>{
        if(initialized){
            loadData();
        }
    },[initialized]);


    function handleSearch() {
        const page_ = 0;
        const limit_ = 5;
        const search_ = event.target.value;
        setLimit(limit_);
        setPage(page_);
        setSearch(search_);
        loadData(page_, limit_, search_);
    }

    function showToolTip(tooltipId, toolTipcontent){
        return (<>
        <a data-tooltip-id="my-tooltip" data-tooltip-content="Hello world!">◕‿‿◕</a></>);
    }


    function addShareHolderAddress(shareHolderId){
        console.log(`Add share holder :: ${shareHolderId}`);
    }

    function deleteShareHolder(shareHolderId){
        console.log(`Delete share holder :: ${shareHolderId}`);
    }

    function editShareHolder(shareHolderId){
        console.log(`Edit share holder :: ${shareHolderId}`);
    }

    async function loadData(page_, limit_, search_) {
        try {

            if (axios) {
                setLoading(true);
                await axios.get(`${search_share_holder_url}/${registrationCode}?page=${page_ || page || ''}&limit=${limit_ || limit}&search=${search_ || search || ''}`)
                    .then((response) => {

                        setLoading(false);
                        const resp = response.data;

                        const data_ = [];
                        const total = resp.total;
                        const pages = resp.pages;
                        const page = resp.page;
                        setPages(pages);
                        setTotal(total);
                        setShareHolderCount(total);
                        resp ?.shareHolders ?.forEach((shareHolder) => {
                            data_.push([
                                shareHolder.person?.firstName,
                                shareHolder.person?.emailAddress,
                                shareHolder.person?.phoneNumber,
                                shareHolder.allottedShares,
                                shareHolder.classOfShares,
                                (<div>    
                                    <a data-tooltip-id="edit-tooltip" data-tooltip-content="Edit">
                                            <Tooltip id="edit-tooltip" />
                                            <span className="fa fa-edit" onClick={(e)=>{ editShareHolder(shareHolder.id); }}></span>
                                    </a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a data-tooltip-id="delete-tooltip" data-tooltip-content="Delete">
                                        <Tooltip id="delete-tooltip" />
                                        <span className="fa fa-trash" onClick={(e)=>{ deleteShareHolder(shareHolder.id); }}></span>
                                    </a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a data-tooltip-id="add-address-tooltip" data-tooltip-content="Add Shareholder Address">
                                        <Tooltip id="add-address-tooltip" />
                                        <span className="fa fa-address-card-o" onClick={(e)=>{ addShareHolderAddress(shareHolder.id); }}></span>
                                    </a> 
                                </div>)
                            ])

                        });
                        setData(data_);
                    });
            }

        } catch (Error) {
            console.log(Error);

        }
    }

    function add() {

        setShowShareHolderForm(true);
        setShowShareHolderTable(false);

    }

    return (
        <>
            <div className='searchStrip'>

                <div className='newAddress' onClick={add}>

                    <span onClick={add} className="fa fa-user-plus" />
                    <span onClick={add}> &nbsp;Add Share Holder</span>

                </div>

                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}


                <div className='searchDiv'>
                    <span className='searchSpan'>
                        <label htmlFor="search">
                            <input id="search" type="text" onKeyUp={(e) => { setSearch(e.target.value); }} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                        </label>
                    </span>
                </div>

            </div>
            {showShareHolderTable && <ResponsiveTable dataFetchFn={loadData} headers={headers} data={data} pagination={{ setPage: setPage, total: total, pages: pages, page: page }}></ResponsiveTable>}
            {showShareHolderForm && <ShareHolderForm setShowShareHolderTable={setShowShareHolderTable} setShowShareHolderForm={setShowShareHolderForm} registrationCode={registrationCode}></ShareHolderForm>}
        </>
    );
}