import React from 'react'
import calendar from '../../assets/calendar.png'
import './Register.css'

const Register = () => {
  return (
    <div className='register'>
        <div className="register-content">
            <div className="register-text">
                <h2>You don't have a registered business or need to upgrade yours?</h2>
                <p>We will help you get your business registered in a couple of days.
                    Make half payment right now and the balance when your loan is approved.
                </p>
                <button type="button">Register or Upgrade Now</button>
            </div>
            <div className="register-image">
                <img src={calendar} alt="calendar" />
            </div>
        </div>
    </div>
  )
}

export default Register