import React, { useState, useEffect } from 'react';
import './director.css';
import DirectorForm from './director-form/director-form';
import ResponsiveTable from '../../../../../../common/responsive-table/responsive-table';
import useAxios from '../../../../../../hooks/axios';
import ReactLoading from "react-loading";

const get_directors_url = `${process.env.API_BASE_URL}/director`;

export default function Director(props) {

    const { axios, initialized, token } = useAxios.useAxios({});

    const headers = ['No.', 'Designation', 'First Name', 'Middle Name','Last Name', 'D.O.B', 'Email', 'Phone No.', 'Gender'];
    const setShowDirectorForm = props.setShowDirectorForm;
    const showDirectorForm = props.showDirectorForm;
    const setShowDirectorTable = props.setShowDirectorTable;
    const showDirectorTable = props.showDirectorTable;
    const navigateBack = props.navigateBack;
    const setShowDirectorDash = props.setShowDirectorDash;
    const directorCount=props.directorCount;
    const setDirectorCount=props.setDirectorCount;
    
    const registrationCode = props.registrationCode;
    const loading = props.loading;
    const setLoading = props.setLoading;
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const [search, setSearch] = useState();
    const [displaySearch, setDisplaySearch] = useState(true);
    const [showBackBtn, setShowBackBtn] = useState(true);
    const [showRegisterDirector, setShowRegisterDirector] = useState(true);



    useEffect(()=>{
        if(initialized){
            loadData();
        }
    },[axios, initialized]);


     async function loadData(page_, limit_, search_) {
        try {

            if (axios) {
                setLoading(true);
                 await axios.get(`${get_directors_url}/${registrationCode}?page=${page_ || page||''}&limit=${limit_ || limit}&search=${search_ || search ||''}`)
                    .then((response) => {
                        setLoading(false);
                        const resp = response.data;
                        const data_ = [];
                        const total = resp.total;
                        const pages = resp.pages;
                        const page = resp.page;
                        setPages(pages);
                        setTotal(total);
                        setDirectorCount(total);
                        resp ?.directors ?.forEach((director) => {
                            data_.push([
                                director.id,
                                director.designationTitle,
                                director.person.firstName,
                                director.person.middleName,
                                director.person.lastName,
                                new Date(director.person.dateOfBirth).toDateString(),
                                director.person.emailAddress,
                                director.person.phoneNumber,
                                director.person.gender
                            ])

                        });
                        setData(data_);
                    });
            }

        } catch (Error) {
            console.log(Error);

        }
    }

    function add(){
        setShowDirectorTable(false);
        setDisplaySearch(false);
        setShowRegisterDirector(false);
        setShowDirectorForm(true);
        
    }

    async function handleSearch(event){
        const page_ = 0;
        const limit_ = 5;
        const search_ = event.target.value;
        setLimit(limit_);
        setPage(page_);
        setSearch(search_);
        loadData(page_, limit_, search_);
    }

    return (
        <>
            <div className='searchStrip'>
                
                <div className='newAddress' onClick={add}>
                    {showRegisterDirector && <>
                        <span onClick={add} className="fa fa-user-plus" />
                        <span onClick={add}> &nbsp;Add Director</span></>
                    } 
                 </div>

                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}

                {displaySearch &&
                <div className='searchDiv'>
                    <span className='searchSpan'>
                        <label htmlFor="search">
                            <input id="search" type="text" onKeyUp={(e) => { setSearch(e.target.value); loadData(); }} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                        </label>
                    </span>
                </div>}

            </div>
            {showDirectorTable && <ResponsiveTable dataFetchFn={loadData} headers={headers} data={data} pagination={{ setPage: setPage, total: total, pages: pages, page: page }}></ResponsiveTable>}
            {showDirectorForm && <DirectorForm loadData={loadData} setShowDirectorTable={setShowDirectorTable} setShowRegisterDirector={setShowRegisterDirector} setDisplaySearch={setDisplaySearch} registrationCode={registrationCode} setShowDirectorForm={setShowDirectorForm}></DirectorForm>}
        </>
    )
}