import React from 'react';
import './business-dashboard-icons.css';
import CrobodDashIcon from '../../../../../../../common/crobod-dash-icon/crobod-dash-icon';

export default function BusinessDashboardIcons(props) {
    
    const setShowDashboardPanel = props.setShowDashboardPanel;
    const businessNamesCount = props.businessNamesCount;
    const registrationCode = props.registrationCode;
    const setShowAddressForm = props.setShowAddressForm;
    const setDisplayAddressTable = props.setDisplayAddressTable;
    const businessAddressCount = props.businessAddressCount;
    const setNavigation = props.setNavigation;
    const setShowDirectorForm = props.setShowDirectorForm;
    const setShowShareCapitalForm = props.setShowShareCapitalForm;
    const setShowShareHolderForm = props.setShowShareHolderForm;
    const setShowBusinessNames = props.setShowBusinessNames;
    const setShowDirectorTable = props.setShowDirectorTable;
    const setShowDirectorDash = props.setShowDirectorDash;
    const showDirectorDash = props.showDirectorDash;
    const directorCount=props.directorCount
    const setDirectorCount=props.setDirectorCount;
    const setShowShareCapitalTable = props.setShowShareCapitalTable;
    const setShowShareCapitalDash = props.setShowShareCapitalDash;
    const shareCapitalCount = props.shareCapitalCount;
    const shareHolderCount = props.shareHolderCount;


    function displayForm(fornToDisplay){

        
        setNavigation('business-address-form');
        if(fornToDisplay==='address-form'){
            
            setShowDashboardPanel(false);
            setShowAddressForm(false);
            setDisplayAddressTable(true);
            setShowAddressForm(false);

            //setShowDirectorForm(false);
            setShowShareHolderForm(false);
            setShowBusinessNames(false);
            setShowDirectorTable(false);
            setShowDirectorDash(false);
            setShowShareCapitalTable(false);
            setShowShareCapitalDash(false);
        }

        if(fornToDisplay==='director-form'){
            
            setShowDashboardPanel(false);
            setShowAddressForm(false);
            setDisplayAddressTable(false);
            setShowAddressForm(false);

            //setShowDirectorForm(false);
            setShowShareHolderForm(false);
            setShowBusinessNames(false);
            setShowDirectorTable(true);
            setShowDirectorDash(true);
            setShowShareCapitalTable(false);
            setShowShareCapitalDash(false);
        }

        if(fornToDisplay==='share-capital'){
            
            setShowDashboardPanel(false);
            setShowAddressForm(false);
            setDisplayAddressTable(false);
            setShowAddressForm(false);

            //setShowDirectorForm(false);
            setShowShareHolderForm(false);
            setShowBusinessNames(false);
            setShowDirectorTable(false);
            setShowDirectorDash(false);
            setShowShareCapitalDash(true);
            setShowShareCapitalTable(true);
        }

        if(fornToDisplay==='shareholders'){
            setShowDashboardPanel(false);
            setShowAddressForm(false);
            setDisplayAddressTable(false);
            setShowAddressForm(false);

            //setShowDirectorForm(false);
            setShowShareHolderForm(true);
            setShowBusinessNames(false);
            setShowDirectorTable(false);
            setShowDirectorDash(false);
            setShowShareCapitalTable(false);
            setShowShareCapitalDash(false);
        }

        if(fornToDisplay==='business-names'){
            setShowDashboardPanel(false);
            setShowAddressForm(false);
            setDisplayAddressTable(false);
            setShowAddressForm(false);

            //setShowDirectorForm(false);
            setShowShareHolderForm(false);
            setShowBusinessNames(true);
            setShowDirectorTable(false);
            setShowDirectorDash(false);
            setShowShareCapitalTable(false);
            setShowShareCapitalDash(false);
        }
    }
    
    return (
        <>
            <div className="row">
                <CrobodDashIcon label="Address" iconClassName="fa fa-address-card-o dashIcon" callForAttention={(businessAddressCount ? businessAddressCount<=0 : true)} action={() => { displayForm('address-form') }} ></CrobodDashIcon>
                <CrobodDashIcon label="Directors" iconClassName="fa fa-briefcase dashIcon" callForAttention={(directorCount ? directorCount<=0 : true)} action={() => { displayForm('director-form') }} ></CrobodDashIcon>
            </div>
            <div className="row">
                <CrobodDashIcon label="Share Capital" iconClassName="fa fa-pie-chart dashIcon" callForAttention={(shareCapitalCount? shareCapitalCount<=0 : true)} action={() => { displayForm('share-capital') }} ></CrobodDashIcon>
                <CrobodDashIcon label="Shareholders" iconClassName="fa fa-users dashIcon" callForAttention={(shareHolderCount? shareHolderCount<=0 : true)} action={() => { displayForm('shareholders') }} ></CrobodDashIcon>
            </div>
            <div className="row">
                <CrobodDashIcon label="Business Names" iconClassName="fa fa-list-ol dashIcon" callForAttention={(businessNamesCount? businessNamesCount<=0 : true )} action={() => { displayForm('business-names') }} ></CrobodDashIcon>
            </div>
        </>
    );
}