import React from 'react'
import checklist from '../../assets/checklist.png'
import './checklist.css'

const Checklist = () => {
  return (
    <div className='checklist'>
        <div className="checklist-content">
            <h3>Checklists</h3>
            <img src={checklist} alt="Checklist" />
            <p>To have your application processed fast and have a good chance of getting up to your request, get these ready:</p>
            <ul>
                <li>Register on the crobod platform</li>
                <li>Have a validly registered business and a valid business permit</li>
                <li>Demonstrate that you are either importing or exporting</li>
                <li>At least 6 months of bank statement</li>
                <li>For Prime subscribers, ensure you have your suppliers payment details, and your oickup and delivery details for us to help you end-to-end</li>
            </ul>
            <p>Please note that having a business name registration for your business will offer you a smaller amount as against if your company is registered as a Limited Liability Company.
                 We have partners that can get your business upgraded to Limited Liability here now.
            </p>
        </div>
    </div>
  )
}

export default Checklist