import React, { useState, useEffect } from 'react';
import './virtual-wallet.css';
import VirtualWalletForm from './virtual-wallet-form/virtual-wallet-form';
import useAxios from '../../../../../hooks/axios';
import ResponsiveTable from '../../../../../common/responsive-table/responsive-table';
import ReactLoading from "react-loading";
import validator from 'validator';
import { setUserId } from '../../../../../redux/actions/setUserId';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../../../../hooks/auth-hook'

const get_virtual_wallet_url = `${process.env.API_BASE_URL}/virtual-wallet`;


export default function VirtualWallet(props) {

    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [displayTable, setDisplayTable] = useState(true);
    const [displayForm, setDisplayForm] = useState(false);
    const { axios, initialized, token } = useAxios.useAxios({});
    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState(['#', 'Name', 'Code', 'Date Created', 'Available Bal.', 'Actual Bal.','Status']);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const userId = useSelector((state) => state.userId);
    const { user, roles } = useAuth();
    const dispatch = useDispatch();




    useEffect(() => {
        if (initialized) {
            
        }
        if (user.id) {
            dispatch(setUserId(user.id));
            load(page, limit, search, user.id);
        }
    }, [initialized, axios, user]);


    function load(page_, limit_, search_, userId_) {

        setLoading(true);
        fetchData(page_, limit_, search_, userId_).then((resp) => {

            setLoading(false);


            const virtualWallets = resp.virtualWallets;
            const total = resp.total;
            const pages = resp.pages;
            const page = resp.page;
            setPages(pages);
            setTotal(total);

            if (virtualWallets) {
                const data_ = [];
                virtualWallets.forEach((virtualWallet) => {
                    data_.push(
                        [
                            virtualWallet.id,
                            virtualWallet.name,
                            virtualWallet.code,
                            new Date(virtualWallet.createdAt).toDateString(),
                            virtualWallet.availableBalance,
                            virtualWallet.actualBalance,
                            virtualWallet.status
                        ]);
                });
                setData(data_);
            }

        });

    }

    const sanitize = (input) => {
        return validator.escape(String(input));
    };

    function fetchData(page_, limit_, search_, userId_) {

        return new Promise((resolve, reject) => {
            try {

                if (axios) {
                    axios.get(`${get_virtual_wallet_url}?page=${page_ || page}&limit=${limit_ || limit}&userId=${sanitize(userId_||userId||user.id)}&search=${sanitize(search_ || search)}`).then((resp) => {
                        resolve(resp.data);
                    });
                }

            } catch (Error) {
                console.error(Error);
            }
        });

    }
    function add() {
        setDisplayTable(false);
        setDisplayForm(true);
    }

    const handleSearch = (event) => {
        const page_ = 0;
        const limit_ = 5;
        const search_ = event.target.value;
        setLimit(limit_);
        setPage(page_);
        setSearch(search_);
        load(page_, limit_, search_);
    };

    return (
        <div>
            <div className='searchStrip'>
                <div className='newAddress'>
                    <span className="fa fa-plus-square-o" />
                    <span onClick={add}> &nbsp;Add Virtual Wallet</span>
                </div>

                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}

                <div className='searchDiv'>
                    <span className='searchSpan'>
                        <label htmlFor="search">
                            <input id="search" type="text" value={search} onKeyUp={(e) => { setSearch(e.target.value); load(); }} onChange={handleSearch} className='searchInput' placeholder='Type to search...'></input>
                        </label>
                    </span>
                </div>

            </div>
            <div className='appTable'>
                {displayTable && <ResponsiveTable dataFetchFn={load} headers={headers} data={data} pagination={{ setPage: setPage, total: total, pages: pages, page: page }}></ResponsiveTable>}
                {displayForm && <VirtualWalletForm user={user} setDisplayForm={setDisplayForm} setDisplayTable={setDisplayTable}></VirtualWalletForm>}
            </div>
        </div>
    )
}