import { createSlice } from '@reduxjs/toolkit'
const defaultval_ ={ 
    names: '',
    businessOrCompanyName: '',
    emailAddress: '',
    requestedLoanAmount: '',
    purposeOfLoan: '',
    phoneNumber: '',
    businessRegistrationCountry: '',
    businessIsRegistered: '',
    termsAndConditionsAccepted: '',
    otp: '',
  };
  export const startTodayFormData = createSlice({
    name: 'startTodayFormData',
    initialState: {
      value: defaultval_,
    },
    reducers: {
      setNames:(state,action) => {
        state.value.names = action.payload;
      },
      setBusinessOrCompanyName:(state,action) => {
        state.value.businessOrCompanyName = action.payload;
      },
      setEmailAddress:(state,action) => {
        state.value.emailAddress = action.payload;
      },
      setRequestedLoanAmount:(state,action) => {
        state.value.requestedLoanAmount = action.payload;
      },
      setPurposeOfLoan:(state,action) => {
        state.value.purposeOfLoan = action.payload;
      },
      setPhoneNumber:(state,action) => {
        state.value.phoneNumber = action.payload;
      },
      setBusinessRegistrationCountry:(state,action) => {
        state.value.businessRegistrationCountry = action.payload;
      },
      setBusinessIsRegistered:(state,action) => {
        state.value.businessIsRegistered = action.payload;
      },
      setTermsAndConditionsAccepted:(state,action) => {
        state.value.termsAndConditionsAccepted = action.payload;
      },
      setOtp:(state,action) => {
        state.value.otp = action.payload;
      },
      
      clear: (state) => {
        state.value = defaultval_
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setNames,setBusinessOrCompanyName,setEmailAddress,setRequestedLoanAmount,setPurposeOfLoan,setPhoneNumber,setBusinessRegistrationCountry,setBusinessIsRegistered, setTermsAndConditionsAccepted, setOtp, clear} = startTodayFormData.actions
  
  export default startTodayFormData.reducer