import React, { useState, useEffect } from 'react';
import './share-holder-form.css';
import { useAuth } from '../../../../../../../hooks/auth-hook';
import useAxios from '../../../../../../../hooks/axios';
import { NumericFormat } from 'react-number-format';
import ReactLoading from "react-loading";
import AsyncSelect from 'react-select/async';
import moment from 'moment';

const country_url = `${process.env.API_BASE_URL}/v1/country`;
const save_share_holder_url = `${process.env.API_BASE_URL}/share-holder`;
const get_share_holder_url = `${process.env.API_BASE_URL}/share-holder`;
const search_country_url = `${process.env.API_BASE_URL}/v1/country/search/`;
const search_person_url = `${process.env.API_BASE_URL}/person`;

export default function ShareHolderForm(props) {

    const registrationCode = props.registrationCode;

    const setShowShareHolderForm = props.setShowShareHolderForm;
    const setShowShareHolderTable = props.setShowShareHolderTable;

    const setShowShareCapitalForm = props.setShowShareCapitalForm;
    const setShowShareCapitalTable = props.setShowShareCapitalTable;
    const [shareCapitalValue, setShareCapitalValue] = useState('');
    const [hasForeignerAsShareHolder, setHasForeignerAsShareHolder] = useState('');
    const [companyStructure, setCompanyStructure] = useState('');
    const [companyStructureDescription, setCompanyStructureDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [allFieldsValid, setAllFieldsValid] = useState(false);
    const [shareCapitalValueError, setShareCapitalValueError] = useState('* Required');
    const [hasForeignerAsShareHolderError, setHasForeignerAsShareHolderError] = useState('* Required');
    const [companyStructureError, setCompanyStructureError] = useState('* Required');
    const [companyStructureDescriptionError, setCompanyStructureDescriptionError] = useState('');
    const [shareCapitalFormattedValue, setShareCapitalFormattedValue] = useState();
    const setShowAddShareCapital = props.setShowAddShareCapital;

    const { user, roles } = useAuth();
    const { axios, initialized, token } = useAxios.useAxios({});

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);//Number of items per page
    const [search, setSearch] = useState();
    const [typedPersonSearchTerm, setTtypedPersonSearchTerm] = useState();
    const [selectedPerson, setSelectedPerson] = useState();

    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [title, setTitle] = useState();
    const [dateOfBirth, setDateOfBirth] = useState();
    const [genderError, setGenderError] = useState('* Required');
    const [gender, setGender] = useState();
    const [titleError, setTitleError] = useState('* Required');
    const [phoneNumberError, setPhoneNumberError] = useState('* Required');
    const [phoneNumber, setPhoneNumber] = useState();
    const [selectedCountry, setSelectedCountry] = useState();
    const [options, setOptions] = useState();
    const [countrySelectError , setCountrySelectError] = useState('* Required');
    const [value, setValue] = useState();
    const [countries, setCountries] = useState();
    const [countriesDisabled, setCountriesDisabled] = useState(false);
    const [meansOfIdentificationError, setMeansOfIdentificationError] = useState('* Required');
    const [meansOfIdentification, setMeansOfIdentification] = useState();

    const [identificationNumberError, setIdentificationNumberError] = useState('* Required');
    const [identificationNumber, setIdentificationNumber] = useState();

    const [identificationDocumentUrlError, setIdentificationDocumentUrlError] = useState('');
    const [identificationDocumentUrl, setIdentificationDocumentUrl] = useState('');

    const [signatureUrlError, setSignatureUrlError] = useState('');
    const [signatureUrl, setSignatureUrl] = useState('');
    const [allottedSharesError, setallottedSharesError] = useState('* Required');
    const [allottedShares, setallottedShares] = useState(0);
    const [ classOfSharesError, setClassOfSharesError] = useState('* Required');
    const [ classOfShares, setClassOfShares] = useState();
    const [firstNameError, setFirstNameError] = useState('* Required');
    const [lastNameError, setLastNameError] = useState('* Required');
    const [emailAddressError, setEmailAddressError] = useState('* Required');
    const [dateOfBirthError, setDateOfBirthError] = useState('* Required');


    const validateEmailAddress = (emailAddress) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(emailAddress).toLowerCase());
    };

    async function handlePersonInputChange(value) {
        if (value) {
            setTtypedPersonSearchTerm(value);
            setSearch(value);
        }
    }


    const handleNationalityChange = async value => {
        setValue(value);
        await validateAllFields();
    }

    const handleChange = async value => {
        setSelectedCountry(value);
        await validateAllFields();
        if (value) {
            setCountrySelectError('');
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            setSelectedCountry(null);
            getCountries().then((countries) => {
                setCountries(countries);
                setOptions(countries);
            })
        }
    };

    async function validateAllFields() {
        const allottedSharesInput = document.getElementById('allottedShares');
        const allottedSharesInputValue = allottedSharesInput.value;
        const allottedSharesValid = allottedSharesInputValue && allottedSharesInputValue>0;
        setallottedSharesError(allottedSharesValid ? '' : '* Required');
        
        const classOfSharesInput = document.getElementById('classOfShares');
        const classOfSharesInputValue = classOfSharesInput.value;
        const classOfSharesValid = classOfSharesInputValue && classOfSharesInputValue!=='--';
        setClassOfSharesError(classOfSharesValid ? '' : '* Required');
        
        const titleInput = document.getElementById('title');
        const titleInputValue = titleInput.value;
        const titleValid = titleInputValue && titleInputValue!=='--';
        setTitleError(titleValid ? '' : '* Required');


        const firstNameInput = document.getElementById('firstName');
        const firstNameInputValue = firstNameInput.value;
        const firstNameValid = firstNameInputValue && firstNameInputValue!=='';
        setFirstNameError(firstNameValid ? '' : '* Required');


        const lastNameInput = document.getElementById('lastName');
        const lastNameInputValue = lastNameInput.value;
        const lastNameValid = lastNameInputValue && lastNameInputValue!=='';
        setLastNameError(lastNameValid ? '' : '* Required');


        const emailAddressInput = document.getElementById('emailAddress');
        const emailAddressInputValue = emailAddressInput.value;
        const emailAddressValid = emailAddressInputValue && emailAddressInputValue!=='';
        const emailFormatValid = validateEmailAddress(emailAddressInputValue);
        setEmailAddressError(emailAddressValid ? 
            (emailFormatValid
            ? '' : '* Email address invalid') 
            : '* Required');
       
        const dateOfBirthInput = document.getElementById('dateOfBirth');
        const dateOfBirthInputValue = dateOfBirthInput.value;
        const dateOfBirthValid = dateOfBirthInputValue && dateOfBirthInputValue!=='';
        setDateOfBirthError(dateOfBirthValid ? '' : '* Required');


        const genderInput = document.getElementById('gender');
        const genderInputValue = genderInput.value;
        const genderValid = genderInputValue && genderInputValue!=='--';
        setGenderError(genderValid ? '' : '* Required');


        const phoneNumberInput = document.getElementById('phoneNumber');
        const phoneNumberInputValue = phoneNumberInput.value;
        const phoneNumberValid = phoneNumberInputValue && phoneNumberInputValue!=='';
        setPhoneNumberError(phoneNumberValid ? '' : '* Required');


        const meansOfIdentificationInput = document.getElementById('meansOfIdentification');
        const meansOfIdentificationInputValue = meansOfIdentificationInput.value;
        const meansOfIdentificationValueValid = meansOfIdentificationInputValue && meansOfIdentificationInputValue!=='--';
        setMeansOfIdentificationError(meansOfIdentificationValueValid ? '' : '* Required');


        const identificationNumberInput = document.getElementById('identificationNumber');
        const identificationNumberInputValue = identificationNumberInput.value;
        const identificationNumberValueValid = identificationNumberInputValue && identificationNumberInputValue!=='';
        setIdentificationNumberError(identificationNumberValueValid ? '' : '* Required');


        const nationalityInputValueValid = selectedCountry && selectedCountry.id;
        setCountrySelectError(nationalityInputValueValid ? '' : '* Required');

        
        
        const allFieldsValid  = nationalityInputValueValid && identificationNumberValueValid && meansOfIdentificationValueValid && phoneNumberValid && genderValid && dateOfBirthValid && emailFormatValid && emailAddressValid && lastNameValid && firstNameValid && titleValid && allottedSharesValid && classOfSharesValid;
        setAllFieldsValid(allFieldsValid);
        return allFieldsValid;
    }


    async function handlePersonChange(value) {
        
        setSelectedPerson(value);
        const firstNameInput = document.getElementById('firstName');
        const middleNameInput = document.getElementById('middleName');
        const lastNameInput = document.getElementById('lastName');
        const emailAddressInput = document.getElementById('emailAddress');
        const titleInput = document.getElementById('title');
        const dateOfBirthInput = document.getElementById('dateOfBirth');
        const genderInput = document.getElementById('gender');
        const phoneNumberInput = document.getElementById('phoneNumber');
        const nationalityInput  = document.getElementById('nationality');
        const meansOfIdentificationInput = document.getElementById('meansOfIdentification');
        const identificationNumberInput = document.getElementById('identificationNumber');
        const identificationDocumentUrlInput  = document.getElementById('identificationDocumentUrl');
        const signatureUrlInput  = document.getElementById('signatureUrl');
        const allottedSharesInput = document.getElementById('allottedShares');
        const classOfSharesInput = document.getElementById('classOfShares');

        if (value) {
            firstNameInput.disabled = true;
            middleNameInput.disabled = true;
            lastNameInput.disabled = true;
            emailAddressInput.disabled = true;
            titleInput.disabled = true;
            dateOfBirthInput.disabled = true;
            genderInput.disabled = true;
            phoneNumberInput.disabled = true;
            nationalityInput.isDisabled = true;
            setCountriesDisabled(true);
            meansOfIdentificationInput.disabled = true;
            identificationNumberInput.disabled = true;
            identificationDocumentUrlInput.disabled = true;
            signatureUrlInput.disabled = true;
            titleInput.value = value.title;
            firstNameInput.value = value.firstName;
            middleNameInput.value = value.middleName;
            lastNameInput.value = value.lastName;
            emailAddressInput.value = value.emailAddress;
            dateOfBirthInput.value = moment(value.dateOfBirth).format('YYYY-MM-DD');
            genderInput.value = value.gender;
            phoneNumberInput.value = value.phoneNumber;
            setSelectedCountry(value.nationality);
            setValue(value.nationality);
            meansOfIdentificationInput.value = value.meansOfIdentification;
            identificationNumberInput.value = value.identificationNumber;
            identificationDocumentUrlInput.value = value.identificationDocumentUrl;
            signatureUrlInput.value = value.signatureUrl;
            classOfSharesInput.value = '--';
        } else {
            firstNameInput.value = '';
            middleNameInput.value = '';
            lastNameInput.value = '';
            emailAddressInput.value = '';
            titleInput.value = '--';
            dateOfBirthInput.value = '';
            genderInput.value = '--';
            phoneNumberInput.value = '';
            nationalityInput.value = '';
            setSelectedCountry(null);
            setValue(null);
            meansOfIdentificationInput.value = '--';
            identificationNumberInput.value = '';
            identificationDocumentUrlInput.value = '';
            allottedSharesInput.value = 0;
            signatureUrlInput.value = '';
            classOfSharesInput.value = '--';
            firstNameInput.disabled = false;
            middleNameInput.disabled = false;
            lastNameInput.disabled = false;
            emailAddressInput.disabled = false;
            titleInput.disabled = false;
            dateOfBirthInput.disabled = false;
            genderInput.disabled = false;
            phoneNumberInput.disabled = false;
            nationalityInput.isDisabled = false;
            setCountriesDisabled(false);
            meansOfIdentificationInput.disabled = false;
            identificationNumberInput.disabled = false;
            identificationDocumentUrlInput.disabled = false;
            signatureUrlInput.disabled = false;
            allottedSharesInput.disabled  = false;
        }

        validateAllFields();
    }

    const onOptionChange = e => {
        setHasForeignerAsShareHolder(e.target.value);
        setHasForeignerAsShareHolderError('');
        validateAllFields();
    }

    function clearForm(event) {
        setShowShareHolderForm(false);
        setShowShareHolderTable(true);
    }

    function hideSaveStatus() {
        setShowShareHolderForm(false);
        setShowShareHolderTable(true);
        const saveStatusSpan = document.getElementById('saveStatusSpan');
        saveStatusSpan.style.display = 'none';
    }

    async function post(e) {
        e.preventDefault();
        const data = {
            registrationCode: registrationCode,
            person: selectedPerson.id,
            allottedShares: allottedShares,
            classOfShares: classOfShares,
            title: title,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            emailAddress: emailAddress,
            dateOfBirth: dateOfBirth,
            gender: gender,
            phoneNumber: phoneNumber,
            nationality: selectedCountry.id,
            meansOfIdentification: meansOfIdentification,
            identificationNumber: identificationNumber,
            identificationDocumentUrl: identificationDocumentUrl,
            signatureUrl : signatureUrl
        }

        setLoading(true);
        await axios.post(`${save_share_holder_url}`, data).then((response) => {
            setLoading(false);
            const saveStatusSpan = document.getElementById('saveStatusSpan');
            saveStatusSpan.style.display = 'flex';
            setTimeout(hideSaveStatus, 500);
            //loadData();
        })

    }



    


    


    const searchPerson = (page_, limit_, search_) =>
        new Promise((resolve, reject) => {

            let fetchUrl = typedPersonSearchTerm ? `${search_person_url}/${registrationCode}?page=${page_ || page || ''}&limit=${limit_ || limit}&search=${search_ || search || typedPersonSearchTerm || ''}` : `${search_person_url}/${registrationCode}`;

            if (axios) {
                axios.get(fetchUrl)
                    .then((result) => {
                        const persons = result.data.persons;
                        setLoading(false);
                        resolve(persons);
                    });
            } else {
                setLoading(false);
            }

        });



    const getCountries = (inputValue) => new Promise(async (resolve, reject) => {

        try {

            if (inputValue) {
                const search_url = `${search_country_url}${inputValue}`;
                await axios.get(search_url).then(result => {
                    const res = result.data;
                    setOptions(res);
                    resolve(res);
                }).then((resss) => {

                    if (resss) {
                        resolve(resss);
                    } else {
                    }
                });

            } else {
                await axios.get(country_url).then(result => {
                    const countries__ = result.data;
                    setOptions(countries__);
                    return countries__;
                });
                setCountries(countries);
                resolve(countries);
            }
        } catch (e) {
            reject(e);
        }

    });

    function getHighlightedText(text, highlight) {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', backgroundColor: '#f9fd8e' } : {} }>
                { part }
            </span>)
        } </span>;
    }

    return (
        <div>


            <div className="formFieldInput">
                <label htmlFor="personSelect">
                    Search Person by name, phone number or email address
                </label>
                <AsyncSelect
                    id="personSelect"
                    isClearable
                    isSearchable
                    isLoading={loading}
                    placeholder='Type to search person'
                    defaultOptions
                    value={selectedPerson}
                    getOptionLabel={e => { return getHighlightedText(`${e.firstName} ${e.middleName} ${e.lastName} ${e.emailAddress} ${e.phoneNumber}`, typedPersonSearchTerm) } }
                    getOptionValue={e => e.id}
                    loadOptions={(e) => { return searchPerson(page, 100, e) }}
                    onInputChange={handlePersonInputChange}
                    onChange={handlePersonChange} />
            </div>



            <div className="formFieldInput">
                <label className="formLabels" htmlFor="allottedShares">
                    Alloted Shares
                    </label>
                <input id="allottedShares" className="formInputs" onChange={(e) => { setallottedShares(e.target.value); validateAllFields();  }} name='allottedShares' type='number'></input>
                {allottedSharesError && <span className="formError" id="allottedSharesError">{allottedSharesError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="classOfShares">
                    Share Class
                    </label>
                <select  id="classOfShares" className="formInputs" onChange={(e) => {  setClassOfShares(e.target.value); validateAllFields();  }}  name='classOfShares'>
                    <option value="--">-- Select Share Class--</option>
                    {[{ value: "Ordinary", name: "Ordinary" }, { value: "Preferred", name: "Preferred" }].map((classOfShares) => (
                        <option key={classOfShares.value} value={classOfShares.value}>{classOfShares.name}</option>
                    ))}
                </select>
                {classOfSharesError && <span className="formError" id="classOfSharesError">{classOfSharesError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="title">
                    Title
                    </label>
                <select value={title} id="title" className="formInputs" onChange={(e) => { setTitle(e.target.value); validateAllFields(); }} name='title'>
                    <option value="--">-- Select Title--</option>
                    {[{ value: "Mr.", name: "Mr." }, { value: "Mrs.", name: "Mrs." }, { value: "Miss.", name: "Miss." }].map((classOfShares) => (
                        <option key={classOfShares.value} value={classOfShares.value}>{classOfShares.name}</option>
                    ))}
                </select>
                {titleError && <span className="formError" id="titleError">{titleError}</span>}

            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="firstName">
                    First Name
                </label>
                <input onChange={(e) => { setFirstName(e.target.value); validateAllFields(); }} className="formInputs" id="firstName"></input>
                {firstNameError && <span className="formError" id="firstNameError">{firstNameError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="middleName">
                    Middle Name
                </label>
                <input onChange={(e) => { setMiddleName(e.target.value); validateAllFields(); }} className="formInputs" id="middleName"></input>
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="lastName">
                    Last Name
                </label>
                <input onChange={(e) => { setLastName(e.target.value); validateAllFields(); }} className="formInputs" id="lastName"></input>
                {lastNameError && <span className="formError" id="lastNameError">{lastNameError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="emailAddress">
                    Email Address
                </label>
                <input type="email" onChange={(e) => { setEmailAddress(e.target.value); validateAllFields();}} className="formInputs" id="emailAddress"></input>
                {emailAddressError && <span className="formError" id="emailAddressError">{emailAddressError}</span>}        
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="dateOfBirth">
                    Date of Birth
                </label>
                <input max={moment(new Date()).format('YYYY-MM-DD')} type="date" onChange={(e) => { setDateOfBirth(e.target.value); validateAllFields(); }} className="formInputs" id="dateOfBirth"></input>
                {dateOfBirthError && <span className="formError" id="dateOfBirthError">{dateOfBirthError}</span>}
            </div>


            <div className="formFieldInput">
                <label className="formLabels" htmlFor="gender">
                    Gender
                    </label>
                <select id="gender" className="formInputs" onChange={(e) => { setGender(e.target.value); validateAllFields(); }} name='gender'>
                    <option value="--">-- Select Gender--</option>
                    {[{ value: "Male", name: "Male" }, { value: "Female", name: "Female" }, { value: "Other", name: "Other" }].map((classOfShares) => (
                        <option key={classOfShares.value + "x"} value={classOfShares.value}>{classOfShares.name}</option>
                    ))}
                </select>
                {genderError && <span className="formError" id="genderError">{genderError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="phoneNumber">
                    Phone Number
                    </label>
                <input id="phoneNumber" className="formInputs" onChange={(e) => { setPhoneNumber(e.target.value); validateAllFields(); }} name='phoneNumber'></input>
                {phoneNumberError && <span className="formError" id="phoneNumberError">{phoneNumberError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="nationality">
                    Nationality (Country of origin)
                    </label>
                <AsyncSelect
                    id="nationality"
                    cacheOptions
                    defaultOptions
                    placeholder='Start typing country name'
                    value={selectedCountry}
                    options={options}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    loadOptions={getCountries}
                    onInputChange={handleNationalityChange}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    isDisabled={countriesDisabled} />
                {countrySelectError && <span className="formError" id="countrySelectError">{countrySelectError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="meansOfIdentification">
                    Means of Identification
                    </label>
                <select id="meansOfIdentification" className="formInputs" onChange={(e) => { setMeansOfIdentification(e.target.value); validateAllFields();  }}>
                    <option key="xf" value="--">-- Select ID Means --</option>
                    {[{ value: 'Passport', name: 'Passport' }, { value: 'National Id', name: 'National Id' }, { value: 'Party Number', name: 'Party Number' }].map((means) => (
                        <option key={means.value} value={means.value}>{means.name}</option>
                    ))}
                </select>
                {meansOfIdentificationError && <span className="formError" id="meansOfIdentificationError">{meansOfIdentificationError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="identificationNumber">
                    Identification Number
                    </label>
                <input id="identificationNumber" className="formInputs" onChange={(e) => { setIdentificationNumber(e.target.value); validateAllFields(); }} name='identificationNumber'></input>
                {identificationNumberError && <span className="formError" id="identificationNumberError">{identificationNumberError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="identificationDocumentUrl">
                    Identification Document
                    </label>
                <input id="identificationDocumentUrl" className="formInputs" onChange={(e) => { setIdentificationDocumentUrl(e.target.value); validateAllFields(); }} name='identificationDocument'></input>
                {identificationDocumentUrlError && <span className="formError" id="identificationDocumentUrlError">{identificationDocumentUrlError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor={signatureUrl}>
                    Signature
                    </label>
                    <input  id="signatureUrl"  className="formInputs" onChange={(e) => {  setSignatureUrl(e.target.value); validateAllFields();  }}  name='signature'></input>
                {signatureUrlError && <span className="formError" id="signatureUrlError">{signatureUrlError}</span>}
            </div>

            <div className="formFieldInput btns">
                <button className="cancelBtn" onClick={(e) => { return clearForm(e) }}>Cancel</button>
                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                <span id="saveStatusSpan" className="saveStatusSpan">Saved.</span>
                <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
            </div>
        </div>
    )
}