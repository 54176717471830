import React from 'react'
import './header.css'
import Form from '../../components/Form/Form.js'
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const navigate = useNavigate();


    function signUp() {
        navigate('signup');
    }
    return (
        <div className='header section__padding'>
            <div className="header-content">
                <h2>Instant loans for African Importers & Exporters</h2>
                <p>Import & export financing tech platform designed for African small and medium
                    merchants to help with financing their international purchases and cross border logistics.
            </p>
                <button type="button" onClick={signUp}>Get Started</button>
            </div>
            <div className="header-form">
                <Form />
            </div>

        </div>
    )
}

export default Header;