import React,{useState, useEffect} from 'react';
import './virtual-wallet-form.css';
import useAxios from '../../../../../../hooks/axios';
import ReactLoading from "react-loading";

const virtual_wallet_post_url = `${process.env.API_BASE_URL}/virtual-wallet`;
const generate_wallet_code_url = `${process.env.API_BASE_URL}/virtual-wallet/code`;

export default function VirtualWalletForm(props) {

    const setDisplayForm = props.setDisplayForm;
    const setDisplayTable = props.setDisplayTable;
    const user = props.user;

    const [walletNameError, setWalletNameError] = useState('* Required');
    const [walletName, setWalletName] = useState('');
    const [loading, setLoading] = useState(false);
    const [allFieldsValid, setAllFieldsValid] = useState();
    const { axios, initialized, token } = useAxios.useAxios({});
    const [options, setOptions] = useState([]);
    const [countries, setCountries] = useState([{ value: '-1', label: 'Select Countries' }]);
    const [countrySelectError, setCountrySelectError] = useState('* Required');
    const [codeError, setCodeError] = useState('* Required initial');
    const [statusError, setStatusError] = useState('* Required');
    const [status, setStatus] = useState('');
    const [deliveryPartnerTypeError, setDeliveryPartnerTypeError] = useState('* Required');
    const [deliveryPartnerType, setDeliveryPartnerType] = useState();
    const [availableBalance, setAvailableBalance] = useState(0.00);
    const [availableBalanceError, setAvailableBalanceError] = useState('* Required');
    const [actualBalanceError, setActualBalanceError] = useState('* Required');

    const [selectedCity, setSelectedCity] = useState();
    const [actualBalance, setActualBalance] = useState(0.00);
    const [citySelectError, setCitySelectError] = useState('* Required');
    const [code, setCode] = useState();
    

    useEffect(() => {
        setWalletName(`${user.firstName} ${user.lastName}`);
        if(axios){
            generateCode();
        }
        validateAllFields();
    }, [axios]);


    async function generateCode(){
       axios.get(generate_wallet_code_url)
       .then((response)=>{
           setCode(  response.data );
           document.getElementById('code').value = response.data;
           validateAllFields();
       });
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
        validateAllFields();
    };

    async function validateAllFields() {
        const walletNameInput = document.getElementById('walletName');
        const walletNameValue = walletNameInput.value;
        const walletNameValid = walletNameValue && walletNameValue!='';
        
        if(walletNameValid){
            setWalletNameError('');
        }else{
            setWalletNameError('* Required');
        }

        const codeInput = document.getElementById('code');
        const codeValue = codeInput.value;
        const codeValid = codeValue && codeValue!='';
        
        if(codeValid){
            setCodeError('');
        }else{
            setCodeError('* Required');
        }



        const availableBalanceInput = document.getElementById('availableBalance');
        const availableBalanceValue = availableBalanceInput.value;
        const availableBalanceValid = availableBalanceValue && availableBalanceValue!='';
        
        if(availableBalanceValid){
            setAvailableBalanceError('');
        }else{
            setAvailableBalanceError('* Required');
        }


        const actualBalanceInput = document.getElementById('actualBalance');
        const actualBalanceValue = actualBalanceInput.value;
        const actualBalanceValid = actualBalanceValue && actualBalanceValue!='';
        
        if(actualBalanceValid){
            setActualBalanceError('');
        }else{
            setActualBalanceError('* Required');
        }


        const statusSelect = document.getElementById('status');
        const statusValue = statusSelect.value;
        const statusValid = statusValue && statusValue!='' && statusValue!='--';
        
        if(statusValid){
            setStatusError('');
        }else{
            setStatusError('* Required');
        }

        const allFieldsValid = walletNameValid && codeValid && availableBalanceValid && actualBalanceValid && statusValid;
        
        setAllFieldsValid(allFieldsValid);
        
        return allFieldsValid;

    }

    async function clearForm(event) {
        event.preventDefault();
        setDisplayForm(false);
        setDisplayTable(true);
    }

    const handleInputChange = async value => {
        await validateAllFields();
    };

    function hideSaveStatus() {
        const saveStatusSpan = document.getElementById('saveStatusSpan');
        saveStatusSpan.style.display = 'none';
        setDisplayForm(false);
        setDisplayTable(true);
    }


    async function post(event) {

        event.preventDefault();
        const allFieldsValid = validateAllFields();

        if (allFieldsValid) {

            const data = {
                name: walletName,
                code: code,
                userId: user.id,
                availableBalance: availableBalance,
                actualBalance: actualBalance,
                status: status
            }

            setLoading(true);
            axios.post(virtual_wallet_post_url, data).then((response) => {
                setLoading(false);
                if (response.status >= 200 && response.status <= 210) {
                    const saveStatusSpan = document.getElementById('saveStatusSpan');
                    saveStatusSpan.style.display = 'flex';
                    setTimeout(hideSaveStatus, 1000);
                }
            });


        }
    }

    return (
        <div className="shipperForm">

            <div className="shipperFormHeader">Virtal Wallet</div>
            <div className="formFieldInput">
                <label className="formLabels" htmlFor="walletName">Name</label>
                <input value={walletName} id="walletName" className="formInputs" onChange={async (e) => { setWalletName(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {walletNameError && <span className="formError" id="walletNameError">{walletNameError}</span>}
            </div>
            
            <div className="formFieldInput">
                <label className="formLabels" htmlFor="code">Code</label>
                <input  id="code" type="text" className="formInputs" onChange={async (e) => { setCode(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {codeError && <span className="formError" id="codeError">{codeError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="availableBalance">Available Balance</label>
                <input value={availableBalance} id="availableBalance" type="number" className="formInputs" onChange={async (e) => { setAvailableBalance(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {availableBalanceError && <span className="formError" id="availableBalanceError">{availableBalanceError}</span>}
            </div>


            <div className="formFieldInput">
                <label className="formLabels" htmlFor="actualBalance">Actual Balance</label>
                <input value={actualBalance} id="actualBalance" type="number" className="formInputs" onChange={async (e) => { setActualBalance(e.target.value); await validateAllFields(); }} placeholder=''></input>
                {actualBalanceError && <span className="formError" id="availableBalanceError">{actualBalanceError}</span>}
            </div>

            <div className="formFieldInput">
                <label className="formLabels" htmlFor="status">Status</label>
                <select value={status} className="formInputs" id="status" onChange={handleStatusChange} >
                    <option key="0" value="--" >--Select--</option>
                    <option key="1" value="Active" defaultValue>Active</option>
                    <option key="2" value="Inactive">Inactive</option>
                </select>
                {statusError && <span className="formError" id="statusError">{statusError}</span>}
            </div>
            <div className="formFieldInput btns">
                <button className="cancelBtn" onClick={(e) => { return clearForm(e) }}>Cancel</button>
                {loading &&
                    <ReactLoading className="getStartedloaderEl" type='cylon' color="rgb(191 217 216)" />}
                <span id="saveStatusSpan" className="saveStatusSpan">Saved.</span>
                <button disabled={!allFieldsValid} className="saveBtn" onClick={(e) => { return post(e) }}>Save</button>
            </div>

        </div>
    );
}