import React from 'react';
import styles from './file-upload-form.module.css'
import { useState } from 'react';
import useAxios from '../../hooks/axios';
var url = `${process.env.API_BASE_URL}/v1/files/upload`;
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import DOMPurify from 'dompurify';


export default function FileUploadForm(props) {

    //const head = document.querySelector('head');
    //var script = document.createElement("script");
    //script.setAttribute('src','file-upload-form/file-upload.js');
    //script.setAttribute('type','text/javascript');
    //head.appendChild(script);
    const { keycloak, initialized } = useKeycloak();
    const axios = useAxios.useAxios({}).axios;
	//console.log('axios ', axios);
        
	const getCSRFToken = async () => {
        //const response = await axios.get('/getCSRFToken');
        console.log("csrf token:: ", keycloak.idTokenParsed.exp);
        //axios.defaults.headers.post['X-CSRF-Token'] = keycloak.token;
     };

    useEffect(()=>{
        if (!initialized) {
            return <div>Loading...</div>;
        }
        if (!keycloak?.authenticated) {
            keycloak.login();
            
        }else{
            getCSRFToken();
        }
    },[]);


    const [fileProperties, setFileProperties] = useState({
        file: null,
        fileType: null,
        fileName: null,
    });
    const [isUploadAction, setUploadAction] = useState(false);
    const setFileProperty = (file, fileType, fileName) => {
        setFileProperties({ file, fileType, fileName });
    };


    const clearFileProperty = () => {
        setFileProperties({ file: null, fileType: null, fileName: null });
    };

    /* replaces the existing preview content with the latest */
    function reloadPreview(previewElement) {
        const documentContainer = document.getElementById('file-preview');
        documentContainer.innerHTML = '';
        documentContainer.appendChild(previewElement);
    }

    /* Creates image preview element dynamically and loads the file data url */
    function loadImagePreview(file, fileName) {
        // Display the image
        const imageElement = document.createElement('img');
        imageElement.src = URL.createObjectURL(file);
        imageElement.style.width = '300px';
        linkFile(file, fileName, imageElement);
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFileProperty(selectedFile, selectedFile.type, selectedFile.name);
        } else {
            clearFileProperty();
        }
    };

    function loadDocumentPreview(fileType, file, fileName) {
        const docCounter = document.createElement('p');
        reloadPreview(docCounter);
        const docPreviewIcon = new Image();
        console.log(fileType);
        if (fileType === "application/pdf") {
            docPreviewIcon.src = '/src/assets/image/pdf.jpeg';
        } else {
            docPreviewIcon.src = '/src/assets/image/doc.png';
        }
        docPreviewIcon.style.maxWidth = '150px';
        linkFile(file, fileName, docPreviewIcon);
    }


    function linkFile(file, fileName, fileElement) {
        const fileLink = document.createElement('a');
        fileLink.href = URL.createObjectURL(file);
        fileLink.download = fileName;
        fileLink.appendChild(fileElement);
        reloadPreview(fileLink);
    }

    /* Showing file preview based on the file extension */
    function displayFilePreview(fileType, file, fileName) {
        const errorMessage = document.getElementById('error-message');
        if (fileType.startsWith("image/")) {
            // Display the image
            loadImagePreview(file, fileName);
        } else if (fileType === "application/pdf" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            // Display the PDF or DOC icon as a link for download
            loadDocumentPreview(fileType, file, fileName);
        } else {
            // Error occurred if the file extension is not among the allowed file types
            errorMessage.textContent = "Unsupported file type: " + fileName;
        }
    }

    async function uploadFile(file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        
        console.log('keycloak.idTokenParsed.exp: ', keycloak.idTokenParsed.exp);
        // console.log('cookies: ', cookies);
        //'X-CSRF-Token': keycloak.idTokenParsed.exp
        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          },
        };
        await axios.post(url, formData, config)
            .then((response) => {
                let respData = DOMPurify.sanitize(JSON.stringify(response.data));
                console.log('response.status', response.status);
                console.log('respData->', respData);
            });
    }


    async function handleUpload(event) {
        event.preventDefault();
        setUploadAction(true);
        displayFilePreview(fileProperties.fileType, fileProperties.file, fileProperties.fileName);
        uploadFile(fileProperties.file);
    };

    return (
        <div>
            <div className="phppot-container">
                <h1>How to upload a file using React Component</h1>
                <div id="file-upload-component"></div>
            </div>
            <form onSubmit={handleUpload}>
                <div id="error-message"></div>
                <input
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif, .pdf, .docx"
                    onChange={handleFileChange}
                    required
                />
                <button type="submit">Upload</button>
            </form>

            {isUploadAction && fileProperties.fileType && (
                <div className="file-details">
                    <h3>Preview :</h3>
                    {fileProperties.fileName && <p>File Name: {fileProperties.fileName}</p>}
                </div>
            )}
            <div id="file-preview"></div>
        </div>
    )
}