import React, { useState, Component } from 'react';
import './OtherBusinesses.css';
import { useSelector, useDispatch } from 'react-redux';

export default class OtherBusiness extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count: 0
        };

        this.affirm = this.props.affirm.bind(this);
        this.closePopup = this.props.closePopup.bind(this);
        this.resetForm = this.props.resetForm.bind(this);
    }

    close() {
        this.closePopup();
        this.resetForm();
    }

    processList(otherBusinesses) {

        const items = [];

        otherBusinesses.forEach((tenant) => {
            const search_term = this.props.loginBusinessName.toLowerCase();
            const start_ = tenant.businessName.toLowerCase().indexOf(search_term.toLowerCase());
            const end_ = start_ + search_term.length;
            const len = search_term.length;
            const to_end = tenant.businessName.toLowerCase().length;

            const searchTermReplace = tenant.businessName.substring(start_, end_);
            
            items.push(<div key={tenant.id} className="otherBiz-wide otherBiz">
                <a key={`${tenant.id}_a`} onClick={() => { this.affirm(tenant.realmId, 'login') }}>{tenant.businessName.substring(0, start_)}<span key={`${tenant.id}_s`} className='searchTermSpan'>{searchTermReplace}</span>{tenant.businessName.substring(start_ + len, to_end)}</a>
            </div>);
        });

        return items;

    };

    render() {

        return (
            <div id="otherBizPopup" className="centeredLayoverLogin" >
                <div className="centeredPopup" >
                    <div className="notifHeader">
                        Accounts associated with <b><i>{this.props.emailAddress}</i></b>. 
                        <br/>Click on one to log in. <br/>
                            <a className="cancelSearchAgain" onClick={() => { this.close() }}>Or, Search Again</a>
                    </div>
                    <div className="businessesEnumeration" id="businesses">
                        {this.processList(this.props.otherBusinesses)}
                    </div>
                </div>
            </div>

        );
    }

}