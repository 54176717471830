import React from 'react';
import './3-crobod-biz.module.css'
import { useState } from 'react'
import BusinessRegistration from './1-business-registration/business-registration'
import VirtualAccountsAndCards from './2-virtual-accounts-and-cards/virtual-accounts-and-cards'
import SuppliersPayments from './3-suppliers-payments/suppliers-payments'
import InternationalShipping from './4-international-shipping/international-shipping';

export default function CrobodBiz(props){

    const [submodule, setSubmodule] = useState({id:'business-registration',name:'Business Registration'});

    function menuNavigate(event, submodule){
        event.preventDefault();
        setSubmodule(submodule.id);
        props.setSubmoduleName(submodule.name);
    }


    return (
        <div>
            <div className='appCardContainer'>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'business-registration', name:'Business Registration'}) }}  className='appCardText'>
                        Business Registration
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'virtual-accounts-and-cards', name: 'Virtual Accounts and Cards'}) }}  className='appCardText'>
                        Virtual Account and Cards
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id:'suppliers-payments', name:'Suppliers Payments'}) }}  className='appCardText'>
                        Suppliers Payments
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'international-shipping', name: 'International Shipping'} ) }}  className='appCardText'>
                        International Shipping
                    </div>
                </div>
            </div>
            {submodule==='business-registration' && <BusinessRegistration></BusinessRegistration>}
            {submodule==='virtual-accounts-and-cards' && <VirtualAccountsAndCards></VirtualAccountsAndCards>}
            {submodule==='suppliers-payments' && <SuppliersPayments></SuppliersPayments>}
            {submodule==='international-shipping' && <InternationalShipping></InternationalShipping>}
        </div>
    )
}