import React from 'react';
import './4-crobod-prime.module.css'
import { useState } from 'react';
import AllInOneSolution from './1-all-in-one-solution/all-in-one-solution'
import MultiCurrencyBanking from './2-multi-currency-banking/multi-currency-banking'


export default function CrobodPrime(props){

    const [submodule, setSubmodule] = useState({id: 'all-in-one-solution', name: 'All-in-one Solution'});

    function menuNavigate(event, submodule){
        event.preventDefault();
        setSubmodule(submodule.id);
        props.setSubmoduleName(submodule.name);
    }
    return (
        <div>
            <div className='appCardContainer'>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'all-in-one-solution', name: 'All-in-one Solution'}) }}  className='appCardText'>
                        All in one solution
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'multi-currency-banking', name: 'Multi-currency Banking'}) }}  className='appCardText'>
                        Multi-currency Banking
                    </div>
                </div>
            </div>
            {submodule==='all-in-one-solution' && <AllInOneSolution></AllInOneSolution>}
            {submodule==='multi-currency-banking' && <MultiCurrencyBanking></MultiCurrencyBanking>}
        </div>
    )
}