import React from 'react';
import './2-crobod-capital.css'
import { useState } from 'react'
import ImportFinancing from './1-import-financing/import-financing'
import ExportFinancing from './2-export-financing/export-financing'
import WorkingCapital from './3-working-capital/working-capital'

export default function CrobodCapital(props){

    const [submodule, setSubmodule] = useState({id:'import-financing', name: 'Import Financing'});

    function menuNavigate(event, submodule){
        event.preventDefault();
        setSubmodule(submodule.id);
        props.setSubmoduleName(submodule.name);
    }

    

    return (
        <div>
            <div className='appCardContainer'>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id:'import-financing', name:'Import Financing'}) }} className='appCardText'>
                        Import Financing
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'export-financing', name: 'Export Financing'}) }}  className='appCardText'>
                        Export Financing
                    </div>
                </div>
                <div className='appCardItem'>
                    <div className='chatFillPlus'>
                        <img src='../../images/icon.svg'></img>
                    </div>
                    <div onClick={(e) => { menuNavigate(e,{id: 'working-capital', name:'Working Capital'}) }}  className='appCardText'>
                        Working Capital
                    </div>
                </div>
            </div>
            {submodule==='import-financing' && <ImportFinancing></ImportFinancing>}
            {submodule==='export-financing' && <ExportFinancing></ExportFinancing>}
            {submodule==='working-capital' && <WorkingCapital></WorkingCapital>}
        </div>
    )
}