import React from 'react'
import './step.css'
import icon from '../../assets/icon.png'

const Step = ({ number, body}) => {
  return (
    <div className='step'>
        <div className="step-number">
            <h2>{number}</h2>
        </div>
        <p>{body}</p>
        <img src={icon} alt="Icon" />
    </div>
  )
}

export default Step